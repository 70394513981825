import React, { useState } from 'react'
import { Route } from 'wouter'
import { useCarMaterial } from '../stores'
import CoatingSliderScreen from './ui/CoatingSliderScreen'

const sliderValues = [
  { s1: '34%', s2: '34%', s3: '90%', s4: '34%' },
  { s1: '34%', s2: '34%', s3: '90%', s4: '34%' },
  { s1: '34%', s2: '34%', s3: '90%', s4: '90%' },
  { s1: '34%', s2: '34%', s3: '34%', s4: '34%' },
  { s1: '34%', s2: '34%', s3: '90%', s4: '34%' },
  { s1: '34%', s2: '34%', s3: '90%', s4: '90%' },
  { s1: '90%', s2: '90%', s3: '90%', s4: '90%' },
  { s1: '90%', s2: '90%', s3: '34%', s4: '90%' },
]

export default function ConditionalUI() {
  const [currentAnimatedValues, setCurerrntAnimatedvalue] = useState(7)

  const { setMaterial } = useCarMaterial()

  function onClick(n) {
    if (n[0] === 'low' && n[1] === 'low' && n[2] === 'low') {
      setPreset(0)
    } else if (n[0] === 'high' && n[1] === 'low' && n[2] === 'low') {
      setPreset(1)
    } else if (n[0] === 'low' && n[1] === 'high' && n[2] === 'high') {
      setPreset(2)
    } else if (n[0] === 'high' && n[1] === 'high' && n[2] === 'low') {
      setPreset(3)
    } else if (n[0] === 'low' && n[1] === 'high' && n[2] === 'low') {
      setPreset(4)
    } else if (n[0] === 'low' && n[1] === 'low' && n[2] === 'high') {
      setPreset(5)
    } else if (n[0] === 'high' && n[1] === 'low' && n[2] === 'high') {
      setPreset(6)
    } else if (n[0] === 'high' && n[1] === 'high' && n[2] === 'high') {
      setPreset(7)
    } else {
      setPreset(0)
    }
  }

  function setPreset(n) {
    setCurerrntAnimatedvalue(n)

    switch (n) {
      case 0:
        //roughness,
        // metalness,
        // clearcoat,
        // clearcoatRoughness,
        // transmission,
        // color'
        // specularTint
        // specularIntensity
        setMaterial(0.4, 1, 1, 0.5, 0, '#000000', '#d26900', 0.2)
        //low, low, low
        break

      case 1:
        setMaterial(0.5, 0.5, 0.2, 0.15, 0, '#080808', '#d26900', 0.1)
        //high, low, low
        break

      case 2:
        setMaterial(0, 0.5, 1, 0.4, 0, '#060606', '#000000', 1)
        //low,high,high
        break

      case 3:
        setMaterial(0.2, 0.1, 1, 0.5, 0, '#000000', '#330000', 1)
        //high, high, low
        break

      case 4:
        setMaterial(0.2, 0.1, 0.3, 0.3, 0, '#000000', '#000000', 1)
        //low,high,low
        break

      case 5:
        setMaterial(0.1, 0.4, 0.8, 0.15, 0, '#121212', '#000000', 0.1)
        //low,low,high
        break

      case 6:
        setMaterial(0.5, 0.5, 1, 0.1, 1, '#000000', '#0046CE', 0.5)
        //high, low, high
        break

      case 7:
        setMaterial(0, 0, 1, 0.1, 0, '#030303', '#00194A', 0.5)
        //high,high,high
        break
      default:
    }
  }

  return (
    <Route path="/coatings/color">
      <CoatingSliderScreen
        presetChange={onClick}
        sliderValues={sliderValues[currentAnimatedValues]}
      />
    </Route>
  )
}
// specularityChange={onSpecularityChange}
