import React from 'react'
import { isMobileOnly } from 'react-device-detect'

export default function NextArrow() {
  return (
    <>
      <svg
        width={isMobileOnly ? '2.5vw' : '3.3vh'}
        heigh={isMobileOnly ? '3.5vw' : '4.3vh'}
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15 8L0.75 15.7942L0.75 0.205772L15 8Z" fill="#C4C4C4" />
      </svg>
    </>
  )
}
