import React, { Suspense, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useEnvironment } from '../../stores'
import EnvironmentState from '../EnvironmentState'
// import { a as animated } from '@react-spring/three';
import Headlights from '../models/conditional/Headlights'

export default function LightSetup(props) {
  const [day, setDay] = useState(true)
  const { environments } = useEnvironment()
  const [mode, setMode] = useState('day')

  function onChange(env) {
    if (env === 0) {
      setMode('day')
    } else if (env === 1) {
      setMode('night')
    } else if (env === 2) {
      setMode('continua')
    }
    // if (env === 1) setDay(false)
    // else setDay(true)
  }

  return (
    <>
      <ambientLight
        intensity={
          environments[mode === 'day' ? 0 : mode === 'continua' ? 2 : 1].ambient
            .intensity
        }
        color={
          environments[mode === 'day' ? 0 : mode === 'continua' ? 2 : 1].ambient
            .color
        }
      />
      <hemisphereLight
        intensity={
          environments[mode === 'day' ? 0 : mode === 'continua' ? 2 : 1].hemi
            .intensity
        }
        groundColor={
          environments[mode === 'day' ? 0 : mode === 'continua' ? 2 : 1].hemi
            .ground
        }
        color={
          environments[mode === 'day' ? 0 : mode === 'continua' ? 2 : 1].hemi
            .sky
        }
      />
      {mode === 'night' && (
        <>
          <spotLight
            color={'#ebe7e5'}
            intensity={3}
            position={[-3, 3.5, 0]}
            angle={1.2}
            penumbra={0.5}
            decay={1}
            distance={0}
          />
          {!isMobile && (
            <Suspense fallback={null}>
              <Headlights animateLight={day} />
            </Suspense>
          )}
        </>
      )}
      {/* <animated.rectAreaLight intensity={inte1} width={width1} height={height1} color={col1} lookAt={lookAt1} position={pos1} />
            <animated.rectAreaLight intensity={inte} width={width} height={height} color={col} lookAt={lookAt} position={pos} /> */}
      <EnvironmentState onChange={onChange} />
    </>
  )
}
