import create from 'zustand'
import { AllowedLocales, languageIndices } from './constants'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import { useAnalytics } from './customAnalytics'
import { isMobile, isMobileOnly } from 'react-device-detect'
// import cachedData from './developer/cacheWithLinks.json'
const axios = require('axios')

/**
 * useMaster - store to deal with all website content
 *
 * current: category and product
 * -1 means none, any other number is an index
 *
 * data: stores the data from the CMS
 */

export const useCarMaterial = create((set, get) => ({
  //0,0,1,0.4,0,'#0B0B0B','#000000',1 // preset 2
  material: {
    roughness: 0,
    metalness: 0.5,
    clearcoat: 1,
    clearcoatRoughness: 0.4,
    transmission: 0,
    color: '#020202',
    specularTint: '#000000',
    specularIntensity: 0.1,
  },

  setUndertone: (c) => {
    set((state) => ({
      material: {
        roughness: get().material.roughness,
        metalness: get().material.metalness,
        clearcoat: get().material.clearcoat,
        clearcoatRoughness: get().material.clearcoatRoughness,
        transmission: get().material.transmission,
        color: c,
        // specularTint: st,
        // specularIntensity: si,
      },
    }))
  },

  setJetness: (c) => {
    set((state) => ({
      material: {
        roughness: get().material.roughness,
        metalness: get().material.metalness,
        clearcoat: get().material.clearcoat,
        clearcoatRoughness: get().material.clearcoatRoughness,
        transmission: get().material.transmission,
        color: c,
        specularTint: get().material.specularTint,
        specularIntensity: get().material.specularIntensity,
      },
    }))
  },

  setMaterial: (ro, me, cc, cr, t, c, st, si) => {
    set((state) => ({
      material: {
        roughness: ro,
        metalness: me,
        clearcoat: cc,
        clearcoatRoughness: cr,
        transmission: t,
        color: c,
        specularTint: st,
        specularIntensity: si,
      },
    }))
  },
}))

export const MenuCats = [
  'MRG',
  'Coatings',
  'Inks',
  'Niche',
  'Plastics',
  'Tyre',
  'Interior',
]

export const useBehaviour = create((set, get) => ({
  currentCategory: -1,
  currentProduct: '',
  display: 'Not Selected',
  setCurrentProduct: (prd) => {
    set((state) => ({
      ...state,
      currentProduct: prd,
    }))
  },
  setCurrentCategory: (index) => {
    //console.log(index !== -1 ? MenuCats[index] : 'Not Selected')
    set((state) => ({
      ...state,
      currentCategory: index,
      display: index !== -1 ? MenuCats[index] : 'Not Selected',
    }))
  },
}))

export const useMaster = create((set, get) => ({
  current: { category: -1, product: -1 },
  locale: AllowedLocales[languageIndices.english],
  setLocale: (index) => {
    set((state) => ({
      ...state,
      locale: AllowedLocales[index],
    }))
  },
  // https://admin.digitalshowroom.birlacarbon.com/api
  // https://dsbc-staging.autovrse.app/api/
  baseURL: 'https://admin.digitalshowroom.birlacarbon.com/api/',
  apiToken:
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjgyNDI3NzMzLCJleHAiOjE2ODUwMTk3MzN9.LlGZ4NT9VWUKwmsTIdUeruP3dG4pnJ41nRbx534KolM',
  cred: { identifier: 'sahil.singh@autovrse.in', password: 'bhKRmLFvjU' },
  data: {
    categories: {
      Mrg: {
        display: 'MRG',
        target: '/mrg',
      },
      Coatings: {
        display: 'COATINGS',
        target: '/coatings',
      },
      Inks: {
        display: 'INKS',
        target: '/inks',
      },
      // Niche: {
      //   display: 'NICHE',
      //   target: '/niche',
      // },
      Plastics: {
        display: 'PLASTICS',
        target: '/plastics',
      },
      Tyre: {
        display: 'TYRE',
        target: '/tyre',
      },
      EnergySystem: {
        display: 'Ener SYSTEM',
        target: '/energy',
      },
      Continua: {
        // display: 'Continua',
        src: '/images/continua_logo.png',
        target: '/continua',
      },
    },
    continuaCategories: {
      Mrg: {
        display: 'MRG',
        target: '/continua/mrg',
      },
      Coatings: {
        display: 'COATINGS',
        target: '/continua/coatings',
      },
      Tyre: {
        display: 'TIRE',
        target: '/continua/tyre',
      },
      Plastics: {
        display: 'PLASTICS',
        target: '/continua/plastics',
      },
      // Continua: {
      //   display: 'Continua',
      //   src: '/images/continua_logo.png',
      //   target: '/',
      // },
    },
    components: {},
  },
  titles: {},
  misc: {},

  objects: {
    '/continua': {
      hotspots: [
        {
          position: [0.8, 1.5, 0.2],
          mobilePosition: isMobileOnly ? [1.8, 1.6, 0.5] : [0.8, 1.6, 0.2],
          // position: [0, 1.7, 0],
          // mobilePosition: [3.5, 1.7, 0.2],
          scale: 1,
          title: 'Coatings',
          misc: 'coatings',
          target: '/continua/coatings',
          audio: '/audio/Opt2.wav',
          green: true,
        },
        {
          position: [-1.73, 1.07, -0.2],
          mobilePosition: [-1.93, 1.07, -0.4],
          scale: 1,
          title: 'MRG',
          misc: 'mrg',
          target: '/continua/mrg',
          green: true,
        },
        {
          position: [1.05, 0.9, -1],
          mobilePosition: [1.65, 0.9, -1],
          // position: [0.84, 0.53, -0.99],
          // mobilePosition: [0.84, 0.53, -0.99],
          scale: 1,
          title: 'Tire',
          target: '/continua/tyre',
          misc: 'tyre',
          audio: '/audio/Opt1.wav',
          green: true,
        },
        {
          position: [-2.61, 0.54, 0.45],
          // mobilePosition: [-2.69, 0.59, 0.19],
          // position: [-2.61, 0.54, 0.19],
          mobilePosition: isMobileOnly
            ? [-2.81, 0.94, 0.19]
            : [-2.61, 0.54, 0.19],
          scale: 1,
          title: 'Plastics',
          target: '/continua/plastics',
          misc: 'plastics',
          green: true,
          type: 'left',
        },
      ],
      threed: {},
      meta: {
        title: 'Continua',
        desc: 'Birla carbon continua mode',
        keywords: 'continua, birla carbon, car, green mode',
      },
    },
    '/continua/coatings': {
      hotspots: [],
      threed: {
        InteriorProps: {
          opacity: 1,
          light: false,
          highlight: {
            index: 0,
            color: '#001A00',
          },
        },
        RoofProps: {
          animate: true,
          highlight: {
            color: '#001A00',
          },
        },
        TyreProps: {
          highlight: {
            index: 0,
            color: '#001A00',
            pulse: false,
          },
        },
      },
      meta: {
        title: 'Continua Coatings',
        desc: 'Birla carbon continua mode coatings',
        keywords: 'continua, birla carbon, car, coatings',
      },
      changeLocation: {
        previous: '/continua/mrg',
        next: '/continua/tyre',
      },
    },
    '/continua/mrg': {
      hotspots: [],
      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            // 0 - 6 parts 7 - all
            index: 7,
            color: '#001A00',
            pulse: false,
          },
        },
        InteriorProps: {
          opacity: 0.2,
        },
        PaintProps: {
          opacity: 0.2,
          animate: true,
        },
        // CarBody: {
        //   opacity: 0.2,
        // },
        // Mrg: {
        //   opacity: 1,
        //   highlight: {
        //     // 0 - 6 parts 7 - all
        //     index: 7,
        //     color: '#0F0000',
        //     pulse: false,
        //   },
        // },
        // Internals: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 0.2,
        // },
        // Hood: {
        //   opacity: 0.5,
        //   animate: true,
        // },
        Tyre: {
          animate: false,
        },
      },
      changeLocation: {
        previous: '/continua',
        next: '/continua/coatings',
      },
      meta: {
        title: 'Continua Mrg',
        desc: 'Birla carbon continua mode mrg',
        keywords: 'continua, birla carbon, car, mrg',
      },
    },
    '/continua/tyre': {
      hotspots: [],
      threed: {
        TyreProps: {
          moveOut: true,
          highlight: {
            index: 7,
            color: '#000200',
            pulse: false,
          },
          // color: {
          //   index: 1,
          //   color: '#001A00',
          // },
        },
      },
      changeLocation: {
        previous: '/continua/coatings',
        next: '/continua/plastics',
      },
      meta: {
        title: 'Continua Tyre',
        desc: 'Birla carbon continua mode tyre',
        keywords: 'continua, birla carbon, car, tyre',
      },
    },
    '/continua/plastics': {
      hotspots: [],
      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            index: 11, // 0-1 parts 2 all
            color: '#001A00',
            pulse: false,
          },
        },
        InteriorProps: {
          opacity: 0.5,
        },
        PaintProps: {
          opacity: 0.5,
          animate: true,
        },
        // CarBody: {
        //   opacity: 0.5,
        // },
        // Interior: {
        //   opacity: 0.5,
        // },
        // Internals: {
        //   opacity: 0.5,
        // },
        // Plastics: {
        //   color: '#2F0000',
        //   pulse: false,
        //   index: 3,
        // },
        // Hood: {
        //   animate: true,
        // },
      },
      changeLocation: {
        previous: '/continua/tyre',
        next: '/continua/mrg',
      },
      meta: {
        title: 'Continua Plastics',
        desc: 'Birla carbon continua mode plastic',
        keywords: 'continua, birla carbon, car, plastic',
      },
    },
    '/': {
      hotspots: [
        {
          position: [0.95, 1.6, 0.5],
          mobilePosition: [0.8, 1.6, 0.2],
          // position: [0.8, 1.6, 0.2],
          // mobilePosition: [1.2, 2.1, 1.5],
          scale: 1,
          title: 'Coatings',
          misc: 'coatings',
          target: '/coatings',
          type: isMobile ? 'right' : 'left',

          // carengine
        },
        // {
        //   position: [-0.47, 1.55, 0.19],
        //   mobilePosition: [-0.47, 1.55, 0.19],
        //   scale: 1,
        //   title: 'Niche',
        //   misc: 'niche',
        //   target: '/niche',
        //   type: 'left',
        // },
        {
          position: [-1.63, 1.07, 0],
          mobilePosition: [-1.63, 1.07, 0],
          scale: 1,
          title: 'MRG',
          misc: 'mrg',
          target: '/mrg',
        },
        {
          position: [1.35, 0.9, -1],
          mobilePosition: [1.65, 0.9, -1],
          scale: 1,
          title: 'Tyre',
          misc: 'tyre',
          target: '/tyre',
          type: 'right',
        },
        {
          position: [0.43, 1.2, 0.4],
          mobilePosition: [0.43, 1.2, 0.4],
          scale: 1,
          title: 'Inks',
          misc: 'inks',
          target: '/inks',
        },
        {
          position: [-1.1, 1.07, 0.92],
          mobilePosition: isMobileOnly
            ? [-0.25, 1.29, 0.987]
            : [-0.9, 1.07, 0.92],
          scale: 1,
          title: 'Energy System',
          misc: 'energy system',
          target: '/energy',
          // type: 'left',
        },
        {
          // position: [-2.61, 0.54, 0.19],
          // mobilePosition: [-2.61, 0.54, 0.19],
          position: [-2.61, 0.54, 0.45],
          mobilePosition: isMobileOnly
            ? [-2.96, 0.94, 0.19]
            : [-2.71, 0.54, 0.19],
          scale: 1,
          title: 'Plastics',
          misc: 'plastics',
          target: '/plastics',
          type: 'left',
        },
        // {
        //   position: [-0.08, 1.09, -1.01],
        //   mobilePosition: [-0.08, 1.09, -1.01],
        //   scale: 1,
        //   title: 'Interior',
        //   misc: 'interior',
        //   target: '/frontpassengerseat',
        // },
      ],
      threed: {},
    },
    '/energy': {
      hotspots: [
        {
          position: [-1.93, 0.95, 0],
          mobilePosition: isMobileOnly ? [-1.55, 0.95, -0.8] : [-1.93, 0.95, 0],
          scale: 1,
          title: 'Lead Acid Battery',
          target: '/energy/lead',
          misc: 'lead',
        },
        {
          position: [2.95, -0.53, 0],
          mobilePosition: isMobileOnly ? [2.95, -0.53, -0.6] : [2.95, -0.53, 0],
          scale: 1,
          title: 'Lithium Ion Battery',
          target: '/energy/lithium',
          misc: 'lithium',
          // type: 'left',
        },
      ],

      threed: {},

      changeLocation: {
        previous: '/tyre',
        next: '/mrg',
      },

      meta: {
        title: 'Birla Carbon | Energy System',
        desc: 'Birla Carbon',
        keywords:
          'lithium carbon,carbon battery,uniform dispersion,lithium carbon battery,fast charging icon,icon lithium battery,carbon ion battery,carbon cell,lithium i,carbon energy systems,power energy systems',
      },
    },
    '/energy/lead': {
      hotspots: [],
      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            index: 10, // 0-1 parts 2 all
            color: '#0f0000',
            pulse: true,
          },
          animate: true,
        },
        InteriorProps: {
          opacity: 1,
        },
        PaintProps: {
          opacity: 0.2,
          animate: true,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        // },
        // Hood: {
        //   animate: true,
        //   highlight: '#000000',
        // },
        // Internals: {
        //   opacity: 0.8,
        // },
        // Niche: {
        //   opacity: 1,
        //   highlight: {
        //     index: 0, // 1-2 parts 3 all
        //     color: '#2F0000',
        //     pulse: true,
        //   },
        // },
      },
      changeLocation: {
        previous: '/energy/lithium',
        next: '/energy/lithium',
      },
      meta: {
        title: 'Birla Carbon | Energy System',
        desc: 'Birla Carbon',
        keywords:
          'lithium carbon,carbon battery,uniform dispersion,lithium carbon battery,fast charging icon,icon lithium battery,carbon ion battery,carbon cell,lithium i,carbon energy systems,power energy systems',
      },
    },
    '/energy/lithium': {
      hotspots: [
        {
          position: [-2.2, 0.95, 1.63],
          mobilePosition: [-2.2, 0.95, 1.63],
          scale: 1,
          title: 'CB.CNT',
          target: '/energy/lithium/cb_cnt',
          titleID: 53,
        },
        {
          position: [0, 0.3, 1.25],
          mobilePosition: [0, 0.3, 1.25],
          scale: 1,
          title: 'Conductex i',
          target: '/energy/lithium/conductex',
          titleID: 54,
          // type: 'left',
        },
        {
          position: [0.49, 0.3, 2],
          mobilePosition: [0.49, 0.3, 2],
          scale: 1,
          title: 'Graphite',
          target: '/energy/lithium/graphite',
          titleID: 55,
          // type: 'left',
        },
      ],
      threed: {
        EnergyProps: {
          moveOut: true,
        },
      },
      changeLocation: {
        previous: '/energy/lead',
        next: '/energy/lead',
      },
      meta: {
        title: 'Birla Carbon | Energy System',
        desc: 'Birla Carbon',
        keywords:
          'lithium carbon,carbon battery,uniform dispersion,lithium carbon battery,fast charging icon,icon lithium battery,carbon ion battery,carbon cell,lithium i,carbon energy systems,power energy systems',
      },
    },
    '/energy/lithium/cb_cnt': {
      hotspots: [],
      threed: {
        EnergyProps: {
          moveOut: true,
        },
      },
      changeLocation: {
        previous: '/energy/lithium/graphite',
        next: '/energy/lithium/conductex',
      },
      meta: {
        title: 'Birla Carbon | Energy System',
        desc: 'Birla Carbon',
        keywords:
          'lithium carbon,carbon battery,uniform dispersion,lithium carbon battery,fast charging icon,icon lithium battery,carbon ion battery,carbon cell,lithium i,carbon energy systems,power energy systems',
      },
    },
    '/energy/lithium/conductex': {
      hotspots: [],
      threed: {
        EnergyProps: {
          moveOut: true,
        },
      },
      changeLocation: {
        previous: '/energy/lithium/cb_cnt',
        next: '/energy/lithium/graphite',
      },
      meta: {
        title: 'Birla Carbon | Energy System',
        desc: 'Birla Carbon',
        keywords:
          'lithium carbon,carbon battery,uniform dispersion,lithium carbon battery,fast charging icon,icon lithium battery,carbon ion battery,carbon cell,lithium i,carbon energy systems,power energy systems',
      },
    },
    '/energy/lithium/graphite': {
      hotspots: [],
      threed: {
        EnergyProps: {
          moveOut: true,
        },
      },
      changeLocation: {
        previous: '/energy/lithium/conductex',
        next: '/energy/lithium/cb_cnt',
      },
      meta: {
        title: 'Birla Carbon | Energy System',
        desc: 'Birla Carbon',
        keywords:
          'lithium carbon,carbon battery,uniform dispersion,lithium carbon battery,fast charging icon,icon lithium battery,carbon ion battery,carbon cell,lithium i,carbon energy systems,power energy systems',
      },
    },
    '/frontpassengerseat': {
      hotspots: [],
      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
      },
      changeLocation: {
        previous: '/rearseat',
        next: '/driverseat',
      },
    },
    '/driverseat': {
      hotspots: [],
      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
      },
      changeLocation: {
        previous: '/frontpassengerseat',
        next: '/rearseat',
      },
    },
    '/rearseat': {
      hotspots: [],
      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
      },
      changeLocation: {
        previous: '/driverseat',
        next: '/frontpassengerseat',
      },
    },
    '/tyre': {
      hotspots: [
        {
          position: [1.16, 0.75, -0.93],
          mobilePosition: [1.23, 0.8, -0.93],
          scale: 1,
          title: 'Interior',
          target: '/tyre/interior',
          misc: 'interior',
          audio: '/audio/Tyre.wav',
        },
        {
          position: [0.84, 0.53, -0.99],
          mobilePosition: [0.84, 0.53, -0.99],
          scale: 1,
          title: 'Exterior',
          target: '/tyre/exterior',
          misc: 'exterior',
          audio: '/audio/Opt1.wav',
        },
      ],

      threed: {},
      changeLocation: {
        previous: '/plastics',
        next: '/energy',
      },
      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon black from tires,carbon black tirecarbon black for tires,carbon black in tyres,tyre carbon black price per toncarbon black in tyres,carbon black used in tyres,tyre carbon,carbon black used in tyre industry,tyre black carbon powder uses,tyre carbon black manufacturers,carbon black used in tyre industry,carbon black used in tyres',
      },
    },
    '/coatings': {
      hotspots: [
        {
          position: [0, 1.7, 0],
          mobilePosition: [0.3, 1.59, 0],
          scale: 1,
          title: 'Roof',
          misc: 'roof',
          target: '/coatings/roof',
          audio: '/audio/Opt2.wav',
        },
        {
          position: [-1.84, 1.0, -0.2],
          mobilePosition: [-1.76, 1.2, 0],
          scale: 1,
          title: 'Color',
          misc: 'color',
          target: '/coatings/color',
        },
        {
          position: [-0.15, 1.25, -0.4],
          mobilePosition: [-0.25, 1.5, -0.4],
          scale: 1,
          title: 'Leather Coatings',
          titleID: 17,
          target: '/coatings/leathercoatings',
          type: 'left',
        },
        {
          position: [1.2, 0.8, -0.8],
          mobilePosition: [2, 0.7, 0],
          scale: 1,
          title: 'Powder Coatings',
          titleID: 18,
          target: '/coatings/powder',
          type: 'left',
        },
        {
          position: [-0.6, 1, 0.1],
          mobilePosition: [0, 1, 0.85],
          scale: 1,
          title: 'Interior Coatings',
          titleID: 19,
          target: '/coatings/interior',
        },
      ],

      threed: {
        // ExteriorProps: {
        //   opacity: 1,
        //   highlight: {
        // 0 - 6 parts 7 - all
        //     index: 8,
        //     color: '#2F0000',
        //     pulse: false,
        //   },
        // },
        InteriorProps: {
          opacity: 1,
          light: false,
          highlight: {
            index: 0,
            color: '#030000',
          },
        },
        RoofProps: {
          highlight: {
            color: '#FF0000',
          },
        },
        // PaintProps: {
        //   opacity: 1,
        //   highlight: {
        //     // 0 - 6 parts 7 - all
        //     index: 0,
        //     color: '#2F0000',
        //     pulse: false,
        //   },
        // },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        //   seats: '#1F0000',
        // },
        // Roof: {
        //   highlight: '#020000',
        //   opacity: 0.8,
        // },
        // Hood: {
        //   highlight: '#000000',
        // },
        TyreProps: {
          highlight: {
            index: 0,
            color: '#030000',
            pulse: false,
          },
        },
      },

      changeLocation: {
        previous: '/mrg',
        next: '/inks',
      },

      meta: {
        title: 'Birla Carbon | Coatings',
        desc: 'Birla Carbon',
        keywords:
          'carbon coatings,carbon black coating,carbon insulation coating',
      },
    },
    '/coatings/roof': {
      hotspots: [
        {
          position: [0, 1.7, -0.3],
          mobilePosition: [0, 1.7, 0],
          scale: 1,
          title: 'Metal Protection',
          titleID: 13,
          target: '/coatings/roof/metal',
        },
        {
          position: [0, 1.9, 0],
          mobilePosition: [0.3, 2, 0],
          scale: 1,
          title: 'Primer',
          titleID: 14,
          target: '/coatings/roof/primer',
        },
        {
          position: [0.3, 1.9, -0.5],
          mobilePosition: [0.48, 2, -0.21],
          scale: 1,
          title: 'Base Coat',
          titleID: 16,
          target: '/coatings/roof/base',
          type: 'left',
        },
        {
          position: [0.3, 1.85, -1.0],
          mobilePosition: [0.93, 1.65, -0.34],
          scale: 1,
          title: 'Clear Coat',
          titleID: 15,
          target: '/coatings/roof/clear',
          type: 'left',
        },
      ],

      threed: {
        RoofProps: {
          animate: true,
        },
      },

      changeLocation: {
        previous: '/coatings/powder',
        next: '/coatings/roof/clear',
      },

      meta: {
        title: 'Birla Carbon | Coatings',
        desc: 'Birla Carbon',
        keywords:
          'carbon coatings,carbon black coating,carbon insulation coating',
      },
    },
    '/coatings/roof/clear': {
      hotspots: [],
      threed: {
        RoofProps: {
          animate: true,
        },
      },

      changeLocation: {
        previous: '/coatings/powder',
        next: '/coatings/roof/base',
      },

      meta: {
        title: 'Birla Carbon | Coatings',
        desc: 'Birla Carbon',
        keywords:
          'carbon coatings,carbon black coating,carbon insulation coating',
      },
    },
    '/coatings/roof/base': {
      hotspots: [],
      threed: {
        RoofProps: {
          animate: true,
        },
      },

      changeLocation: {
        previous: '/coatings/roof/clear',
        next: '/coatings/roof/primer',
      },

      meta: {
        title: 'Birla Carbon | Coatings',
        desc: 'Birla Carbon',
        keywords:
          'carbon coatings,carbon black coating,carbon insulation coating',
      },
    },
    '/coatings/roof/primer': {
      hotspots: [],
      threed: {
        RoofProps: {
          animate: true,
        },
      },

      changeLocation: {
        previous: '/coatings/roof/base',
        next: '/coatings/roof/metal',
      },

      meta: {
        title: 'Birla Carbon | Coatings',
        desc: 'Birla Carbon',
        keywords:
          'carbon coatings,carbon black coating,carbon insulation coating',
      },
    },
    '/coatings/roof/metal': {
      hotspots: [],
      threed: {
        RoofProps: {
          animate: true,
        },
      },

      changeLocation: {
        previous: '/coatings/roof/primer',
        next: '/coatings/color',
      },

      meta: {
        title: 'Birla Carbon | Coatings',
        desc: 'Birla Carbon',
        keywords:
          'carbon coatings,carbon black coating,carbon insulation coating',
      },
    },
    '/coatings/color': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
        },
        InteriorProps: {
          opacity: 1,
        },
        PaintProps: {
          opacity: 1,
          animate: false,
        },
      },

      changeLocation: {
        previous: '/coatings/roof/primer',
        next: '/coatings/leathercoatings',
      },

      meta: {
        title: 'Birla Carbon | Coatings',
        desc: 'Birla Carbon',
        keywords:
          'carbon coatings,carbon black coating,carbon insulation coating',
      },
    },
    '/coatings/leathercoatings': {
      hotspots: [],
      threed: {
        InteriorProps: {
          opacity: 1,
          light: true,
          lightPosition: [0, 1.2, 0],
        },
        PaintProps: {
          opacity: 1,
        },
      },

      changeLocation: {
        previous: '/coatings/color',
        next: '/coatings/interior',
      },

      meta: {
        title: 'Birla Carbon | Coatings',
        desc: 'Birla Carbon',
        keywords:
          'carbon coatings,carbon black coating,carbon insulation coating',
      },
    },
    '/coatings/interior': {
      hotspots: [],
      threed: {
        InteriorProps: {
          opacity: 1,
          light: true,
          lightPosition: [-3, 1.2, 0],
          highlight: {
            index: 1,
            color: '#3F0000',
            pulse: true,
          },
        },
        PaintProps: {
          opacity: 1,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        //   light: true,
        // },
        // NsFrame: {
        //   highlight: '#3F0000',
        // },
      },

      changeLocation: {
        previous: '/coatings/leathercoatings',
        next: '/coatings/powder',
      },

      meta: {
        title: 'Birla Carbon | Coatings',
        desc: 'Birla Carbon',
        keywords:
          'carbon coatings,carbon black coating,carbon insulation coating',
      },
    },
    '/coatings/powder': {
      hotspots: [],
      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
      },

      changeLocation: {
        previous: '/coatings/interior',
        next: '/coatings/roof/clear',
      },

      meta: {
        title: 'Birla Carbon | Coatings',
        desc: 'Birla Carbon',
        keywords:
          'carbon coatings,carbon black coating,carbon insulation coating',
      },
    },

    '/tyre/exterior': {
      hotspots: [
        {
          position: [1.16, 0.78, -1.3],
          mobilePosition: [1.2, 0.81, -1.3],
          scale: 1,
          title: 'Tread',
          titleID: 42,
          target: '/tyre/exterior/tread',
        },
        {
          position: [1.14, 0.73, -1.44],
          mobilePosition: [1.15, 0.765, -1.44],
          scale: 1,
          title: 'Sidewall',
          titleID: 36,
          target: '/tyre/exterior/sidewall',
          type: 'right',
        },
        {
          position: [1.41, 0.71, -1.47],
          mobilePosition: [1.41, 0.74, -1.47],
          scale: 1,
          title: 'Sub Tread',
          titleID: 43,
          target: '/tyre/exterior/subtread',
        },
      ],

      threed: {
        TyreProps: {
          moveOut: true,
        },
      },

      changeLocation: {
        previous: '/tyre/interior',
        next: '/tyre/interior',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon black from tires,carbon black tirecarbon black for tires,carbon black in tyres,tyre carbon black price per toncarbon black in tyres,carbon black used in tyres,tyre carbon,carbon black used in tyre industry,tyre black carbon powder uses,tyre carbon black manufacturers,carbon black used in tyre industry,carbon black used in tyres',
      },
    },
    '/tyre/exterior/tread': {
      hotspots: [],
      threed: {
        TyreProps: {
          moveOut: true,
        },
      },

      changeLocation: {
        previous: '/tyre/exterior/subtread',
        next: '/tyre/exterior/sidewall',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon black from tires,carbon black tirecarbon black for tires,carbon black in tyres,tyre carbon black price per toncarbon black in tyres,carbon black used in tyres,tyre carbon,carbon black used in tyre industry,tyre black carbon powder uses,tyre carbon black manufacturers,carbon black used in tyre industry,carbon black used in tyres',
      },
    },
    '/tyre/exterior/sidewall': {
      hotspots: [],

      threed: {
        TyreProps: {
          moveOut: true,
        },
      },

      changeLocation: {
        previous: '/tyre/exterior/tread',
        next: '/tyre/exterior/subtread',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon black from tires,carbon black tirecarbon black for tires,carbon black in tyres,tyre carbon black price per toncarbon black in tyres,carbon black used in tyres,tyre carbon,carbon black used in tyre industry,tyre black carbon powder uses,tyre carbon black manufacturers,carbon black used in tyre industry,carbon black used in tyres',
      },
    },
    '/tyre/exterior/subtread': {
      hotspots: [],

      threed: {
        TyreProps: {
          moveOut: true,
        },
      },

      changeLocation: {
        previous: '/tyre/exterior/sidewall',
        next: '/tyre/exterior/tread',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon black from tires,carbon black tirecarbon black for tires,carbon black in tyres,tyre carbon black price per toncarbon black in tyres,carbon black used in tyres,tyre carbon,carbon black used in tyre industry,tyre black carbon powder uses,tyre carbon black manufacturers,carbon black used in tyre industry,carbon black used in tyres',
      },
    },

    '/tyre/interior': {
      hotspots: [
        {
          position: [1.31, 0.73, -1.81],
          mobilePosition: [1.31, 0.73, -1.81],
          scale: 1,
          title: 'Belt',
          titleID: 40,
          target: '/tyre/interior/belt',
        },
        {
          position: [1.35, 0.68, -1.81],
          mobilePosition: [1.35, 0.68, -1.81],
          scale: 1,
          title: 'Cap Plies',
          titleID: 41,
          target: '/tyre/interior/capplies',
        },
        {
          position: [1.39, 0.63, -1.81],
          mobilePosition: [1.39, 0.63, -1.81],
          scale: 1,
          title: 'Radial Plies',
          titleID: 37,
          target: '/tyre/interior/radialplies',
        },
        {
          position: [1.42, 0.53, -1.81],
          mobilePosition: [1.42, 0.53, -1.81],
          scale: 1,
          title: 'Inner Liner',
          titleID: 5,
          target: '/tyre/interior/innerliner',
        },
        {
          position: [1.39, 0.57, -1.9],
          mobilePosition: [1.39, 0.57, -1.9],
          scale: 1,
          title: 'Bead Apex',
          titleID: 39,
          target: '/tyre/interior/beadapex',
        },
        {
          position: [1.41, 0.51, -1.9],
          mobilePosition: [1.41, 0.51, -1.9],
          scale: 1,
          title: 'Bead',
          titleID: 38,
          target: '/tyre/interior/bead',
        },
      ],
      audio: '/audio/Tire_layer.wav',

      threed: {
        TyreProps: {
          animate: true,
          opacity: 1,
          moveOutInterior: true,
        },
      },

      changeLocation: {
        previous: '/tyre/exterior',
        next: '/tyre/exterior',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon black from tires,carbon black tirecarbon black for tires,carbon black in tyres,tyre carbon black price per toncarbon black in tyres,carbon black used in tyres,tyre carbon,carbon black used in tyre industry,tyre black carbon powder uses,tyre carbon black manufacturers,carbon black used in tyre industry,carbon black used in tyres',
      },
    },
    '/tyre/interior/bead': {
      hotspots: [],

      threed: {
        TyreProps: {
          animate: true,
          opacity: 1,
          moveOutInterior: true,
          highlight: {
            index: 1,
            color: '#FF0000',
            pulse: true,
          },
        },
      },

      changeLocation: {
        previous: '/tyre/interior/innerliner',
        next: '/tyre/interior/beadapex',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon black from tires,carbon black tirecarbon black for tires,carbon black in tyres,tyre carbon black price per toncarbon black in tyres,carbon black used in tyres,tyre carbon,carbon black used in tyre industry,tyre black carbon powder uses,tyre carbon black manufacturers,carbon black used in tyre industry,carbon black used in tyres',
      },
    },
    '/tyre/interior/beadapex': {
      hotspots: [],

      threed: {
        TyreProps: {
          animate: true,
          opacity: 1,
          moveOutInterior: true,
          highlight: {
            index: 2,
            color: '#FF0000',
            pulse: true,
          },
        },
      },

      changeLocation: {
        previous: '/tyre/interior/bead',
        next: '/tyre/interior/belt',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon black from tires,carbon black tirecarbon black for tires,carbon black in tyres,tyre carbon black price per toncarbon black in tyres,carbon black used in tyres,tyre carbon,carbon black used in tyre industry,tyre black carbon powder uses,tyre carbon black manufacturers,carbon black used in tyre industry,carbon black used in tyres',
      },
    },
    '/tyre/interior/belt': {
      hotspots: [],

      threed: {
        TyreProps: {
          animate: true,
          opacity: 1,
          moveOutInterior: true,
          highlight: {
            index: 3,
            color: '#FF0000',
            pulse: true,
          },
        },
      },

      changeLocation: {
        previous: '/tyre/interior/beadapex',
        next: '/tyre/interior/capplies',
      },

      meta: {
        title: 'Birla Carbon | Tire | Interior | Belt',
        desc: 'Birla Carbon',
        keywords:
          'carbon black rubber belts,carbon black rubber belts goods,belt manufacturing for black carbon,Black Carbon for Conveyor Belt,Carbon Black for Conveyor Belts',
      },
    },
    '/tyre/interior/capplies': {
      hotspots: [],

      threed: {
        TyreProps: {
          animate: true,
          opacity: 1,
          moveOutInterior: true,
          highlight: {
            index: 4,
            color: '#FF0000',
            pulse: true,
          },
        },
      },

      changeLocation: {
        previous: '/tyre/interior/belt',
        next: '/tyre/interior/radialplies',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon black from tires,carbon black tirecarbon black for tires,carbon black in tyres,tyre carbon black price per toncarbon black in tyres,carbon black used in tyres,tyre carbon,carbon black used in tyre industry,tyre black carbon powder uses,tyre carbon black manufacturers,carbon black used in tyre industry,carbon black used in tyres',
      },
    },
    '/tyre/interior/radialplies': {
      hotspots: [],

      threed: {
        TyreProps: {
          animate: true,
          opacity: 1,
          moveOutInterior: true,
          highlight: {
            index: 6,
            color: '#ff0000',
            pulse: true,
          },
        },
      },

      changeLocation: {
        previous: '/tyre/interior/capplies',
        next: '/tyre/interior/innerliner',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon black from tires,carbon black tirecarbon black for tires,carbon black in tyres,tyre carbon black price per toncarbon black in tyres,carbon black used in tyres,tyre carbon,carbon black used in tyre industry,tyre black carbon powder uses,tyre carbon black manufacturers,carbon black used in tyre industry,carbon black used in tyres',
      },
    },
    '/tyre/interior/innerliner': {
      hotspots: [],

      threed: {
        TyreProps: {
          animate: true,
          opacity: 1,
          moveOutInterior: true,
          highlight: {
            index: 5,
            color: '#FF0000',
            pulse: true,
          },
        },
      },

      changeLocation: {
        previous: '/tyre/interior/radialplies',
        next: '/tyre/interior/bead',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon black from tires,carbon black tirecarbon black for tires,carbon black in tyres,tyre carbon black price per toncarbon black in tyres,carbon black used in tyres,tyre carbon,carbon black used in tyre industry,tyre black carbon powder uses,tyre carbon black manufacturers,carbon black used in tyre industry,carbon black used in tyres',
      },
    },
    '/mrg': {
      hotspots: [
        {
          position: [-1.311, 1.18, -0.363],
          mobilePosition: [-1.311, 1.28, 0],
          scale: 1,
          title: 'Wipers',
          titleID: 6,
          target: '/mrg/wipers',
          type: 'left',
        },
        {
          position: [-0.629, 1.298, -0.721],
          mobilePosition: [-0.629, 1.298, 0.9],
          scale: 1,
          title: 'Weatherstrip',
          titleID: 7,
          target: '/mrg/weatherstrips',
          type: 'left',
        },
        {
          position: [-1.84, 0.85, -0.28],
          mobilePosition: [-1.84, 0.85, -0.28],
          scale: 1,
          title: 'Coolant Hose',
          titleID: 8,
          target: '/mrg/coolanthose',
          type: 'right',
        },
        {
          position: [-1.76, 1.02, 0.297],
          mobilePosition: [-1.69, 1.08, 0.23],
          scale: 1,
          title: 'Pneumatic Hoses',
          titleID: 9,
          target: '/mrg/pneumatichose',
        },
        {
          position: [-1.708, 0.85, -0.45],
          mobilePosition: [-1.708, 0.85, -0.45],
          scale: 1,
          title: 'Anti-Vibration Bushings',
          titleID: 10,
          target: '/mrg/antivibrationbushings',
          type: 'left',
        },
        {
          position: [-2.19, 0.764, -0.007],
          mobilePosition: [-2.19, 0.764, -0.007],
          scale: 1,
          title: 'Anti-Vibration Mounts',
          titleID: 11,
          target: '/mrg/antivibrationmounts',
        },
        {
          position: [-2.043, 0.8, -0.222],
          mobilePosition: [-2.043, 0.8, -0.222],
          scale: 1,
          title: 'Drivebelt',
          titleID: 12,
          target: '/mrg/drivebelt',
        },
      ],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            // 0 - 6 parts 7 - all
            index: 7,
            color: '#0F0000',
            pulse: false,
          },
        },
        InteriorProps: {
          opacity: 0.2,
        },
        PaintProps: {
          opacity: 0.2,
          animate: true,
        },
        // CarBody: {
        //   opacity: 0.2,
        // },
        // Mrg: {
        //   opacity: 1,
        //   highlight: {
        //     // 0 - 6 parts 7 - all
        //     index: 7,
        //     color: '#0F0000',
        //     pulse: false,
        //   },
        // },
        // Internals: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 0.2,
        // },
        // Hood: {
        //   opacity: 0.5,
        //   animate: true,
        // },
        Tyre: {
          animate: false,
        },
      },

      changeLocation: {
        previous: '/tyre',
        next: '/coatings',
      },
    },
    '/mrg/wipers': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            // 0 - 6 parts 7 - all
            index: 6,
            color: '#2F0000',
            pulse: true,
          },
        },
        InteriorProps: {
          opacity: 0.8,
        },
        PaintProps: {
          opacity: 0.8,
          animate: false,
        },
        // CarBody: {
        //   opacity: 0.8,
        // },
        // Interior: {
        //   opacity: 0.8,
        // },
        // Internals: {
        //   opacity: 0,
        // },
        // Hood: {
        //   opacity: 0.8,
        //   animate: false,
        // },
        // Mrg: {
        //   opacity: 1,
        //   highlight: {
        //     // 0 - 6 parts 7 - all
        //     index: 6,
        //     color: '#2F0000',
        //     pulse: true,
        //   },
        // },
      },

      changeLocation: {
        previous: '/mrg/drivebelt',
        next: '/mrg/weatherstrips',
      },
    },
    '/mrg/weatherstrips': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            // 0 - 6 parts 7 - all
            index: 5,
            color: '#2F0000',
            pulse: true,
          },
        },
        InteriorProps: {
          opacity: 0.8,
        },
        PaintProps: {
          opacity: 0.8,
          animate: false,
        },
        // CarBody: {
        //   opacity: 0.8,
        // },
        // Interior: {
        //   opacity: 0.8,
        // },
        Tyre: {
          opacity: 0.8,
        },
        // Hood: {
        //   opacity: 0.8,
        //   animate: false,
        // },
        // Internals: {
        //   opacity: 0,
        // },
        // Mrg: {
        //   opacity: 1,
        //   highlight: {
        //     // 0 - 6 parts 7 - all
        //     index: 5,
        //     color: '#2F0000',
        //     pulse: true,
        //   },
        // },
      },

      changeLocation: {
        previous: '/mrg/wipers',
        next: '/mrg/coolanthose',
      },
    },
    '/mrg/coolanthose': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            // 0 - 6 parts 7 - all
            index: 2,
            color: '#2F0000',
            pulse: true,
          },
        },
        InteriorProps: {
          opacity: 0.3,
        },
        PaintProps: {
          opacity: 0.3,
          animate: true,
        },
        // CarBody: {
        //   opacity: 0.3,
        // },
        // Interior: {
        //   opacity: 0.3,
        // },
        // Internals: {
        //   opacity: 1,
        // },
        // Hood: {
        //   opacity: 0.5,
        //   animate: true,
        // },
        // Mrg: {
        //   opacity: 1,
        //   highlight: {
        //     // 0 - 6 parts 7 - all
        //     index: 2,
        //     color: '#2F0000',
        //     pulse: true,
        //   },
        // },
      },

      changeLocation: {
        previous: '/mrg/weatherstrips',
        next: '/mrg/pneumatichose',
      },

      meta: {
        title: 'Birla Carbon | Mrg | Coolanthose',
        desc: 'Birla Carbon',
        keywords:
          'carbon black rubber hose manufacturers,carbon black rubber hose,Carbon Black in Mechanical Rubber Goods',
      },
    },
    '/mrg/pneumatichose': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            // 0 - 6 parts 7 - all
            index: 4,
            color: '#1F0000',
            pulse: true,
          },
        },
        InteriorProps: {
          opacity: 0.3,
        },
        PaintProps: {
          opacity: 0.3,
          animate: true,
        },
        // CarBody: {
        //   opacity: 0.3,
        // },
        // Interior: {
        //   opacity: 0.3,
        // },
        // Internals: {
        //   opacity: 1,
        // },
        // Hood: {
        //   opacity: 0.5,
        //   animate: true,
        // },
        // Mrg: {
        //   opacity: 1,
        //   highlight: {
        //     // 0 - 6 parts 7 - all
        //     index: 4,
        //     color: '#1F0000',
        //     pulse: true,
        //   },
        // },
      },

      changeLocation: {
        previous: '/mrg/coolanthose',
        next: '/mrg/antivibrationbushings',
      },

      meta: {
        title: 'Birla Carbon | Mrg | Coolanthose',
        desc: 'Birla Carbon',
        keywords:
          'carbon black rubber hose manufacturers,carbon black rubber hose,Carbon Black in Mechanical Rubber Goods',
      },
    },
    '/mrg/antivibrationbushings': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            // 0 - 6 parts 7 - all
            index: 0,
            color: '#2F0000',
            pulse: true,
          },
        },
        InteriorProps: {
          opacity: 0.3,
        },
        PaintProps: {
          opacity: 0.3,
          animate: true,
        },
        // CarBody: {
        //   opacity: 0.3,
        // },
        // Interior: {
        //   opacity: 0.3,
        // },
        // Internals: {
        //   opacity: 1,
        // },
        // Hood: {
        //   opacity: 0.5,
        //   animate: true,
        // },
        // Mrg: {
        //   opacity: 1,
        //   highlight: {
        //     // 0 - 6 parts 7 - all
        //     index: 0,
        //     color: '#2F0000',
        //     pulse: true,
        //   },
        // },
      },

      changeLocation: {
        previous: '/mrg/pneumatichose',
        next: '/mrg/antivibrationmounts',
      },
    },
    '/mrg/antivibrationmounts': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            // 0 - 6 parts 7 - all
            index: 1,
            color: '#2F0000',
            pulse: true,
          },
        },
        InteriorProps: {
          opacity: 0.3,
        },
        PaintProps: {
          opacity: 0.3,
          animate: true,
        },
        // CarBody: {
        //   opacity: 0.3,
        // },
        // Interior: {
        //   opacity: 0.3,
        // },
        // Internals: {
        //   opacity: 1,
        // },
        // Hood: {
        //   opacity: 0.5,
        //   animate: true,
        // },
        // Mrg: {
        //   opacity: 1,
        //   highlight: {
        //     // 0 - 6 parts 7 - all
        //     index: 1,
        //     color: '#2F0000',
        //     pulse: true,
        //   },
        // },
      },

      changeLocation: {
        previous: '/mrg/antivibrationbushings',
        next: '/mrg/drivebelt',
      },
    },
    '/mrg/drivebelt': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            // 0 - 6 parts 7 - all
            index: 3,
            color: '#2F0000',
            pulse: true,
          },
        },
        InteriorProps: {
          opacity: 0.3,
        },
        PaintProps: {
          opacity: 0.3,
          animate: true,
        },
        // CarBody: {
        //   opacity: 0.3,
        // },
        // Interior: {
        //   opacity: 0.3,
        // },
        // Hood: {
        //   opacity: 0.5,
        //   animate: true,
        // },
        // Mrg: {
        //   opacity: 1,
        //   highlight: {
        //     // 0 - 6 parts 7 - all
        //     index: 3,
        //     color: '#2F0000',
        //     pulse: true,
        //   },
        //   wipers: {
        //     opacity: 1,
        //     highlight: null,
        //   },
        //   weatherstrip: {
        //     opacity: 1,
        //     highlight: null,
        //   },
        //   coolanthose: {
        //     opacity: 0,
        //     highlight: null,
        //   },
        //   pneumatichose: {
        //     opacity: 0,
        //     highlight: null,
        //   },
        //   antivibrationbushings: {
        //     opacity: 0,
        //     highlight: null,
        //   },
        //   antivibrationmounts: {
        //     opacity: 0,
        //     highlight: null,
        //   },
        //   drivebelt: {
        //     opacity: 1,
        //   },
        // },
      },

      changeLocation: {
        previous: '/mrg/antivibrationmounts',
        next: '/mrg/wipers',
      },
    },

    '/inks': {
      hotspots: [
        {
          position: [0.374, 0.6, 0.486],
          mobilePosition: [0.374, 0.6, 0.486],
          scale: 1,
          title: 'Food Packaging',
          titleID: 20,
          target: '/inks/uvink',
        },
        {
          position: [-0.209, 0.63, 0.015],
          mobilePosition: [-0.209, 0.68, 0.015],
          scale: 1,
          title: 'Conductive Ink',
          titleID: 21,
          target: '/inks/conductive',
        },
        {
          position: [-0.016, 0.9, -0.01],
          mobilePosition: [-0.016, 0.9, -0.01],
          scale: 1,
          title: 'Food Packaging Ink',
          titleID: 4,
          target: '/inks/coffee',
        },
        {
          position: [-0.05, 0.01, -0.4],
          mobilePosition: [-0.05, 0.01, -0.35],
          scale: 1,
          title: 'Printing Ink',
          titleID: 24,
          target: '/inks/newspaper',
        },
        {
          position: [0.3, 0.5, 0.3],
          mobilePosition: [0.3, 0.5, 0.3],
          scale: 1,
          title: 'Printing Ink',
          titleID: 22,
          target: '/inks/magazine',
        },
        {
          position: [-0.18, 0.08, -0.62],
          mobilePosition: [-0.18, 0.08, -0.62],
          scale: 1,
          title: 'Printing Ink',
          titleID: 23,
          target: '/inks/printout',
        },
      ],

      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
        Ink: {
          light: true,
          opacity: 1,
        },
      },

      changeLocation: {
        previous: '/coatings',
        next: '/niche',
      },

      meta: {
        title: 'Birla Carbon | Inks',
        desc: 'Birla Carbon',
        keywords:
          'carbon ink,carbon black ink,conductive carbon inks,ink carbon black',
      },
    },
    '/inks/uvink': {
      hotspots: [],

      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
        Ink: {
          light: true,
          opacity: 1,
        },
      },

      changeLocation: {
        previous: '/inks/printout',
        next: '/inks/conductive',
      },

      meta: {
        title: 'Birla Carbon | Inks',
        desc: 'Birla Carbon',
        keywords:
          'carbon ink,carbon black ink,conductive carbon inks,ink carbon black',
      },
    },
    '/inks/conductive': {
      hotspots: [],

      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
        Ink: {
          light: true,
          opacity: 1,
        },
      },

      changeLocation: {
        previous: '/inks/uvink',
        next: '/inks/coffee',
      },
      meta: {
        title: 'Birla Carbon | Inks',
        desc: 'Birla Carbon',
        keywords:
          'carbon ink,carbon black ink,conductive carbon inks,ink carbon black',
      },
    },
    '/inks/coffee': {
      hotspots: [],

      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
        Ink: {
          light: true,
          opacity: 1,
        },
      },

      changeLocation: {
        previous: '/inks/conductive',
        next: '/inks/newspaper',
      },

      meta: {
        title: 'Birla Carbon | Inks',
        desc: 'Birla Carbon',
        keywords:
          'carbon ink,carbon black ink,conductive carbon inks,ink carbon black',
      },
    },
    '/inks/newspaper': {
      hotspots: [],

      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
        Ink: {
          light: true,
          opacity: 1,
        },
      },

      changeLocation: {
        previous: '/inks/coffee',
        next: '/inks/magazine',
      },

      meta: {
        title: 'Birla Carbon | Inks',
        desc: 'Birla Carbon',
        keywords:
          'carbon ink,carbon black ink,conductive carbon inks,ink carbon black',
      },
    },
    '/inks/magazine': {
      hotspots: [],

      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
        Ink: {
          light: true,
          opacity: 1,
        },
      },

      changeLocation: {
        previous: '/inks/newspaper',
        next: '/inks/printout',
      },

      meta: {
        title: 'Birla Carbon | Inks',
        desc: 'Birla Carbon',
        keywords:
          'carbon ink,carbon black ink,conductive carbon inks,ink carbon black',
      },
    },
    '/inks/printout': {
      hotspots: [],

      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
        Ink: {
          light: true,
          opacity: 1,
        },
      },

      changeLocation: {
        previous: '/inks/magazine',
        next: '/inks/uvink',
      },

      meta: {
        title: 'Birla Carbon | Inks',
        desc: 'Birla Carbon',
        keywords:
          'carbon ink,carbon black ink,conductive carbon inks,ink carbon black',
      },
    },

    '/niche': {
      hotspots: [
        {
          position: [-0.04, 1.13, 0.19],
          mobilePosition: [-0.04, 1.13, 0.19],
          scale: 1,
          title: 'PU Seat Foam',
          titleID: 25,
          target: '/niche/puseatfoam',
        },
        {
          position: [-0.7099999999999986, 1.54, -0.21999999999999992],
          mobilePosition: [-0.7099999999999986, 1.54, -0.21999999999999992],
          scale: 1,
          title: 'Windshield Sealant',
          titleID: 26,
          target: '/niche/windshield',
        },
        {
          position: [-1.93, 0.95, 0.33],
          mobilePosition: [-1.93, 0.95, 0.33],
          scale: 1,
          title: 'Lead Acid Battery',
          titleID: 27,
          target: '/niche/battery',
        },
        {
          position: [1.1, 0.69, -0.87],
          mobilePosition: [1.1, 0.69, -0.87],
          scale: 1,
          title: 'Brake Pad',
          titleID: 28,
          target: '/niche/brakepad',
        },
      ],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            index: 8, // 0-1 parts 2 all
            color: '#0f0000',
            pulse: false,
          },
        },
        InteriorProps: {
          opacity: 1,
          highlight: {
            index: 0, // 0-1 parts 2 all
            color: '#030000',
            pulse: false,
          },
        },
        PaintProps: {
          opacity: 0.2,
          animate: true,
        },
        // CarBody: {
        //   opacity: 0.5,
        // },
        // Interior: {
        //   opacity: 1,
        //   seats: '#1F0000',
        // },
        // Niche: {
        //   opacity: 1,
        //   highlight: {
        //     index: 2, // 0-1 parts 2 all
        //     color: '#1F0000',
        //     pulse: false,
        //   },
        // },
      },

      changeLocation: {
        previous: '/inks',
        next: '/plastics',
      },
    },

    '/niche/puseatfoam': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          // highlight: {
          //   index: 8, // 0-1 parts 2 all
          //   color: '#030000',
          //   pulse: false,
          // },
        },
        InteriorProps: {
          opacity: 1,
          light: true,
          lightPosition: [0, 1.2, 0],
        },
        PaintProps: {
          opacity: 1,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        //   light: true,
        // },

        // Niche: {
        //   opacity: 1,
        //   highlight: {
        //     index: 2, // 0-1 parts 2 all
        //     color: '#000000',
        //     pulse: false,
        //   },
        // },
      },

      changeLocation: {
        previous: '/niche/brakepad',
        next: '/niche/windshield',
      },
    },
    '/niche/windshield': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            index: 9, // 0-1 parts 2 all
            color: '#020000',
            pulse: true,
          },
        },
        InteriorProps: {
          opacity: 1,
        },
        PaintProps: {
          opacity: 1,
        },
        GlassProps: {
          opacity: 0.2,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        // },
        // Niche: {
        //   opacity: 1,
        //   highlight: {
        //     index: 1, // 0-1 parts 2 all
        //     color: '#2F0000',
        //     pulse: true,
        //   },
        // },
      },

      changeLocation: {
        previous: '/niche/puseatfoam',
        next: '/niche/battery',
      },
    },
    '/niche/battery': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            index: 10, // 0-1 parts 2 all
            color: '#0f0000',
            pulse: true,
          },
          animate: true,
        },
        InteriorProps: {
          opacity: 1,
        },
        PaintProps: {
          opacity: 0.2,
          animate: true,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        // },
        // Hood: {
        //   animate: true,
        //   highlight: '#000000',
        // },
        // Internals: {
        //   opacity: 0.8,
        // },
        // Niche: {
        //   opacity: 1,
        //   highlight: {
        //     index: 0, // 1-2 parts 3 all
        //     color: '#2F0000',
        //     pulse: true,
        //   },
        // },
      },

      changeLocation: {
        previous: '/niche/windshield',
        next: '/niche/brakepad',
      },
    },
    '/niche/brakepad': {
      hotspots: [],

      threed: {
        BrakePadProps: {
          animate: true,
        },
      },

      changeLocation: {
        previous: '/niche/battery',
        next: '/niche/puseatfoam',
      },
    },

    '/plastics': {
      hotspots: [
        {
          position: [-2.0, 1.0, -0.3],
          mobilePosition: [-2.0, 1.0, -0.3],
          scale: 1,
          title: 'Exterior',
          target: '/plastics/exterior',
          misc: 'underthehood',
        },
        {
          position: [-1.01, 1.3, 0.2],
          mobilePosition: [-1.01, 1.3, 0.2],
          scale: 1,
          title: 'Interior',
          target: '/plastics/interior',
          misc: 'interior',
        },
      ],

      threed: {
        CarBody: {
          opacity: 1,
        },
        Interior: {
          opacity: 1,
        },
        Internals: {
          opacity: 1,
        },
      },

      changeLocation: {
        previous: '/niche',
        next: '/tyre',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon plastic,carbon black in plastics,carbon black plastic,black carbon plastic,carbon black filled hdpe,carbon black polyethylene,conductive carbon black filled hdpe',
      },
    },

    '/plastics/interior': {
      hotspots: [
        {
          position: [-1.0, 1.0, -0.3],
          mobilePosition: [-1.0, 1.0, -0.3],
          scale: 1,
          title: 'Dashboard Panel',
          titleID: 30,
          target: '/plastics/interior/dashboardpanel',
        },
        {
          position: [-0.5, 0.97, 0.3],
          mobilePosition: [-0.5, 0.97, 0.3],
          scale: 1,
          title: 'Steering Wheel',
          titleID: 31,
          target: '/plastics/interior/steeringwheel',
        },
        {
          position: [-0.5, 0.93, -0.2],
          mobilePosition: [-0.5, 0.93, -0.2],
          scale: 1,
          title: 'Glove Box',
          titleID: 32,
          target: '/plastics/interior/glovebox',
        },
        {
          // position: [-0.5, 1.05, -0.03],
          // mobilePosition: [-0.5, 1.08, -0.03],
          position: [-0.52, 1.01, -0.05],
          mobilePosition: [-0.5, 1.08, -0.03],
          scale: 1,
          title: 'Center console',
          titleID: 33,
          target: '/plastics/interior/centerconsole',
        },
        {
          position: [-1.0, 0.4, -0.3],
          mobilePosition: [-1.0, 0.4, -0.3],
          scale: 1,
          title: 'Carpet',
          titleID: 35,
          target: '/plastics/interior/carpet',
        },
      ],

      threed: {
        ExteriorProps: {
          opacity: 1,
        },
        InteriorProps: {
          opacity: 1,
        },
        PaintProps: {
          opacity: 1,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        // },
        // Plastics: {
        //   radiatorgrill: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   dashboardpanel: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   steeringwheel: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   glovebox: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   centerconsole: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   airintake: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   carpet: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        // },
      },

      changeLocation: {
        previous: '/plastics/exterior',
        next: '/plastics/exterior',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon plastic,carbon black in plastics,carbon black plastic,black carbon plastic,carbon black filled hdpe,carbon black polyethylene,conductive carbon black filled hdpe',
      },
    },

    '/plastics/interior/dashboardpanel': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
        },
        InteriorProps: {
          opacity: 1,
          light: true,
          lightPosition: [0, 0.36, -0.45],
        },
        PaintProps: {
          opacity: 1,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        // },
        // Plastics: {
        //   radiatorgrill: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   dashboardpanel: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   steeringwheel: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   glovebox: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   centerconsole: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   airintake: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   carpet: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        // },
      },
      changeLocation: {
        previous: '/plastics/interior/carpet',
        next: '/plastics/interior/steeringwheel',
      },
      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon plastic,carbon black in plastics,carbon black plastic,black carbon plastic,carbon black filled hdpe,carbon black polyethylene,conductive carbon black filled hdpe',
      },
    },

    '/plastics/interior/steeringwheel': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
        },
        InteriorProps: {
          opacity: 1,
        },
        PaintProps: {
          opacity: 1,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        // },
        // Plastics: {
        //   radiatorgrill: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   dashboardpanel: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   steeringwheel: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   glovebox: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   centerconsole: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   airintake: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   carpet: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        // },
      },
      changeLocation: {
        previous: '/plastics/interior/dashboardpanel',
        next: '/plastics/interior/glovebox',
      },
      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon plastic,carbon black in plastics,carbon black plastic,black carbon plastic,carbon black filled hdpe,carbon black polyethylene,conductive carbon black filled hdpe',
      },
    },

    '/plastics/interior/glovebox': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
        },
        InteriorProps: {
          opacity: 1,
          light: true,
          lightPosition: [0, 0.36, -0.45],
        },
        PaintProps: {
          opacity: 1,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        // },
        // Plastics: {
        //   radiatorgrill: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   dashboardpanel: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   steeringwheel: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   glovebox: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   centerconsole: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   airintake: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   carpet: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   light: true,
        // },
      },
      changeLocation: {
        previous: '/plastics/interior/steeringwheel',
        next: '/plastics/interior/centerconsole',
      },
      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon plastic,carbon black in plastics,carbon black plastic,black carbon plastic,carbon black filled hdpe,carbon black polyethylene,conductive carbon black filled hdpe',
      },
    },

    '/plastics/interior/centerconsole': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
        },
        InteriorProps: {
          opacity: 1,
        },
        PaintProps: {
          opacity: 1,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        // },
        // Plastics: {
        //   radiatorgrill: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   dashboardpanel: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   steeringwheel: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   glovebox: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   centerconsole: {
        //     opacity: 1,
        //     highlight: '#440000',
        //   },
        //   airintake: {
        //     opacity: 1,
        //   highlight: '#440000',
        // },
        // carpet: {
        //   opacity: 1,
        //   highlight: '#440000',
        // },
        // },
      },
      changeLocation: {
        previous: '/plastics/interior/glovebox',
        next: '/plastics/interior/carpet',
      },
      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon plastic,carbon black in plastics,carbon black plastic,black carbon plastic,carbon black filled hdpe,carbon black polyethylene,conductive carbon black filled hdpe',
      },
    },

    '/plastics/interior/carpet': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
        },
        InteriorProps: {
          opacity: 1,
        },
        PaintProps: {
          opacity: 1,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        // },
        // Plastics: {
        //   color: '#2F0000',
        //   pulse: true,
        //   index: 0,
        // },
      },

      changeLocation: {
        previous: '/plastics/interior/centerconsole',
        next: '/plastics/interior/dashboardpanel',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon plastic,carbon black in plastics,carbon black plastic,black carbon plastic,carbon black filled hdpe,carbon black polyethylene,conductive carbon black filled hdpe',
      },
    },

    '/plastics/exterior': {
      hotspots: [
        {
          position: [-2.0, 0.2, -1.0],
          mobilePosition: [-1, -0.22, -2.4],
          scale: 1,
          title: 'Front Bumper (Radiator Grill)',
          titleID: 29,
          target: '/plastics/exterior/radiatorgrill',
        },
        {
          position: [-1, 0.83, -0.8],
          mobilePosition: [0, 0.8, -1.8],
          scale: 1,
          title: 'Air intake Manifold cover',
          titleID: 34,
          target: '/plastics/exterior/airintake',
        },
      ],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            index: 11, // 0-1 parts 2 all
            color: '#2F0000',
            pulse: false,
          },
        },
        InteriorProps: {
          opacity: 0.5,
        },
        PaintProps: {
          opacity: 0.5,
          animate: true,
        },
        // CarBody: {
        //   opacity: 0.5,
        // },
        // Interior: {
        //   opacity: 0.5,
        // },
        // Internals: {
        //   opacity: 0.5,
        // },
        // Plastics: {
        //   color: '#2F0000',
        //   pulse: false,
        //   index: 3,
        // },
        // Hood: {
        //   animate: true,
        // },
      },

      changeLocation: {
        previous: '/plastics/interior',
        next: '/plastics/interior',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon plastic,carbon black in plastics,carbon black plastic,black carbon plastic,carbon black filled hdpe,carbon black polyethylene,conductive carbon black filled hdpe',
      },
    },

    '/plastics/exterior/radiatorgrill': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            index: 13, // 0-1 parts 2 all
            color: '#2F0000',
            pulse: true,
          },
        },
        InteriorProps: {
          opacity: 1,
        },
        PaintProps: {
          opacity: 1,
        },
        // CarBody: {
        //   opacity: 1,
        // },
        // Interior: {
        //   opacity: 1,
        // },
        // Plastics: {
        //   color: '#2F0000',
        //   pulse: true,
        //   index: 2,
        // },
      },

      changeLocation: {
        previous: '/plastics/exterior/airintake',
        next: '/plastics/exterior/airintake',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon plastic,carbon black in plastics,carbon black plastic,black carbon plastic,carbon black filled hdpe,carbon black polyethylene,conductive carbon black filled hdpe',
      },
    },

    '/plastics/exterior/airintake': {
      hotspots: [],

      threed: {
        ExteriorProps: {
          opacity: 1,
          highlight: {
            index: 12, // 0-1 parts 2 all
            color: '#2F0000',
            pulse: true,
          },
        },
        InteriorProps: {
          opacity: 0.5,
        },
        PaintProps: {
          opacity: 0.5,
          animate: true,
        },
        // CarBody: {
        //   opacity: 0.5,
        // },
        // Interior: {
        //   opacity: 0.5,
        // },
        // Internals: {
        //   opacity: 0.5,
        // },
        // Plastics: {
        //   color: '#2F0000',
        //   pulse: true,
        //   index: 1,
        // },
        // Hood: {
        //   animate: true,
        // },
      },

      changeLocation: {
        previous: '/plastics/exterior/radiatorgrill',
        next: '/plastics/exterior/radiatorgrill',
      },

      meta: {
        title: 'Birla Carbon | Tire',
        desc: 'Birla Carbon',
        keywords:
          'carbon plastic,carbon black in plastics,carbon black plastic,black carbon plastic,carbon black filled hdpe,carbon black polyethylene,conductive carbon black filled hdpe',
      },
    },
  },
  carMaterial: {
    roughness: 0.2,
    metalness: 0.9,
    clearcoat: 0,
    clearcoatRoughness: 0.1,
    transmission: 0,
    color: '#070606',
  },

  // getApiToken: async (onDone) => { //! cached
  //   // return cachedData.getApiToken
  //   // var axios = require('axios')

  //   // var data = JSON.stringify(get().cred)
  //   // var config = {
  //   //   method: 'post',
  //   //   url: get().baseURL + 'auth/local',
  //   //   headers: {
  //   //     'Content-Type': 'application/json',
  //   //   },
  //   //   data: data,
  //   // }
  //   // try {
  //   //   axios(config)
  //   //     .then(function (response) {
  //   //       // console.log(response);
  //   set((state) => ({ apiToken: 'Bearer ' + cachedData.getApiToken.jwt }))
  //   useSFDC.setState({ apiToken: 'Bearer ' + cachedData.getApiToken.jwt })
  //   onDone(true)
  //   //     })
  //   //     .catch(function (error) {
  //   // console.log(error);
  //   //     })
  //   // } catch (e) {
  //   //   onDone(false)
  //   // }
  // },

  getApiToken: async (onDone) => {
    var axios = require('axios')
    var data = JSON.stringify(get().cred)
    var config = {
      method: 'post',
      url: get().baseURL + 'auth/local',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }
    try {
      axios(config)
        .then(function (response) {
          // console.log(response)
          set((state) => ({ apiToken: 'Bearer ' + response.data.jwt }))
          useSFDC.setState({ apiToken: 'Bearer ' + response.data.jwt })
          onDone(true)
        })
        .catch(function (error) {
          // console.log(error);
        })
    } catch (e) {
      onDone(false)
    }
  },

  // getCategories: async (onDone) => { //! cached
  //   // var config = {
  //   //   method: 'get',
  //   //   url: get().baseURL + 'categories?_locale=' + get().locale + '&_limit=-1',
  //   //   headers: {
  //   //     Authorization: get().apiToken,
  //   //   },
  //   // }
  //   // try {
  //   //   axios(config)
  //   //     .then(function (response) {
  //   let cur = get().data
  //   for (var ind in cachedData.getCategories) {
  //     let name = cachedData.getCategories[ind].Name
  //     console.log(name)
  //     let id = cachedData.getCategories[ind].id
  //     if (id === 2 || id === 17 || id === 26 || id === 29) {
  //       cur.categories.Inks.display = name.toUpperCase()
  //       cur.categories.Inks.id = cachedData.getCategories[ind].id
  //     } else if (id === 3 || id === 18 || id === 25 || id === 30) {
  //       cur.categories.Mrg.display = name.toUpperCase()
  //       cur.categories.Mrg.id = cachedData.getCategories[ind].id
  //     } else if (id === 12 || id === 16 || id === 27 || id === 28) {
  //       cur.categories.Coatings.display = name.toUpperCase()
  //       cur.categories.Coatings.id = cachedData.getCategories[ind].id
  //     } else if (id === 13 || id === 19 || id === 24 || id === 31) {
  //       cur.categories.Niche.display = name.toUpperCase()
  //       cur.categories.Niche.id = cachedData.getCategories[ind].id
  //     } else if (id === 14 || id === 20 || id === 23 || id === 32) {
  //       cur.categories.Plastics.display = name.toUpperCase()
  //       cur.categories.Plastics.id = cachedData.getCategories[ind].id
  //     } else if (id === 15 || id === 21 || id === 22 || id === 33) {
  //       cur.categories.Tyre.display = name.toUpperCase()
  //       cur.categories.Tyre.id = cachedData.getCategories[ind].id
  //     }
  //   }
  //   set((state) => ({ data: cur }))
  //   onDone(cur)
  //   //     })
  //   //     .catch(function (error) {
  //   //       onDone(false)
  //   //     })
  //   // } catch (e) {
  //   //   onDone(false)
  //   // }
  // },

  getCategories: async (onDone) => {
    var config = {
      method: 'get',
      url: get().baseURL + 'categories?locale=' + get().locale + '&_limit=-1',
      headers: {
        Authorization: get().apiToken,
      },
    }
    try {
      axios(config)
        .then(function (response) {
          let cur = get().data
          // console.log('categpries api: ', response.data)
          for (var ind in response.data) {
            for (var item_id in response.data[ind]) {
              let id = response.data[ind][item_id].id
              let name =
                response.data[ind][item_id].attributes != null &&
                response.data[ind][item_id].attributes.Name
              // console.log('cat name: ', id)
              //   let name = response.data[ind].Name
              // let id = response.data[ind].id
              // console.log('continua ids: ' + response.data[ind].id)
              if (id === 2 || id === 17 || id === 26 || id === 29) {
                cur.categories.Inks.display = name.toUpperCase()
                cur.categories.Inks.id = response.data[ind][item_id].id
              } else if (id === 3 || id === 18 || id === 25 || id === 30) {
                cur.categories.Mrg.display = name.toUpperCase()
                // cur.continuaCategories.Mrg.display = name.toUpperCase()
                cur.categories.Mrg.id = response.data[ind][item_id].id
              } else if (id === 12 || id === 16 || id === 27 || id === 28) {
                cur.categories.Coatings.display = name.toUpperCase()
                cur.categories.Coatings.id = response.data[ind][item_id].id
              }
              // else if (id === 13 || id === 19 || id === 24 || id === 31) {
              //   cur.categories.Niche.display = name.toUpperCase()
              //   cur.categories.Niche.id = response.data[ind].id
              // }
              else if (id === 14 || id === 20 || id === 23 || id === 32) {
                cur.categories.Plastics.display = name.toUpperCase()
                cur.categories.Plastics.id = response.data[ind][item_id].id
              } else if (id === 15 || id === 21 || id === 22 || id === 33) {
                cur.categories.Tyre.display = name.toUpperCase()
                cur.categories.Tyre.id = response.data[ind][item_id].id
              } else if (id === 34 || id === 36) {
                cur.categories.EnergySystem.display = name.toUpperCase()
                cur.categories.EnergySystem.id = response.data[ind][item_id].id
              } else if (id === 35) {
                // cur.categories.Continua.display = name.toUpperCase()
                cur.categories.Continua.id = response.data[ind][item_id].id
              } else if (id === 37 || id === 38 || id === 39 || id === 40) {
                cur.continuaCategories.Mrg.display = name.toUpperCase()
                cur.continuaCategories.Mrg.id = response.data[ind][item_id].id
              } else if (id === 41 || id === 42 || id === 43 || id === 44) {
                cur.continuaCategories.Coatings.display = name.toUpperCase()
                cur.continuaCategories.Coatings.id =
                  response.data[ind][item_id].id
              } else if (id === 45 || id === 46 || id === 47 || id === 48) {
                cur.continuaCategories.Tyre.display = name.toUpperCase()
                cur.continuaCategories.Tyre.id = response.data[ind][item_id].id
              } else if (id === 49 || id === 50 || id === 51 || id === 52) {
                cur.continuaCategories.Plastics.display = name.toUpperCase()
                cur.continuaCategories.Plastics.id =
                  response.data[ind][item_id].id
              }
            }
            // response.data[ind].map((item) => {

            // })
          }
          set((state) => ({ data: cur }))
          onDone(response, cur)
        })
        .catch(function (error) {
          console.log('cat error: ', error)
          onDone(false)
        })
    } catch (e) {
      onDone(false)
    }
  },

  getItems: async (onDone, cur) => {
    let resp = []

    get().getItemsforCategory(cur.categories.Inks.id, add)

    function add(item) {
      resp.push(item)

      switch (resp.length) {
        case 1:
          get().getItemsforCategory(cur.categories.Mrg.id, add)
          break
        case 2:
          get().getItemsforCategory(cur.categories.Coatings.id, add)
          break
        // case 3:
        //   get().getItemsforCategory(cur.categories.Niche.id, add)
        //   break
        case 3:
          get().getItemsforCategory(cur.categories.Plastics.id, add)
          break
        case 4:
          get().getItemsforCategory(cur.categories.Tyre.id, add)
          break
        case 5:
          get().getItemsforCategory(cur.categories.EnergySystem.id, add)
          break
        case 6:
          get().getItemsforCategory(cur.categories.Continua.id, add)
          break
        case 7:
          get().getItemsforCategory(cur.continuaCategories.Mrg.id, add)
          break
        case 8:
          get().getItemsforCategory(cur.continuaCategories.Coatings.id, add)
          break
        case 9:
          get().getItemsforCategory(cur.continuaCategories.Tyre.id, add)
          break
        case 10:
          get().getItemsforCategory(cur.continuaCategories.Plastics.id, add)
          break
        case 11:
          cleanResponse()
          break
        default:
          console.log('unknown')
          break
      }
    }

    // {data.performanceNeeds.length != 0 && (
    //   < className={css(styles.sub_container)}>
    //     <div className={css(styles.titleContainer)}>
    //       <div className={css(styles.title)} />
    //       <div className={css(styles.desc01)}>
    //         {getNameWhereId(props.titles, 4)}
    //       </div>
    //       {data.performanceNeeds != null && (
    //         <>
    //           {data.performanceNeeds.length == 1 && (
    //             <div className={css(styles.soldesc)}>
    //               {data.performanceNeeds[0].text_value}
    //             </div>
    //           )}
    //           {data.performanceNeeds.length > 1 && (
    //             <ul className={css(styles.solul)}>
    //               {data.performanceNeeds.map((data, index) => {
    //                 return (
    //                   <li
    //                     key={index}
    //                     className={css(styles.soldesc)}
    //                   >
    //                     {data.text_value}
    //                   </li>
    //                 )
    //               })}
    //             </ul>
    //           )}
    //         </>
    //       )}
    //     </div>
    //   </div>

    function cleanResponse() {
      let cleaned = new Array([])
      for (var i in resp) {
        for (var j in resp[i]) {
          let dat = resp[i][j]

          cleaned[dat.sub_category] = {
            element: dat.sub_sector,
            annotation: dat.annotation,
            painPoints: dat.pain_points,
            value: {
              text: dat.next_gen_products,
              //dat.birla_carbon_solution
              image: dat.value_image,
            },

            productsOffered: dat.products_offered,
            solutions: dat.solutions,
            solutionsImage: dat.solutions_image,
            nextGenProducts: dat.next_gen_products,
            id: dat.id,
            benefits: dat.benefits,
            performanceNeeds: dat.performance_needs,
          }
        }
      }
      // console.log('cleaned items: ', cleaned)
      cur.components = cleaned
      set({ data: cur })
      onDone(true)
    }
  },

  // getTitles: async (onDone) => { //! Cached
  //   // var config = {
  //   //   method: 'get',
  //   //   url:
  //   //     get().baseURL +
  //   //     'component-titles?_locale=' +
  //   //     get().locale +
  //   //     '&_limit=-1',
  //   //   headers: {
  //   //     Authorization: get().apiToken,
  //   //   },
  //   // }
  //   // try {
  //   //   axios(config)
  //   //     .then(function (response) {
  //   set({ titles: cachedData.getTitles })
  //   onDone(true)
  //   //     })
  //   //     .catch(function (error) {
  //   //       // console.log(error);
  //   //       onDone(false)
  //   //     })
  //   // } catch (e) {
  //   //   onDone(false)
  //   // }
  // },

  getTitles: async (onDone) => {
    var config = {
      method: 'get',
      url:
        get().baseURL +
        'component-titles?locale=' +
        get().locale +
        '&pagination[start]=0&pagination[limit]=100',
      headers: {
        Authorization: get().apiToken,
      },
    }
    try {
      axios(config)
        .then(function (response) {
          // for (let ind in response.data) {
          //   set({ titles: response.data[ind] })
          // }
          // return response.data;

          let dat = response.data
          let dat_Array = dat.data
          let outputArray = []

          for (let ind in dat_Array) {
            const currentItem = dat_Array[ind]
            let newObject = {
              id: currentItem.id,
              ...currentItem.attributes,
            }
            outputArray.push(newObject)
          }
          set({ titles: outputArray })
          // console.log('title data: ', outputArray)
          onDone(true)
        })
        .catch(function (error) {
          // console.log(error);
          onDone(false)
        })
    } catch (e) {
      onDone(false)
    }
  },

  // getMisc: async (onDone) => { //! Cached
  //   // var config = {
  //   //   method: 'get',
  //   //   url:
  //   //     get().baseURL +
  //   //     'miscellaneous-items?_locale=' +
  //   //     get().locale +
  //   //     '&_limit=-1',
  //   //   headers: {
  //   //     Authorization: get().apiToken,
  //   //   },
  //   // }
  //   // try {
  //   //   axios(config)
  //   //     .then(function (response) {
  //   set({ misc: cachedData.getMisc })
  //   cleanResponse(cachedData.getMisc)
  //   //     })
  //   //     .catch(function (error) {
  //   //       onDone(false)
  //   //     })
  //   // } catch (e) {
  //   //   // console.log(e);
  //   //   onDone(false)
  //   // }

  //   function cleanResponse(data) {
  //     let cleaned = new Array([])
  //     for (var ind in data) {
  //       let dat = data[ind]
  //       cleaned[dat.title_key] = dat
  //     }
  //     set({ misc: cleaned })
  //     onDone(true)
  //   }
  // },

  getMisc: async (onDone) => {
    var config = {
      method: 'get',
      url:
        get().baseURL +
        'miscellaneous-items?locale=' +
        get().locale +
        '&pagination[start]=0&pagination[limit]=100',
      //  +
      // '&_limit=-1',
      headers: {
        Authorization: get().apiToken,
      },
    }
    try {
      axios(config)
        .then(function (response) {
          // console.log('misc resp: ', response.data)
          let dat = response.data
          let dat_Array = dat.data
          let outputArray = []
          // console.log('daata array: ', dat_Array)
          for (let ind in dat_Array) {
            const currentItem = dat_Array[ind]
            let newObject = {
              id: currentItem.id,
              ...currentItem.attributes,
            }
            outputArray.push(newObject)
            // obj[ind] = newObj
          }
          // console.log('object misc: ', outputArray)
          set({ misc: outputArray })

          cleanResponse(outputArray)
        })

        .catch(function (error) {
          onDone(false)
        })
    } catch (e) {
      console.log(e)
      onDone(false)
    }
    function cleanResponse(data) {
      let cleaned = new Array([])

      for (var ind in data) {
        let dat = data[ind]

        cleaned[dat.title_key] = dat
        // console.log('clean data', cleaned[dat.title_key])
      }
      set({ misc: cleaned })
      onDone(true)
    }
  },

  // getItemsforCategory: async (id, add) => { //! Cached
  //   // var config = {
  //   //   method: 'get',
  //   //   url:
  //   //     get().baseURL +
  //   //     'items?_locale=' +
  //   //     get().locale +
  //   //     '&_limit=-1&category=' +
  //   //     id,
  //   //   headers: {
  //   //     Authorization: get().apiToken,
  //   //   },
  //   // }

  //   // try {
  //   //   axios(config)
  //   //     .then(function (response) {

  //   // return response.data;
  //   add(cachedData.getItemsforCategory)
  //   //     })
  //   //     .catch(function (error) {
  //   //       // console.log(error);
  //   //       return null
  //   //     })
  //   // } catch (e) {
  //   //   console.log(e)
  //   //   return null
  //   // }
  // },

  getItemsforCategory: async (id, add) => {
    var config = {
      method: 'get',
      url:
        get().baseURL +
        'items?locale=' +
        get().locale +
        '&pagination[start]=0&pagination[limit]=100' +
        id +
        '&populate=*',
      headers: {
        Authorization: get().apiToken,
      },
    }

    try {
      axios(config)
        .then(function (response) {
          // return response.data;
          // console.log('item resp: ', config.url)
          let dat = response.data
          let dat_Array = dat.data
          let outputArray = []

          for (let ind in dat_Array) {
            const currentItem = dat_Array[ind]
            let newObject = {
              id: currentItem.id,
              ...currentItem.attributes,
            }
            outputArray.push(newObject)
            // obj[ind] = newObj
          }
          // console.log('items for specific cat: ', outputArray)
          add(outputArray)
        })
        .catch(function (error) {
          // console.log(error);
          return null
        })
    } catch (e) {
      console.log(e)
      return null
    }
  },

  isCurrentTarget: (a) => {
    if (
      a.category === get().current.category &&
      a.product === get().current.product
    )
      return true
    else return false
  },

  getCurrentObject: () => {
    let objs = get().objects
    let target = { hotspots: [] }
    for (var index in objs) {
      if (get().isCurrentTarget(objs[index].identifier)) {
        target = objs[index]
        break
      }
    }

    return target
  },

  getObject: (location) => {
    let targ = get().objects[location]
    if (targ != null) return targ
    else return { hotspots: [] }
  },
}))

export const useEnvironment = create((set, get) => ({
  current: 0,
  environments: [
    {
      //Day
      ambient: {
        intensity: 0.4,
        color: '#c7bdae',
      },
      hemi: {
        intensity: 0.15,
        sky: '#598ed1',
        ground: '#5c4c32',
      },
    },
    {
      //Night
      ambient: {
        intensity: 0.6,
        color: '#adaec7',
      },
      hemi: {
        intensity: 0.15,
        sky: '#baac97',
        ground: '#2e271b',
      },
    },
    {
      //Continua
      ambient: {
        intensity: 0.8,
        color: '#adaec7',
      },
      hemi: {
        intensity: 0.15,
        sky: '#baac97',
        ground: '#2e271b',
      },
    },
  ],
  toggle: () => {
    set((state) => ({ current: state.current === 0 ? 1 : 0 }))
  },
  continuaToggle: () => {
    if (get().current === 0 || get().current === 1) {
      set((state) => ({ current: (state.current = 2) }))
    }
  },
  categoriesClick: () => {
    if (get().current === 2) {
      set((state) => ({ current: (state.current = 0) }))
    }
  },
}))

export const useTween = create((set) => ({
  activeTweenPoint: null,
  tweenActive: false,

  tweenDuration: 2000,
  tweenInterpolation: TWEEN.Easing.Quadratic.InOut,
  tweenName: 'default',

  startTween: (
    tweenPoint,
    tweenName,
    tweenDuration = 2000,
    interp = TWEEN.Easing.Quadratic.InOut
  ) => {
    set((state) => ({
      activeTweenPoint: tweenPoint,
      tweenActive: true,
      tweenDuration: tweenDuration,
      tweenInterpolation: interp,
      tweenName: tweenName,
    }))
  },

  tweenFinished: () => {
    set((state) => ({
      tweenActive: false,
    }))
  },
}))

export const useHud = create((set) => ({
  openNav: false,
  hotspots: true,
  languagePrompt: false,
  country_name: null,

  setCountryName: (string) => set((state) => (state.country_name = string)),
  setOpenNav: (bool) => set((state) => ({ openNav: bool })),
  setLanguagePrompt: (bool) => set((state) => ({ languagePrompt: bool })),
  setHideHotspots: (bool) => set((state) => ({ hotspots: bool })),

  setAudioSrc: (src) => set((state) => (state.audioSrc = src)),
  setObjAudio: (bol) => set((state) => (state.objAudio = bol)),
  setContinuaLoading: (bool) => set((state) => (state.continuaLoading = bool)),
}))

// export const useInfo = create((set) => ({ //! cached
//   leftInfo: false,
//   rightInfo: false,
//   detailsPage: false,
//   setDetailsPage: (bool) => set((state) => ({ detailsPage: bool })),
//   setLeftInfo: (bool) => set((state) => ({ leftInfo: bool })),
//   setRightInfo: (bool) => set((state) => ({ rightInfo: bool })),
// }))

// export const useSFDC = create((set, get) => ({
//   showForm: false,
//   baseURL: 'https://admin.digitalshowroom.birlacarbon.com/',
//   //https://admin.digitalshowroom.birlacarbon.com
//   //https://autovrse-strapi.biotrade.in/
//   apiToken:
//     'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjM4MTcwNzc2LCJleHAiOjE2NDA3NjI3NzZ9.3NThVKfl9zDvD_tUdc1cFIJJ9n_SiO1NEIHX_1EoGe4',
//   showFormLearnMore: false,
//   setShowForm: (bool) => set((state) => ({ showForm: bool })),
//   setShowFormLearnMore: (bool) => set((state) => ({ showFormLearnMore: bool })),
//   submitForm: async (form, sector = 'Not Selected', retn) => {
//     // // Analytics Pending
//     // console.log(sector)
//     // let cntry = 'UNDEF'
//     // try {
//     //   await axios
//     //     .get('https://ipapi.co/json/')
//     //     .then((response) => {
//     //       let data = response.data
//     //       cntry = data.country_coded
//     //     })
//     //     .catch((error) => {
//     //       cntry = 'UNDEF'
//     //     })
//     // } catch (e) {
//     //   cntry = 'UNDEF'
//     // }
//     // let extraa =
//     //   useAnalytics.getState().output != null
//     //     ? useAnalytics.getState().output
//     //     : [{ section: 'NA', sector: 'NA', 'time-spent': 'NA:NA' }]
//     // var data = JSON.stringify({
//     //   form: {
//     //     'market-sector': sector,
//     //     'first-name': form.firstName,
//     //     'last-name': form.lastName,
//     //     phone: form.Phone,
//     //     email: form.Email,
//     //     company: form.Company,
//     //     title: form.Title,
//     //     city: form.City,
//     //     state: form.State,
//     //     street: form.Street,
//     //     country: cntry,
//     //     comments: form.Comment,
//     //   },
//     //   extra: extraa,
//     // })
//     // console.log(data)
//     // console.log(useMaster.getState().apiToken)
//     // var config = {
//     //   method: 'post',
//     //   url: get().baseURL + 'miscellaneous-items/autovrsetoSFDC',
//     //   headers: {
//     //     Authorization: useMaster.getState().apiToken,
//     //     'Content-Type': 'application/json',
//     //   },
//     //   data: data,
//     // }
//     // try {
//     //   axios(config)
//     //     .then(function (response) {
//     //       console.log(response)
//     //       if (response.status == 200) retn(true)
//     //       else retn(false)
//     //     })
//     //     .catch(function (error) {
//     //       // console.log(error);
//     //       retn(false)
//     //     })
//     // } catch (e) {
//     //   console.log(e)
//     //   retn(false)
//     // }
//     retn(true)
//   },
// }))

export const useInfo = create((set) => ({
  leftInfo: false,
  rightInfo: false,
  detailsPage: false,
  setDetailsPage: (bool) => set((state) => ({ detailsPage: bool })),
  setLeftInfo: (bool) => set((state) => ({ leftInfo: bool })),
  setRightInfo: (bool) => set((state) => ({ rightInfo: bool })),
}))

export const useSFDC = create((set, get) => ({
  showForm: false,
  baseURL: 'https://dsbc-staging.autovrse.app/api/',
  //https://admin.digitalshowroom.birlacarbon.com
  //https://autovrse-strapi.biotrade.in/
  apiToken:
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjgyNDI3NzMzLCJleHAiOjE2ODUwMTk3MzN9.LlGZ4NT9VWUKwmsTIdUeruP3dG4pnJ41nRbx534KolM',
  showFormLearnMore: false,
  // setShowForm: (bol) => set((state) => (state.showForm = bol)),
  setShowForm: (bool) => set((state) => ({ showForm: bool })),
  setShowFormLearnMore: (bool) => set((state) => ({ showFormLearnMore: bool })),
  submitForm: async (form, sector = 'Not Selected', retn) => {
    // Analytics Pending
    let cntry = 'UNDEF'
    try {
      await axios
        .get('https://ipapi.co/json/')
        .then((response) => {
          let data = response.data
          cntry = data.country_code
        })
        .catch((error) => {
          cntry = 'UNDEF'
        })
    } catch (e) {
      cntry = 'UNDEF'
    }
    let extraa =
      useAnalytics.getState().output != null
        ? useAnalytics.getState().output
        : [{ section: 'NA', sector: 'NA', 'time-spent': 'NA:NA' }]
    var data = JSON.stringify({
      form: {
        'market-sector': sector,
        'first-name': form.firstName,
        'last-name': form.lastName,
        phone: form.Phone,
        email: form.Email,
        company: form.Company,
        title: form.Title,
        city: form.City,
        state: form.State,
        street: form.Street,
        country: cntry,
        comments: form.Comment,
      },
      extra: extraa,
    })
    var config = {
      method: 'post',
      url: get().baseURL + 'miscellaneous-items/autovrsetoSFDC',
      headers: {
        Authorization: useMaster.getState().apiToken,
        'Content-Type': 'application/json',
      },
      data: data,
    }
    try {
      axios(config)
        .then(function (response) {
          if (response.status === 200) retn(true)
          else retn(false)
        })
        .catch(function (error) {
          // console.log(error);
          retn(false)
        })
    } catch (e) {
      console.log(e)
      retn(false)
    }
  },
}))

export const useLoader = create((set) => ({
  everythingLoaded: false,
  loadedModel: null,
  setLoadedModel: (model) => set((state) => ({ loadedModel: model })),
  setEverythingLoaded: (bool) => set((state) => ({ everythingLoaded: bool })),
}))

export const useCamVal = create((set) => ({
  controlRef: null,
  cameraRef: null,
  setControlRef: (ref) => {
    set((state) => ({ controlRef: ref }))
  },
  setCameraRef: (ref) => {
    set((state) => ({ cameraRef: ref }))
  },
}))

// fishies: await response.json()
// .then(response => response.json).then(data => set({result: data.form})

// import cachedData from './developer/cacheWithLinks.json'
