import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { fadeOut } from 'react-animations'
import { countryData, TotalLoaders } from '../constants'
import { useLoader, useHud } from '../stores'
import RotateMobileIcon from '../components/icons/RotateMobileIcon'
import StoreUpdate from '../context/StoreUpdate'
import {
  sendGAEventWithValue,
  sendGAEventWithValueLabel,
} from './GoogleAnalytics'
import { isChrome, isMobile, isMobileOnly } from 'react-device-detect'
import car2 from '../components/images/car2.webp'
import car3 from '../components/images/car3.webp'
import car4 from '../components/images/car4.webp'
import { fadeIn } from 'react-animations'
// import { useContext } from 'react'

var start

var downloadSpeedCalculated = false
var downloadSpeed = 0

class ProgressRing extends React.Component {
  constructor(props) {
    super(props)

    const { radius, stroke } = this.props

    this.normalizedRadius = radius - stroke * 2
    this.circumference = this.normalizedRadius * 2 * Math.PI
  }

  render() {
    const { radius, stroke, progress, portraitStatus } = this.props

    const strokeDashoffset =
      this.circumference - (progress / 100) * this.circumference

    return (
      <div
        className={css(
          portraitStatus
            ? styles.progressRing2
            : isMobileOnly
            ? styles.progressRing1
            : styles.progressRing
        )}
        // style={{ height: radius * 2, width: radius * 2 }}
      >
        <svg
          className={css(styles.svgStyle)}
          height={radius * 2}
          width={radius * 2}
        >
          <circle
            className={css(styles.circle1)}
            stroke="red"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={this.circumference + ' ' + this.circumference}
            style={{ strokeDashoffset }}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        {progress >= 0 && (
          <div
            className={css(portraitStatus ? styles.ring1 : styles.ring)}
            style={{
              height: radius * 2,
              width: radius * 2,
              lineHeight: radius * 2 + 'px',
              fontSize: radius / 2.5,
            }}
          >
            {progress}%
          </div>
        )}
      </div>
    )
  }
}

export default class LoadingManager extends React.Component {
  constructor(props) {
    super(props)
    this.intervalVar = null
    this.state = {
      TotalLoaders: TotalLoaders[0].desktop,
      DoneLoaders: [],
      LoadersDoneNumber: 0,
      DonePercentage: 0,
      DisplayPercentage: 0,
      isDone: false,
      Effect: null,
      isEverythingLoaded: false,
      dataState: [],
      count: 0,
      image: car3,
      progress: 0,
      radius: null,
      portrait: false,
      animationStatus: false,
    }
    this.lastTime = Date.now()
  }

  ReportLoaderDone(loader) {
    let name = loader
    if (this.state.TotalLoaders.includes(name)) {
      this.state.DoneLoaders.push(name)
      var done = this.state.LoadersDoneNumber
      done++
      var dp = (done / this.state.TotalLoaders.length) * 100
      this.setState({
        LoadersDoneNumber: done,
        DonePercentage: dp,
      })
    } else {
    }
  }

  CalculateDownloadTime = () => {
    const timeTaken = Date.now() - start
    const timeSeconds = Math.round(timeTaken / 1000)
    return timeSeconds
  }

  CalculateDownloadRange = (time) => {
    if (time <= 5) {
      return '<5'
    } else if (time >= 6 && time < 8) {
      return '6-8'
    } else if (time >= 8 && time < 10) {
      return '8-10'
    } else if (time >= 10 && time < 15) {
      return '10-15'
    } else if (time >= 15 && time < 20) {
      return '15-20'
    } else if (time >= 20 && time < 30) {
      return '20-30'
    } else if (time >= 30 && time < 50) {
      return '30-50'
    } else {
      return time
    }
  }

  CalculateDownloadSpeed = (time) => {
    let useTime = time <= 0 ? 3 : time
    const downLoadSpeed = Math.round((25.44 * 8) / useTime)
    return downLoadSpeed
  }

  _LoaderLoop = () => {
    if (this.intervalVar != null) clearInterval(this.intervalVar)
    this.intervalVar = window.setInterval(() => {
      var pp = this.state.DisplayPercentage
      if (pp === 100) {
        let calcTime = this.CalculateDownloadTime()
        sendGAEventWithValue('User', 'Time', calcTime)
        sendGAEventWithValue('User', 'Speed', downloadSpeed)
        sendGAEventWithValueLabel(
          'User',
          'SpeedTime',
          downloadSpeed + 'mbps',
          calcTime
        )
        sendGAEventWithValueLabel(
          'User',
          'TierTime',
          'Tier ' + this.props.gpuInfo,
          calcTime
        )
        if (!this.state.portrait) {
          clearInterval(this.intervalVar)
          setTimeout(() => this.setState({ Effect: styles.FadeOut }), 500)
          setTimeout(() => {
            this.setState({ isDone: true })
            this.setState({ isEverythingLoaded: true })
            this.props.OnLoaderDone()
          }, 500)
        }
      }
      if (pp < this.state.DonePercentage && pp < 100) {
        this.setState({
          DisplayPercentage: pp + 1,
          progress: this.state.progress + 1,
        })
      }
      if (this.props.downloadedStuffDone && !downloadSpeedCalculated) {
        downloadSpeedCalculated = true
        downloadSpeed = this.CalculateDownloadSpeed(
          this.CalculateDownloadTime()
        )
      }
    }, 50)
  }

  componentDidMount() {
    console.log('Component Mounted')
    start = Date.now()
    this._LoaderLoop()
    this.unsub = useLoader.subscribe(
      (paw) => {
        console.log('Reporting Done')
        this.ReportLoaderDone(paw.loadedModel)
      }
      // (state) => state.loadedModel
    )
    if (isMobile) {
      this.setState({
        radius: 30,
      })
    } else {
      this.setState({
        radius: 45,
      })
    }

    if (window.innerWidth < window.innerHeight) {
      this.setState({
        portrait: true,
        animationStatus: false,
      })
      // useHud.setState({ hotspots: true })
    } else {
      this.setState({
        portrait: false,
      })
      // useHud.setState({ hotspots: false })
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth < window.innerHeight) {
        this.setState({
          portrait: true,
          animationStatus: false,
        })
        // useHud.setState({ hotspots: true })
      } else {
        this.setState({
          portrait: false,
        })
        // useHud.setState({ hotspots: false })
      }
    })
  }

  componentDidUpdate() {
    if (
      this.state.DisplayPercentage >= 33 &&
      this.state.DisplayPercentage <= 66
    ) {
      if (this.state.image !== car2) {
        this.setState({
          image: car2,
        })
      }
    } else if (this.state.DisplayPercentage >= 66) {
      if (this.state.image !== car4) {
        this.setState({
          image: car4,
        })
      }
    }
  }

  onStoreUpdate = (store) => {
    this.setState({
      dataState: store.misc,
    })
  }

  animationState = () => {
    this.setState({
      animationStatus: true,
    })
  }

  //this.state.isDone = Boolean Variable for all the model Loaded
  //this.state.DisplayPercentage = Percentage Value for the number of models loaded

  render() {
    return (
      <>
        <StoreUpdate onChange={this.onStoreUpdate} />

        <div
          className={css(
            this.state.isEverythingLoaded && !this.state.portrait
              ? styles.containerLoaded
              : styles.container,
            this.state.animationStatus
              ? styles.displayNone
              : styles.displayBlock
          )}
          onAnimationEnd={
            this.state.isEverythingLoaded && !this.state.portrait
              ? this.animationState
              : null
          }
        >
          {this.state.radius != null && (
            <ProgressRing
              portraitStatus={this.state.portrait}
              radius={this.state.radius}
              stroke={5}
              progress={this.state.progress}
            />
          )}
          <div
            className={css(
              this.state.DisplayPercentage <= 33
                ? styles.backgroundImg
                : styles.FadeOutImage
            )}
            style={{ backgroundImage: `url(${car3})` }}
          >
            <div
              className={css(
                this.state.DisplayPercentage <= 33
                  ? isMobileOnly
                    ? styles.textBox1
                    : styles.textBox
                  : styles.displayNone
              )}
            >
              <div className={css(styles.header)}>
                CARBON BLACK VIRTUAL SHOWROOM
              </div>
              <div className={css(styles.subheader)}>
                An industry first, Birla Carbon - the leader in technology and
                Innovation- presents excellence in Carbon Black with this
                virtual showroom.
              </div>
              {!isChrome && (
                <div
                  className={css(
                    isMobileOnly
                      ? styles.recommandation1
                      : styles.recommandation
                  )}
                >
                  We recommend using Google Chrome for this experience.
                </div>
              )}
            </div>
          </div>
          <div
            className={css(
              this.state.DisplayPercentage >= 33 &&
                this.state.DisplayPercentage <= 66
                ? styles.backgroundImg
                : styles.FadeOutImage
            )}
            style={{ backgroundImage: `url(${car2})` }}
          >
            <div
              className={css(
                this.state.DisplayPercentage >= 33 &&
                  this.state.DisplayPercentage <= 66
                  ? isMobileOnly
                    ? styles.textBox1
                    : styles.textBox
                  : styles.displayNone
              )}
            >
              <div className={css(styles.header)}>
                CARBON BLACK VIRTUAL SHOWROOM
              </div>
              <div className={css(styles.subheader)}>
                Zoom into the depths of an Automobile to unveil the
                <br />
                true value of Carbon Black.
              </div>
              {!isChrome && (
                <div
                  className={css(
                    isMobileOnly
                      ? styles.recommandation1
                      : styles.recommandation
                  )}
                >
                  We recommend using Google Chrome for this experience.
                </div>
              )}
            </div>
          </div>
          <div
            className={css(
              this.state.DisplayPercentage >= 66
                ? styles.backgroundImg
                : styles.FadeOutImage
            )}
            style={{ backgroundImage: `url(${car4})` }}
          >
            <div
              className={css(
                this.state.DisplayPercentage >= 66
                  ? isMobileOnly
                    ? styles.textBox1
                    : styles.textBox
                  : styles.displayNone
              )}
            >
              <div className={css(styles.header)}>
                CARBON BLACK VIRTUAL SHOWROOM
              </div>
              <div className={css(styles.subheader)}>
                Uncover all the values and applications of Carbon Black through
                this
                <br />
                virtual journey.
              </div>
              {!isChrome && (
                <div
                  className={css(
                    isMobileOnly
                      ? styles.recommandation1
                      : styles.recommandation
                  )}
                >
                  We recommend using Google Chrome for this experience.
                </div>
              )}
            </div>
          </div>
          <div className={css(styles.logoContainer)}>
            <img
              className={css(styles.logoImage)}
              style={{
                width: `${this.state.portrait ? '9.5vh' : '10.46vw'}`,
                height: `${this.state.portrait ? '10vh' : '10.87vw'}`,
              }}
              src="/images/logoImage.jpg"
              alt="Logo"
            />
          </div>
          {this.state.portrait && (
            <>
              <div className={css(styles.rotateInfoContainer)}>
                <div className={css(styles.rotateInfoIcon)}>
                  <RotateMobileIcon />
                </div>
                <div className={css(styles.rotateInfoTitle)}>
                  {useHud.getState().country_name != null &&
                    countryData[useHud.getState().country_name].portraitError}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    )
  }
}

const typing = {
  from: {
    width: 0,
  },

  to: {
    width: '100%',
  },
}

// const blinkcaret = {
//   from: {
//     borderColor: 'transparent',
//   },
//   to: {
//     borderColor: 'transparent',
//   },
//   '50%': {
//     borderColor: 'orange',
//   },
// }

const styles = StyleSheet.create({
  webviewContainer: {
    position: 'absolute',
    inset: 0,
    backgroundColor: '#ffffff',
    width: '100%',
    height: '100%',
    zIndex: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2.5vh',
    fontFamily: 'Roboto-Regular',
    color: '#4B4B4B',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
    paddingLeft: '2.5vh',
    paddingRight: '2.5vh',
  },
  ring: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    color: '#ffffff',
  },
  ring1: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    color: '#000000',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  progressRing: {
    position: 'absolute',
    zIndex: 5,
    right: '4.5vw',
    bottom: '15vh',
  },
  progressRing1: {
    position: 'absolute',
    zIndex: 5,
    right: '4.5vw',
    bottom: '10vh',
  },
  progressRing2: {
    position: 'absolute',
    zIndex: 5,
    bottom: '10vh',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  svgStyle: {
    zIndex: 6,
  },
  circle1: {
    transition: 'stroke-dashoffset 0.35s',
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%',
  },
  backgroundImg: {
    zIndex: 0,
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'black',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    animationName: [fadeIn],
    animationDuration: '2s',
  },
  textBox: {
    position: 'relative',
    width: '83vw',
    height: '30vh',
    marginTop: '70vh',
    marginLeft: '2vw',
    textAlign: 'left',
  },
  textBox1: {
    position: 'relative',
    width: '83vw',
    height: '30vh',
    marginTop: '53vh',
    marginLeft: '2vw',
    textAlign: 'left',
  },
  header: {
    fontStyle: 'italic',
    fontSize: '3.5vw',
    lineHeight: '3.8vw',
    color: '#ffffff',
    fontFamily: 'Raleway',
    fontWeight: 'bold',
  },
  subheader: {
    marginTop: '1.1vh',
    marginBottom: '1.1vh',
    fontSize: '2vw',
    lineHeight: '2.3vw',
    color: '#ffffff',
    fontFamily: 'Raleway',
    fontWeight: 'normal',
  },
  recommandation: {
    position: 'absolute',
    bottom: '7vh',
    fontSize: '1.28vw',
    lineHeight: '1.5vw',
    color: '#ffffff',
    fontFamily: 'Raleway',
    fontWeight: 'normal',
  },
  recommandation1: {
    position: 'absolute',
    bottom: '4vh',
    fontSize: '1.28vw',
    lineHeight: '1.5vw',
    color: '#ffffff',
    fontFamily: 'Raleway',
    fontWeight: 'normal',
  },
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
  loadingText: {
    marginTop: 25,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'fit-content',
    blockSize: 'fit-content',
    fontSize: 20,
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#363535',
    height: 24,
    '@media(max-width: 575.98px) and (orientation:portrait)': {
      width: 246,
      fontSize: 12,
      height: 20,
    },
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 14,
      height: 20,
    },
    '@media(min-width: 576px) and (max-width: 768.98px)': {
      fontSize: 14,
      height: 20,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        // width: 325,
        // height: 39,
        // marginTop: 4
        height: 20,
      },
    '@media(min-width: 768px) and (max-width: 991.98px)': {
      fontSize: 14,
      height: 28,
    },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        // width: 325,
        // height: 39,
        // margin: 4
        height: 28,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      fontSize: 20,
      height: 40,
    },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        fontSize: 20,
        height: 40,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 16,
      marginTop: 25,
      height: 40,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 20,
        height: 40,
      },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      fontSize: 20,
      height: 30,
    },
    '@media(min-width: 1600px) and (max-width: 2255.98px)': {
      fontSize: 20,
      height: 60,
    },
    '@media(min-width: 2256px) and (max-width: 2559.98px)': {
      fontSize: 30,
      height: 60,
    },
    '@media(min-width: 2560px)': {
      fontSize: 30,
      height: 60,
    },
  },
  text: {
    overflow:
      'hidden' /* Ensures the content is not revealed until the animation */,
    borderRight: '2px solid orange' /* The typwriter cursor */,
    whiteSpace: 'nowrap' /* Keeps the content on a single line */,
    animationName: [typing],
    animationDuration: '2s',
    animationTimingFunction: 'steps(200, end)',
    float: 'left',
    // animation : `${blinkcaret} .5s step-end infinite`
  },
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 3,
    backgroundColor: '#000000',
    textAlign: 'center',
  },
  containerLoaded: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 3,
    textAlign: 'center',
    backgroundColor: '#000000',
    animationName: [fadeOut],
    animationDuration: '2s',
    animationFillMode: 'forwards',
  },
  FadeOut: {
    animationName: fadeOut,
    animationDuration: '3s',
  },
  FadeOutImage: {
    backgroundColor: 'black',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    animationName: fadeOut,
    animationDuration: '1s',
  },
  containerNotLoaded: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    // width: '100%',
    // height: '100%',
    flexDirection: 'column',
    // alignContent: 'space-between',
    // justifyContent: 'space-between',
    flexWrap: 'wrap',
    zIndex: 3,
    // display: 'flex'
  },
  title: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 40,
    color: '#6C6C6C',
    lineHeight: '51px',
    fontFamily: 'Oxygen-Regular',
    fontWeight: 'normal',
    fontStyle: 'normal',
    marginTop: 138,
    // backgroundColor: 'black',
    '@media(max-width: 575.98px)': {
      fontSize: 20,
      marginTop: 36,
      // marginRight: 10
    },
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 20,
      marginTop: 5,
    },
    '@media(min-width: 576px) and (max-width: 768.98px)': {
      fontSize: 20,
      marginTop: 36,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 18,
        lineHeight: '32px',
        marginTop: 10,
      },
    '@media(min-width: 768px) and (max-width: 991.98px)': {
      fontSize: 36,
      marginTop: 86,
    },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 20,
        lineHeight: '32px',
        marginTop: 10,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      fontSize: 40,
      // marginTop: 120,
    },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        fontSize: 30,
        marginTop: 50,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 45,
        marginTop: 150,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 35,
      marginTop: 50,
    },
    //Laptops & larger screens
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      marginTop: 100,
    },
    '@media(min-width: 1600px) and (max-width: 2255.98px)': {
      marginTop: 100,
      lineHeight: '51px',
    },
    '@media(min-width: 2256px) and (max-width: 2559.98px)': {
      marginTop: 180,
      fontSize: 48,
      lineHeight: '64px',
      // backgroundColor: 'red'
    },
    '@media(min-width: 2560px)': {
      fontSize: 56,
      lineHeight: '64px',
      marginTop: 200,
    },
  },
  logoContainer: {
    position: 'relative',
    zIndex: 5,
    marginLeft: '3vw',
    width: '12vw',
    height: '10.87vw',
    backgroundColor: '#ffffff',
  },
  logoImage: {
    zIndex: 5,
    // width: '10.46vw',
    // height: '10.87vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    display: 'block',
    // '@media(max-width: 575.98px) and (orientation:portrait)': {
    //   width: 141,
    //   height: 163,
    // },
    // '@media(max-width: 575.98px) and (orientation:landscape)': {
    //   // marginTop: 18,
    //   width: 100,
    //   height: 113,
    // },
    // // '@media(min-width: 576px) and (max-width: 768.98px)': {
    // //     width: 141,
    // //     height: 163
    // // },
    // '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
    //   {
    //     width: 80,
    //     height: 93,
    //   },
    // '@media(min-width: 768px) and (max-width: 991.98px)': {
    //   width: 161,
    //   height: 183,
    // },
    // '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
    //   {
    //     width: 100,
    //     height: 113,
    //   },
    // '@media(min-width: 992px) and (max-width: 1199.98px)': {
    //   width: 274,
    //   height: 312,
    // },
    // '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
    //   {
    //     width: 200,
    //     height: 'auto',
    //   },
    // '@media(min-width: 1200px) and (max-width: 1399.98px)': {
    //   width: 174,
    //   height: 212,
    // },
    // '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
    //   {
    //     width: 274,
    //     height: 312,
    //   },
    // '@media(min-width: 1400px) and (max-width: 1599.98px)': {
    //   width: 274,
    //   height: 312,
    // },
    // '@media(min-width: 1600px) and (max-width: 2255.98px)': {
    //   width: 278,
    //   height: 312,
    // },
    // '@media(min-width: 2256px) and (max-width: 2559.98px)': {
    //   width: 378,
    //   height: 412,
    // },
    // '@media(min-width: 2560px)': {
    //   width: 374,
    //   height: 412,
    // },
  },
  rotateInfoContainer: {
    backgroundColor: '#ffffff',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 4,
    top: 0,
    textAlign: 'center',
  },
  rotateInfoIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30vh',
    width: '20vw',
  },
  rotateInfoTitle: {
    fontFamily: 'Roboto-Regular',
    color: '#4B4B4B',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '14px',
    marginTop: '2vh',
    width: '80vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '4vw',
  },
  progress: {
    position: 'absolute',
    height: 30,
    width: '100%',
    backgroundColor: '#E8E8E8',
    bottom: 0,
    // marginTop: 43,
    // visibility: 'hidden',
    '@media(max-width: 575.98px)': {
      height: 20,
      // marginRight: 10
    },
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      height: 20,
      // marginTop: 17,
      // marginRight: 10
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        height: 20,
        // marginTop: 15,
      },
    '@media(min-width: 768px) and (max-width: 991.98px)': {
      height: 20,
      // marginTop: 82
    },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        height: 20,
        // marginTop: 15,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      height: 30,
      // marginTop: 85
    },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        height: 30,
        // marginTop: 87,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        height: 30,
        // marginTop: 57,
      },
    '@media(min-width: 2256px) and (max-width: 2559.98px)': {
      height: 44,
      // marginTop: 107,
    },
    '@media(min-width: 2560px)': {
      height: 44,
      // marginTop: 87,
    },
  },
  progressbar: {
    height: '100%',
    backgroundColor: '#DF3224',
  },
  buttonContainer: {
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    bottom: 50,
    '@media(min-width: 576px) and (max-width: 768.98px)': {
      bottom: 55,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        bottom: 55,
      },
    '@media(min-width: 768px) and (max-width: 991.98px)': {
      bottom: 40,
    },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        bottom: 40,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      bottom: 87,
    },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        bottom: 100,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      bottom: 50,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        bottom: 50,
      },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      bottom: 70,
    },
    '@media(min-width: 1600px) and (max-width: 2255.98px)': {
      bottom: 70,
    },
    '@media(min-width: 2256px) and (max-width: 2559.98px)': {
      bottom: 70,
    },
    '@media(min-width: 2560px)': {
      bottom: 70,
    },
  },
  button: {
    outline: 0,
    border: 0,
    color: '#ffffff',
    backgroundColor: '#DF3224',
    borderRadius: 12,
    height: 44,
    width: 274,
    fontSize: 18,
    fontFamily: 'Oxygen-Regular',
    pointerEvents: 'all',
    cursor: 'pointers',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media(max-width: 575.98px) and (orientation:portrait)': {
      width: 0,
      height: 0,
      fontSize: 0,
      outline: 0,
      border: 0,
      background: 'none',
      // marginRight: 10
    },
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 180,
      height: 30,
      fontSize: 12,
      borderRadius: 10,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:portrait)':
      {
        width: 0,
        height: 0,
        fontSize: 0,
        outline: 0,
        border: 0,
        background: 'none',
      },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 180,
        height: 30,
        fontSize: 12,
        borderRadius: 10,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:portrait)':
      {
        width: 0,
        height: 0,
        fontSize: 0,
        outline: 0,
        border: 0,
        background: 'none',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 180,
        height: 30,
        fontSize: 12,
        borderRadius: 10,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:portrait)':
      {
        width: 0,
        height: 0,
        fontSize: 0,
        outline: 0,
        border: 0,
        background: 'none',
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        height: 44,
        width: 274,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      height: 44,
      width: 274,
      fontSize: 16,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        height: 44,
        width: 274,
      },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      height: 44,
      width: 274,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      height: 44,
      width: 274,
    },
    '@media(min-width: 2256px) and (max-width: 2559.98px)': {
      height: 64,
      width: 374,
      fontSize: 24,
      borderRadius: 14,
    },
    '@media(min-width: 2560px)': {
      height: 64,
      width: 374,
      fontSize: 24,
      borderRadius: 14,
    },
  },
  buttonHide: {
    display: 'none',
  },
})
