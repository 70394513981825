import React from  'react';

export default function LanguagePrompt(){
    return(
        <>
        <svg width="114" height="124" viewBox="0 0 114 124" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="12" width="114" height="112" rx="12" fill="white"/>
            <path d="M75 0L83.6603 12H66.3397L75 0Z" fill="white"/>
        </svg>
        </>
    )

}