import React from 'react'
import { isMobile } from 'react-device-detect'

export default function CloseIcon() {
  return (
    <>
      <svg
        width={`${isMobile ? '1.6vw' : '1.4vw'}`} //16
        height={`${isMobile ? '1.5vw' : '1.3vw'}`} //17
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="0.646447"
          y1="15.6464"
          x2="15.6464"
          y2="0.646446"
          stroke="white"
        />
        <line
          x1="1.35355"
          y1="0.646447"
          x2="16.3536"
          y2="15.6464"
          stroke="white"
        />
      </svg>
    </>
  )
}
