import React from 'react'

export default function Circle(){
    return(
        <>
        <svg viewBox="0 0 164 164" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M82 164C127.287 164 164 127.287 164 82C164 36.7127 127.287 0 82 0C36.7127 0 0 36.7127 0 82C0 127.287 36.7127 164 82 164ZM82 156C122.869 156 156 122.869 156 82C156 41.1309 122.869 8 82 8C41.1309 8 8 41.1309 8 82C8 122.869 41.1309 156 82 156Z" fill="white"/>
        </svg>
        </>
    )
}