import { useDetectGPU } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import { useEffect } from 'react'
import {
  ACESFilmicToneMapping,
  LinearEncoding,
  NoToneMapping,
  sRGBEncoding,
} from 'three'

export default function GLSetup(props) {
  const { gl } = useThree()
  const GPU = useDetectGPU()

  useEffect(() => {
    gl.sortObjects = false
    gl.localClippingEnabled = true
    gl.physicallyCorrectLights = true
    gl.toneMapping = ACESFilmicToneMapping
    gl.toneMappingExposure = 8
    gl.encoding = LinearEncoding
    console.log(GPU.tier)
    if (GPU != null) props.reportGPU(GPU.tier)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gl, GPU])

  return null
}
