import React from 'react'
import { isMobileOnly } from 'react-device-detect'

export default function BackArrow() {
  return (
    <svg
      width={isMobileOnly ? '2.5vw' : '3.3vh'}
      height={isMobileOnly ? '3.5vw' : '4.3vh'}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g style="mix-blend-mode:screen"> */}
      <path
        d="M-3.93402e-07 8L14.25 0.205772L14.25 15.7942L-3.93402e-07 8Z"
        fill="#C4C4C4"
      />
      {/* </g> */}
    </svg>
  )
}
