import './App.css'
import React from 'react'
import ReactGA from 'react-ga4'
import { Suspense, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import Env from './context/models/EnvV3'
import Skybox from './core/Skybox'
import HotspotManager from './components/HotspotManager'
import CameraHandler from './context/CameraHandler'
// import Controllables from './developer/Controllables'
import LightSetup from './context/environment/LightSetup'
import GLSetup from './context/GLSetup'
import LoadingScreen from './core/LoadingScreen'
import { useHud, useLoader, useSFDC } from './stores'
import ContentGetter from './context/ContentGetter'
import UIContentBridge from './core/UIContentBridge'
import CameraValues from './core/CameraValues'
import LocationTrack from './core/LocationTrack'
import WouterAnalytics from './context/WouterAnalytics'
// import { isMobile } from 'react-device-detect'
// import MobileMeshes from './context/models/conditional/MobileMeshes'
import ConditionalHolder from './core/ConditionalHolder'
import RenderStuff from './RenderStuff'
import LoadStuff from './LoadStuff'
import CustomDPR from './CustomDPR'
import { isIOS, isMobile } from 'react-device-detect'
import WebViewUI from './context/ui/WebViewUI'
import DynamicMetadata from './core/DynamicMetadata'
import { useEffect } from 'react'
import CountryDetection from './core/CountryDetection'
import { Route, useLocation } from 'wouter'
import SFDCFormSuccessPopup from './context/ui/SFDCFormSuccessPopup'

//zustand was 3.7.2

ReactGA.initialize('UA-163873590-11')
ReactGA.initialize('G-EJQC164C3R', { debug: true })

function App() {
  const { everythingLoaded, setEverythingLoaded } = useLoader()
  const { setHideHotspots } = useHud()

  const [canLoad, setCanLoad] = useState(false)
  const [contentDone, setContentDone] = useState(false)
  const [gpu, setGpu] = useState(null)
  const [environmentDone, setEnvironmentDone] = useState(true)
  const [webview, setWebview] = useState(false)

  const country = useSFDC()
  const [, setLocation] = useLocation()

  function EverythingLoaded() {
    setEverythingLoaded(true)
    // setHideHotspots(false)
  }
  const [portrait, setPortrait] = useState(false)
  function onResize() {
    if (window.innerWidth < window.innerHeight) {
      setPortrait(true)
    } else {
      setPortrait(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()

    return function cleanup() {
      window.removeEventListener('resize', onResize)
    }
  }, [window])

  useEffect(() => {
    if (everythingLoaded && !portrait) {
      setHideHotspots(false)
    } else {
      setHideHotspots(true)
    }
  }, [everythingLoaded, portrait])

  const queryParams = new URLSearchParams(window.location.search)

  // Check if the 'showPopup' query parameter is present and set to 'true'
  const showPopup = queryParams.get('thankyou') === 'true'

  useEffect(() => {
    if (showPopup) {
      setLocation('/thankyou')
    }
  }, [showPopup])

  return (
    <>
      <DynamicMetadata />
      <CountryDetection />
      <WebViewUI setWebview={setWebview} webview={webview} />
      {!webview && (
        <>
          <Canvas
            legacy
            frameloop={'demand'}
            performance={[0.7, isIOS ? 0.7 : 1]}
          >
            {/* <Perf /> */}

            {!canLoad && (
              <Suspense fallback={null}>
                <LoadStuff
                  canRenderStuff={() => {
                    if (!canLoad) setCanLoad(true)
                  }}
                  tier={gpu}
                />
              </Suspense>
            )}
            <CameraHandler />

            {canLoad && contentDone && (
              <>
                <Suspense fallback={null}>
                  <HotspotManager />
                </Suspense>
                <Suspense fallback={null}>
                  <Env position={[2.6, 0.33, 0.06]} done={setEnvironmentDone} />
                  <LightSetup />
                </Suspense>
                {environmentDone && (
                  <>
                    <Suspense fallback={null}>
                      <Skybox />
                    </Suspense>
                    <ConditionalHolder tier={gpu} />
                  </>
                )}
                {everythingLoaded && (
                  <>
                    {!isMobile && <RenderStuff />}
                    <CustomDPR qualityMode={everythingLoaded} />
                  </>
                )}
              </>
            )}

            <Suspense fallback={null}>
              <GLSetup reportGPU={(v) => setGpu(v)} />
              {/* <PreloadTextures /> */}
            </Suspense>

            {/* <Controllables /> */}
            {/* <Stats /> */}
          </Canvas>
          <ContentGetter
            onContentFetched={() => {
              if (!contentDone) setContentDone(true)
              // EverythingLoaded()
            }}
          />
        </>
      )}

      <LocationTrack />
      <WouterAnalytics />
      {!webview && (
        <>
          <LoadingScreen
            OnLoaderDone={EverythingLoaded}
            downloadedStuffDone={canLoad}
            gpuInfo={gpu}
            webview={webview}
          />
          <UIContentBridge />

          <CameraValues hidden={false} />
          <Route path="/thankyou">{<SFDCFormSuccessPopup />}</Route>
        </>
      )}

      {/* <PeformanceApi
    everythingLoaded={everythingLoaded}
    startLoading={canLoad}
  /> */}
    </>
  )
}

export default App
