import { useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import { useAnalytics } from '../customAnalytics'
import { useBehaviour } from '../stores'

export default function WouterAnalytics() {
  const [location] = useLocation()

  const { measureTime, stopMeasureTime } = useAnalytics()

  const [currCat, serCurrCat] = useState(-1)
  const [cat, setCat] = useState(-1)
  const [currPrd, setCurrPrd] = useState('')
  const [prd, setPrd] = useState('')
  const [lastLocation, setLastLocation] = useState('')

  useEffect(() => {
    const unsub = useBehaviour.subscribe((category) => {
      // console.log(hots)
      // console.log('setting ' + hots.hotspots)
      setCat(category.display)
    })

    return function cleanup() {
      unsub()
    }
  }, [location])

  useEffect(() => {
    if (cat != currCat) {
      serCurrCat(cat)
    }
  }, [cat])

  useEffect(() => {
    const unsub = useBehaviour.subscribe((product) => {
      setPrd(product.currentProduct)
    })

    return function cleanup() {
      unsub()
    }
  }, [location])

  useEffect(() => {
    if (prd != currPrd) {
      setCurrPrd(prd)
    }
  }, [prd])

  useEffect(() => {
    if (currCat !== -1 && currPrd !== '') {
      measureTime(currPrd, currCat)
      setLastLocation(location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currPrd])

  useEffect(() => {
    if (currCat !== -1 && currPrd !== '') {
      if (lastLocation !== location) {
        stopMeasureTime()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return null
}
