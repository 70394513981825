import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'wouter'
import { useMaster } from '../stores'

function DynamicMetadata() {
  const [currentObject, setCurrentObject] = useState()
  const { getObject } = useMaster()
  const [location] = useLocation()

  useEffect(() => {
    setCurrentObject(getObject(location).meta)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      {currentObject != null && (
        <>
          <Helmet>
            {/* <title>{currentObject.title}</title> */}
            {/* <meta name="description" content={currentObject.desc} /> */}
            <meta name="keywords" content={currentObject.keywords} />
          </Helmet>
        </>
      )}
    </>
  )
}

export default DynamicMetadata
