import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useRef, useState } from 'react'
// import Lottie from 'react-lottie'
import Lottie from 'lottie-react'
import animationData from './toggle.json'
import { a, config, useSpring } from '@react-spring/web'
import { useHud, useMaster, useSFDC } from '../../stores'
import { isMobile, isMobileOnly } from 'react-device-detect'

export default function DayNightToggle({ direction = -1, onClick }) {
  const ref = useRef()

  const [fade, setFade] = useState(false)

  const isPlaying = useRef(false)

  const { setHideHotspots } = useHud()
  const { showForm, setShowForm } = useSFDC()
  const { misc } = useMaster()

  const animatedStyle = useSpring({
    opacity: fade ? 2 : 0,
    config: config.gentle,
  })

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    ref.current.setSpeed(2)
    ref.current.setDirection(direction)
  }, [ref.current])

  const onAnimComplete = () => {
    // console.log('mobile night' + fade)
    if (isPlaying.current) {
      isPlaying.current = false
      setTimeout(() => {
        setFade(false)
        setTimeout(() => {
          if (!isMobile) setHideHotspots(false)
        }, 500)
      }, 2000)
    }
  }

  return (
    <>
      <div className={css(styles.lottie)}>
        <Lottie
          lottieRef={ref}
          // eventlisteners={[
          //   { eventName: 'complete', callback: () => onComplete() },
          // ]}
          onComplete={onAnimComplete}
          // speed={2}
          animationData={animationData}
          loop={false}
          autoplay={false}
          style={{
            height: `${isMobileOnly && '35vw'}`,
            width: `${isMobileOnly && '35vw'}`,
            // position: 'absolute',
            marginTop: `${isMobileOnly && '-36vh'}`,
            marginLeft: `${isMobileOnly && '-15.4vh'}`,
            padding: 0,
            // background: 'red',
          }}
        />
        {/* <Lottie
          ref={ref}
          direction={direction}
          speed={2}
          height={100}
          width={100}
          options={defaultOptions}
          eventlisteners={[
            { eventName: 'complete', callback: () => onComplete() },
          ]}
          style={{
            height: `${isMobileOnly && '35vw'}`,
            width: `${isMobileOnly && '35vw'}`,
            // position: 'absolute',
            marginTop: `${isMobileOnly && '-36vh'}`,
            marginLeft: `${isMobileOnly && '-15.4vh'}`,
            padding: 0,
            // background: 'red',
          }}
        /> */}
      </div>
      <div
        onClick={() => {
          // console.log('collider')
          if (showForm) {
            setShowForm(false)
          }
          setFade(true)
          isPlaying.current = true
          setHideHotspots(true)
          setTimeout(() => {
            ref.current.play()

            onClick()
          }, 500)
        }}
        className={css(styles.collider)}
      />
      {fade && (
        <a.div className={css(styles.fadeOut)} style={animatedStyle}>
          <div className={css(styles.text)}>
            {/* Please wait while we change the lighting */}
            {misc['daynighttoggle'] != null && misc['daynighttoggle'].title}
          </div>
        </a.div>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  lottie: {
    height: 100,
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    // transform: 'scale(3.5)',
    position: 'absolute',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      // height: 100 / 2.5,
      // width: 100 / 2.5,
      // transform: 'scale(6)',
      // marginLeft: 60,
      // marginTop: 0,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        // height: 100 / 3,
        // width: 100 / 3,
        // transform: 'scale(8)',
        // marginLeft: 60,
        // marginTop: 0,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        // height: 100 / 2,
        // width: 100 / 2,
        // transform: 'scale(6)',
        // marginLeft: 60,
        // marginTop: 0,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        transform: 'scale(3)',
        marginTop: 12,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        transform: 'scale(3.5)',
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      transform: 'scale(3)',
      marginTop: 12,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      transform: 'scale(3.5)',
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      transform: 'scale(3)',
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      transform: 'scale(4)',
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(4.5)',
      height: 150,
      width: 150,
      marginTop: 0,
    },
  },
  collider: {
    // height:100,
    width: 100,

    // top:0,
    textAlign: 'center',
    pointerEvents: 'auto',
    // backgroundColor: 'black',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      height: 40,
      width: '100%',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        // marginLeft: 'auto',
        height: 33,
        width: '100%',
        // top: 90
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        // marginTop: -50,
        height: 50,
        width: '100%',
        // top: 36
      },
    '@media(min-width: 2560px)': {
      // transform: 'scale(6)',
      height: 150,
      width: 150,
    },
  },
  fadeOut: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100vh',
    backgroundColor: '#000000',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: '100%',
      position: 'fixed',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: '100%',
        position: 'fixed',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: '100%',
        position: 'fixed',
      },
    textAlign: 'center',
  },
  text: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    top: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'space-around',
    color: 'white',
    fontSize: 40,
    // paddingTop: 260,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 30,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 30,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 30,
      },
  },
})
