import { useGLTF } from '@react-three/drei'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useEnvironment, useLoader } from './stores'

const url = '/gltfP2V2/emissiveMaps'
const url1 = '/gltfP2LR/emissiveMaps'

/* eslint-disable no-unused-vars */

const eTextures = [
  url + '/AntiVibrationBushing.webp',
  url + '/AntiVibrationMounts.webp',
  url + '/CoolantHose.webp',
  url + '/DriveBelt.webp',
  url + '/PneumaticHose.webp',
  url + '/Weatherstrip.webp',
  url + '/Wipers.webp',
  url + '/MRG_All.webp',
  url + '/Rim.webp',
  url + '/Bead.webp',
  url + '/BeadApex.webp',
  url + '/Belt.webp',
  url + '/CapePiles.webp',
  url + '/InnerLiner.webp',
  url + '/RadialPiles.webp',
  url + '/PUSeatfoam.webp',
  url + '/EntertainmentUnit.webp',
]

const eTexturesLowRes = [
  url1 + '/AntiVibrationBushing.webp',
  url1 + '/AntiVibrationMounts.webp',
  url1 + '/CoolantHose.webp',
  url1 + '/DriveBelt.webp',
  url1 + '/PneumaticHose.webp',
  url1 + '/Weatherstrip.webp',
  url1 + '/Wipers.webp',
  url1 + '/MRG_All.webp',
  url1 + '/Rim.webp',
  url1 + '/Bead.webp',
  url1 + '/BeadApex.webp',
  url1 + '/Belt.webp',
  url1 + '/CapePiles.webp',
  url1 + '/InnerLiner.webp',
  url1 + '/RadialPiles.webp',
  url1 + '/PUSeatfoam.webp',
  url1 + '/EntertainmentUnit.webp',
]

const continua_tex = ['/textures/rainforest_trail_4k.webp']

// {isMobile && <ConditionalMeshesLowRes />}
//       {!isMobile && (
//         <>
//           {props.tier >= 2 && props.tier != null && <ConditionalMeshes />}
//           {props.tier <= 1 && props.tier != null && <ConditionalMeshesLowRes />}
//           {props.tier == null && <ConditionalMeshesLowRes />}
//         </>
//       )}

export default function LoadStuff(props) {
  const a = useGLTF(
    isMobile
      ? '/gltfP2LR/Interior.gltf'
      : props.tier != null
      ? props.tier >= 2
        ? '/gltfP2V2/Interior.gltf'
        : '/gltfP2LR/Interior.gltf'
      : '/gltfP2LR/Interior.gltf'
  )
  const b = useGLTF(
    isMobile
      ? '/gltfP2LR/Exterior.gltf'
      : props.tier != null
      ? props.tier >= 2
        ? '/gltfP2V2/Exterior.gltf'
        : '/gltfP2LR/Exterior.gltf'
      : '/gltfP2LR/Exterior.gltf'
  )
  const c = useGLTF(
    isMobile
      ? '/gltfP2LR/CarPaint.gltf'
      : props.tier != null
      ? props.tier >= 2
        ? '/gltfP2V2/CarPaint.gltf'
        : '/gltfP2LR/CarPaint.gltf'
      : '/gltfP2LR/CarPaint.gltf'
  )
  const d = useGLTF(
    isMobile
      ? '/gltfP2LR/Glass.gltf'
      : props.tier != null
      ? props.tier >= 2
        ? '/gltfP2V2/Glass.gltf'
        : '/gltfP2LR/Glass.gltf'
      : '/gltfP2LR/Glass.gltf'
  )
  const { current } = useEnvironment()
  const e = useGLTF('/glb/environment/dayscene_1.gltf')
  const continua_e = useGLTF('/glb/environment/continua_env/Env_Continua.gltf')
  // const continua_e = useGLTF('/glb/environment/continua_env/Env_Continua.gltf')

  // const f = useTexture(
  //   isMobile
  //     ? eTexturesLowRes
  //     : props.tier >= 2 && props.tier != null
  //     ? eTextures
  //     : props.tier <= 1 && props.tier != null
  //     ? eTexturesLowRes
  //     : eTextures
  // )
  const { everythingLoaded, setLoadedModel } = useLoader()

  useEffect(() => {
    if (!everythingLoaded) {
      setTimeout(() => {
        setLoadedModel(`all`)
        props.canRenderStuff()
      }, 500)
    }
    //console.log(f)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [a, b, c, d, e, continua_e])

  return null
}
