import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { css, StyleSheet } from 'aphrodite'
import NextArrow from '../../components/icons/NextArrow'
import BackArrow from '../../components/icons/BackArrow'
import { useLocation } from 'wouter'
import { useState } from 'react'
import { useEffect } from 'react'

function MultiProductCarousel({ productOffered }) {
  const [location] = useLocation()
  const [customMargin, setCustomMargin] = useState(false)

  // useEffect(() => {
  //   if(location.includes('/powder') || location.includes('/primer') ||location.includes('/metal')||location.includes('/coatings/interior') || location.includes('coffee')||location.includes('magazine') || location.includes('uvink') || location.includes('radiatorgrill') || location.includes('airintake') || location.includes('glovebox') || location.includes())
  // })

  function checkUrl(url) {
    // console.log(url)
    if (url != null) {
      // console.log(url)
      if (url.includes('$')) {
        window.open(url.split('$')[0], '_blank')
        // window.focus()
      } else {
        window.open(url, '_blank')
        // window.focus()
      }
    }
  }
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      // partialVisibilityGutter: -10,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1200, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
  }
  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      carouselState: { currentSlide, deviceType },
    } = rest
    // onMove means if dragging or swiping in progress.
    return (
      <div
        className={css(styles.btnContainer)}
        style={{
          top: 0,
          right: '0vh',

          background: 'black',
        }}
      >
        <button
          onClick={() => onClick()}
          type="button"
          className={css(styles.btn)}
          style={{
            marginLeft: '1.2vh',
            background: 'black',
          }}
        >
          <NextArrow />
        </button>
      </div>
    )
  }
  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      carouselState: { currentSlide, deviceType },
    } = rest
    // onMove means if dragging or swiping in progress.
    return (
      <div
        className={css(styles.btnContainer)}
        style={{
          left: '0vh',
        }}
      >
        <button
          onClick={() => onClick()}
          className={css(styles.btn)}
          style={{
            marginRight: '1.2vh',
          }}
        >
          <BackArrow />
        </button>
      </div>
    )
  }
  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={false}
      responsive={responsive}
      // ssr={true} // means to render carousel on server-side.
      infinite={true}
      // autoPlaySpeed={1000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      // removeArrowOnDeviceType={['tablet', 'mobile']}
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
      partialVisible={false}
      // centerMode={true}
      // itemClass={css(styles.carouselItem)}
      // deviceType={this.props.deviceType}
      // dotListClass="custom-dot-list-style"

      itemClass="carousel-item-padding-40-px"
    >
      {productOffered.map((data, index) => {
        // console.log(data)
        return (
          <button key={index} className={css(styles.imgContainer)}>
            <div
              className={css(styles.imgConent)}
              onClick={() => {
                checkUrl(data.url)
              }}
            >
              {data.title}
            </div>
          </button>
        )
      })}
    </Carousel>
  )
}

export default MultiProductCarousel

const styles = StyleSheet.create({
  carouselItem: {
    paddingRight: 40,
  },
  imgContainer: {
    background: 'none',
    outline: 'none',
    border: 'none',
    ':hover': {
      opacity: 0.5,
    },
    ':active': {
      opacity: 1,
    },
    display: 'inline-block',
    // margin: '10px 11px 0px 11px',
    textAlign: 'center',
    // '@media(min-width:556px) and (orientation:landscape)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 768px) and (orientation:landscape)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 992px) and (orientation:landscape)': {
    //   margin: '10px 9px 0px 9px',
    // },
    // '@media(min-width: 1200px)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 1400px)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 1600px)': {
    //   margin: '10px 11px 0px 11px',
    // },
  },
  imgConent: {
    border: '3px solid grey',
    padding: '10px',
    // height: '5vh',
    borderRadius: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginTop: '50%',
    width: 'fit-content',
    fontFamily: 'Roboto-Regular',
    fontSize: 20,
    fontWeight: 'normal',
    color: '#ffffff',
    lineHeight: '23px',
    whiteSpace: 'nowrap',
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 14,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 14,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 16,
    },
    '@media(min-width: 1200px)': {
      fontSize: 16,
    },
    '@media(min-width: 1400px)': {
      fontSize: 20,
    },
    '@media(min-width: 1600px)': {
      fontSize: 20,
    },
  },
  btnContainer: {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    transform: 'translateY(-50%)',
    // top: 'calc(50% - 15px)',
    height: '100%',
    // width: '5vh',
    // borderRadius: '50%',
    background: 'rgb(60, 60, 60)',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // opacity: 0.5,
  },
  container: {
    // width: '100%',
    // height: 110,
    // display: 'inline-block',
    // marginBottom: 12,
    height: 'fit-content',
  },
  btn: {
    // width: 30,
    // height: 30,
    // paddingTop: '0.8vh',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 0,
    border: 0,
    background: 'none',
    // height: 'auto',
    // width: 'auto',
    pointerEvents: 'auto',
    width: '100%',
    height: 'auto',
    // width: isMobileOnly ? '3.5vw' : '4.3vh',
    // height: isMobileOnly ? '3.5vw' : '4.3vh',
  },
})
