import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useSFDC, useHud, useMaster } from '../../stores'
import RightArrow from '../../components/icons/RightArrow'
import { sendGAEvent } from '../../core/GoogleAnalytics'

export default function LearnMoreButton() {
  const { showForm, setShowForm, setShowFormLearnMore } = useSFDC()
  const { setHideHotspots } = useHud()
  const { misc } = useMaster()

  function showContactForm() {
    sendGAEvent('Form', 'Click', 'Learn More')
    setShowForm(!showForm)
    setHideHotspots(!showForm)
    setShowFormLearnMore(true)
  }
  return (
    <>
      {!showForm && misc != null && (
        <div className={css(styles.buttonContainer)}>
          <button
            className={css(styles.button)}
            onClick={() => {
              showContactForm()
            }}
          >
            {misc['learn_more'] != null
              ? misc['learn_more'].title
              : 'Learn More'}
            <RightArrow />
          </button>
        </div>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    position: 'absolute',
    right: 68,
    bottom: 41,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      right: 28,
      bottom: 21,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        right: 28,
        bottom: 21,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        right: 28,
        bottom: 21,
      },
  },
  button: {
    border: 0,
    outline: 0,
    borderRadius: 12,
    backgroundColor: '#ffffff',
    color: '#4B3939',
    textAlign: 'center',
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    pointerEvents: 'auto',
    cursor: 'pointer',
    width: 'auto',
    height: 30,
    paddingLeft: 8,
    paddingRight: 8,
    '@media(min-width: 576px) and (orientation:landscape)': {
      width: 'auto',
      height: 30,
      fontSize: 12,
      borderRadius: 10,
      lineHeight: '15px',
      paddingLeft: 8,
      paddingRight: 8,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      width: 'auto',
      height: 35,
      fontSize: 13,
      borderRadius: 10,
      lineHeight: '15px',
      paddingLeft: 8,
      paddingRight: 8,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      width: 'auto',
      height: 51,
      fontSize: 16,
      borderRadius: 10,
      lineHeight: '20px',
      paddingLeft: 8,
      paddingRight: 8,
    },
    '@media(min-width: 1200px)': {
      width: 'auto',
      height: 51,
      fontSize: 16,
      borderRadius: 10,
      lineHeight: '23.44px',
      paddingLeft: 10,
      paddingRight: 10,
    },
    '@media(min-width: 1400px)': {
      width: 'auto',
      height: 55,
      fontSize: 21,
      borderRadius: 10,
      lineHeight: '23.44px',
      paddingLeft: 10,
      paddingRight: 10,
    },
    '@media(min-width: 1600px)': {
      width: 'auto',
      height: 61,
      fontSize: 21,
      borderRadius: 10,
      lineHeight: '23.44px',
      paddingLeft: 14,
      paddingRight: 14,
    },
    '@media(min-width: 2560px)': {
      width: 'auto',
      height: 71,
      fontSize: 28,
      paddingLeft: 14,
      paddingRight: 14,
      borderRadius: 10,
      lineHeight: '23.44px',
    },
  },
})
