import { useAnimations } from '@react-three/drei'
import React, { useEffect } from 'react'
import { LoopOnce } from 'three'

let Timer = null

export default function BrakePadGLTF(props) {
  const { ref, actions } = useAnimations(props.animations)

  useEffect(() => {
    if (props.object != null) {
      if (props.object.animate != null) {
        if (props.object.animate) {
          Timer = setTimeout(() => {
            actions.KeyAction.clampWhenFinished = true
            actions.KeyAction.loop = LoopOnce
            actions.KeyAction.setEffectiveTimeScale(1)
            actions.KeyAction.paused = false
            actions.KeyAction.play()
          }, 1500)
        } else {
          actions.KeyAction.clampWhenFinished = true
          actions.KeyAction.loop = LoopOnce
          actions.KeyAction.setEffectiveTimeScale(-2)
          actions.KeyAction.paused = false
          clearTimeout(Timer)
        }
      } else {
        actions.KeyAction.clampWhenFinished = true
        actions.KeyAction.loop = LoopOnce
        actions.KeyAction.setEffectiveTimeScale(-2)
        actions.KeyAction.paused = false
        clearTimeout(Timer)
      }
    } else {
      actions.KeyAction.clampWhenFinished = true
      actions.KeyAction.loop = LoopOnce
      actions.KeyAction.setEffectiveTimeScale(-2)
      actions.KeyAction.paused = false
      clearTimeout(Timer)
    }
  }, [actions, props.object])

  return <primitive ref={ref} object={props.scene} />
}
