import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import BackArrow from '../../components/icons/BackArrow'
import NextArrow from '../../components/icons/NextArrow'
import 'react-responsive-carousel/lib/styles/carousel.css'
import { css, StyleSheet } from 'aphrodite'
import { isMobileOnly } from 'react-device-detect'

function ProductcardCarousel({ productOffered }) {
  function checkUrl(url) {
    // console.log(url)
    if (url != null) {
      // console.log(url)
      if (url.includes('$')) {
        window.open(url.split('$')[0], '_blank')
        // window.focus()
      } else {
        window.open(url, '_blank')
        // window.focus()
      }
    }
  }
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      showIndicators={false}
      // emulateTouch
      // autoPlay
      centerMode
      // centerSlidePercentage={!isMobileOnly && 100 / 80}
      useKeyboardArrows
      transitionTime={80}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <div
            className={css(styles.btnContainer)}
            style={{
              left: '0vh',
            }}
          >
            <button
              type="button"
              onClick={onClickHandler}
              className={css(styles.btn)}
              title={label}
              style={{
                marginRight: '3vh',
              }}
            >
              <BackArrow />
            </button>
          </div>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <div
            className={css(styles.btnContainer)}
            style={{
              top: 0,
              right: '0vh',

              background: 'black',
            }}
          >
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className={css(styles.btn)}
              style={{
                marginLeft: '3vh',
                background: 'black',
              }}
            >
              <NextArrow />
            </button>
          </div>
        )
      }
      // renderThumbs={customRenderThumb}
      // width="600px"
    >
      {productOffered.map((data, index) => {
        console.log(data)
        return (
          <button key={index} className={css(styles.imgContainer)}>
            <div
              className={css(styles.imgConent)}
              onClick={() => {
                checkUrl(data.url)
              }}
            >
              {data.title}
            </div>
          </button>
        )
      })}
    </Carousel>
  )
}

export default ProductcardCarousel

const styles = StyleSheet.create({
  imgContainer: {
    background: 'none',
    outline: 'none',
    border: 'none',
    ':hover': {
      opacity: 0.5,
    },
    ':active': {
      opacity: 1,
    },
    display: 'inline-block',
    // margin: '10px 11px 0px 11px',
    textAlign: 'center',
    // '@media(min-width:556px) and (orientation:landscape)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 768px) and (orientation:landscape)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 992px) and (orientation:landscape)': {
    //   margin: '10px 9px 0px 9px',
    // },
    // '@media(min-width: 1200px)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 1400px)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 1600px)': {
    //   margin: '10px 11px 0px 11px',
    // },
  },
  imgConent: {
    border: '3px solid grey',
    padding: '10px',
    // height: '5vh',
    borderRadius: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginTop: '50%',
    width: 'fit-content',
    fontFamily: 'Roboto-Regular',
    fontSize: 20,
    fontWeight: 'normal',
    color: '#ffffff',
    lineHeight: '23px',
    whiteSpace: 'nowrap',
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 14,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 14,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 16,
    },
    '@media(min-width: 1200px)': {
      fontSize: 16,
    },
    '@media(min-width: 1400px)': {
      fontSize: 20,
    },
    '@media(min-width: 1600px)': {
      fontSize: 20,
    },
  },
  btnContainer: {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    transform: 'translateY(-50%)',
    // top: 'calc(50% - 15px)',
    height: '100%',
    // width: '5vh',
    // borderRadius: '50%',
    background: 'rgb(60, 60, 60)',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // opacity: 0.5,
  },
  container: {
    // width: '100%',
    // height: 110,
    // display: 'inline-block',
    // marginBottom: 12,
    height: 'fit-content',
  },
  btn: {
    // width: 30,
    // height: 30,
    // paddingTop: '0.8vh',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 0,
    border: 0,
    background: 'none',
    // height: 'auto',
    // width: 'auto',
    pointerEvents: 'auto',
    width: '100%',
    height: 'auto',
    // width: isMobileOnly ? '3.5vw' : '4.3vh',
    // height: isMobileOnly ? '3.5vw' : '4.3vh',
  },
})
