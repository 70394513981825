import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useCamVal } from '../stores'

/**
props:
position = position of the panel in 3D space 
spatialPanelVisibilityState= Visibility State of the panel 
header= Header Text 
subHeader = Subheader Text 
onCrossClicked = On Panel Closed (if Required)
 */

export default function CameraValues(props) {
  const { controlRef, cameraRef } = useCamVal()
  const [state, setState] = useState({
    CameraPos: [0, 0, 0],
    TargetControl: [0, 0, 0],
    LookAt: [0, 0, 0],
  })

  function onCameraValuesClicked() {
    let value = cameraRef.position.toArray()
    let value1 = controlRef.current.target.toArray()
    setState({
      ...state,
      CameraPos: value,
      TargetControl: value1,
    })
  }

  if (props.hidden) {
    return (
      <div className={css(styles.infoContainer)}>
        <button className={css(styles.button)} onClick={onCameraValuesClicked}>
          Camera Values
        </button>
        <div className={css(styles.textContainer)}>
          Position: {state.CameraPos[0]}, {state.CameraPos[1]},{' '}
          {state.CameraPos[2]}
          {/* <br/>
          <br/>
          LookAt: {state.LookAt} */}
          <br />
          <br />
          TargetControl: {state.TargetControl[0]} , {state.TargetControl[1]},{' '}
          {state.TargetControl[2]}
        </div>
      </div>
    )
  } else {
    return null
  }
}

const styles = StyleSheet.create({
  infoContainer: {
    position: 'absolute',
    // width:494,
    // height:289,
    backgroundColor: 'lightgreen',
    textAlign: 'center',
    top: 0,
    right: 0,
    paddingBottom: 50,
    paddingRight: 50,
  },
  button: {
    marginTop: 10,
  },
  textContainer: {
    textAlign: 'left',
    marginLeft: 20,
    marginTop: 20,
    userSelect: 'auto',
  },
})
