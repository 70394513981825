import create from 'zustand'

var measuredTime = 0
let interval = null

export const useAnalytics = create((set, get) => ({
  output: null,
  lastSection: null,
  stopMeasureTime: () => {
    if (interval != null) {
      clearInterval(interval)
      interval = null

      let last = get().lastSection
      let currentOp = get().output
      let min = parseInt(measuredTime / 60)

      let second = measuredTime - 60 * min

      let sMin = min < 10 ? '0' + min.toString() : min.toString()
      let sSec = second < 10 ? '0' + second.toString() : second.toString()
      last['time-spent'] = sMin + ':' + sSec
      if (currentOp == null) {
        currentOp = [last]
      } else {
        currentOp[currentOp.length] = last
      }
      //console.log(currentOp);

      set((state) => ({
        ...state,
        output: currentOp,
      }))
    }
  },
  measureTime: (sec, sector) => {
    let op = { section: sec, sector: sector, 'time-spent': '00:00' }
    set((state) => ({
      ...state,
      lastSection: op,
    }))
    meaTime()
  },
}))

function meaTime() {
  measuredTime = 0
  if (interval != null) clearInterval(interval)

  interval = setInterval(() => {
    measuredTime++
  }, 1000)
}
