import React from 'react';

export default function HumburgerIcon(){
    return(
        <>
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="8.74227e-09" y1="0.5" x2="15" y2="0.5" stroke="white"/>
            <line x1="8.74227e-09" y1="7.64258" x2="15" y2="7.64258" stroke="white"/>
            <line y1="15.5" x2="15" y2="15.5" stroke="white"/>
        </svg>
        </>
    )
}