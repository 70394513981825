import { css, StyleSheet } from 'aphrodite'
import React, { useState, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Link, useLocation } from 'wouter'
import { sendGAEvent } from '../../core/GoogleAnalytics'
import {
  useBehaviour,
  useEnvironment,
  useHud,
  useMaster,
  useSFDC,
} from '../../stores'

export default function NavBar(props) {
  const { currentCategory } = useBehaviour()
  const { data } = useMaster()
  const [currCat, setCurrCat] = useState(-1)
  const [location] = useLocation()
  const { continuaToggle, current } = useEnvironment()
  const Continua = useHud()
  const { showForm, setShowForm } = useSFDC()

  function OnNavCategoryClicked(category) {
    sendGAEvent('Navbar Category Selected', 'Click', category)
  }

  useEffect(() => {
    setCurrCat(currentCategory)
  }, [currentCategory])

  function OnContinuaClicked() {
    continuaToggle()
    Continua.setContinuaLoading(true)
    if (current !== 2) {
      Continua.setContinuaLoading(true)
    } else {
      Continua.setContinuaLoading(false)
    }
  }

  return (
    <>
      <div
        className={css(styles.nav)}
        style={{
          justifyContent: location.includes('/continua')
            ? 'center'
            : 'flex-start',
        }}
      >
        {Object.keys(
          current === 2 ? data.continuaCategories : data.categories
        ).map((key, index) => {
          let dat = location.includes('/continua')
            ? data.continuaCategories[key]
            : data.categories[key]
          let href = dat != null && dat.target
          return (
            <div
              key={index}
              className={css(
                styles.navLink,
                current === 2 ? styles.countinuaMargin : styles.navMargin
              )}
            >
              <Link
                onClick={() => {
                  OnNavCategoryClicked(dat.display)
                  showForm && setShowForm(false)
                  dat.target === '/continua' && OnContinuaClicked()
                  // : OnCategoriesClick()
                  // OnContinuaClicked()
                }}
                className={css(
                  index === currCat ? styles.linkFontSelected : styles.linkFont,
                  styles.link
                )}
                href={href}
              >
                {dat != null && dat.target === '/continua' && !isMobileOnly ? (
                  <div
                    className={css(
                      styles.continuaLogo,
                      styles.linkFont,
                      styles.link
                    )}
                  >
                    <img
                      src={dat.src}
                      alt="continua_logo"
                      className={css(styles.continuaImg)}
                    />
                  </div>
                ) : (
                  dat != null && dat.display
                )}
              </Link>
            </div>
          )
        })}
      </div>
    </>
  )
}

const styles = StyleSheet.create({
  continuaLogo: {
    width: `${isMobileOnly ? '5.5vw' : '6.3vw'}`,
    height: 'auto',
    cursor: 'pointer',
  },
  continuaImg: {
    height: '100%',
    width: '100%',
  },
  fadeOut: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100vh',
    backgroundColor: '#000000',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: '100%',
      position: 'fixed',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: '100%',
        position: 'fixed',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: '100%',
        position: 'fixed',
      },
    textAlign: 'center',
  },
  text: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    top: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'space-around',
    color: 'white',
    fontSize: 40,
    // paddingTop: 260,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 30,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 30,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 30,
      },
  },
  continuaButton: {
    background: 'none',
    outline: 'none',
    // border: 'none',
    width: '12vh',
    pointerEvents: 'auto',
  },
  continuaSelected: {
    borderBottom: '0.2vh solid rgb(255,255,255)',
  },
  continua: {
    borderBottom: 'none',
  },
  nav: {
    display: 'flex',
    // background: 'red',
    width: '100%',
    marginLeft: '2vh',

    '@media(max-width: 575.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 768.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 992px) and (orientation:portrait)': {
      display: 'none',
    },
  },
  button: {
    pointerEvents: 'auto',
  },
  linkFont: {
    textDecoration: 'none',
  },
  linkFontSelected: {
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationColor: '#ffffff',
  },
  navLink: {
    textAlign: 'center',
    // paddingLeft: '1.5vw',
    // paddingRight: '1.5vw',
    // width: 120,
    whiteSpace: 'nowrap',
    // marginLeft: '4vw',
    // backgroundColor: 'blue',
  },
  navMargin: {
    '@media(max-width: 575.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 768.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 992px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(min-width: 992px)': {
      marginLeft: '2.3vw',
    },
    '@media(min-width: 1100px)': {
      marginLeft: '3vw',
    },
    '@media(min-width: 1200px)': {
      marginLeft: '3.3vw',
    },
    '@media(min-width: 1440px)': {
      marginLeft: '4.2vw',
    },
    '@media(min-width: 1600px)': {
      marginLeft: '3.3vw',
    },
    '@media(min-width: 1920px)': {
      marginLeft: '4.4vw',
    },
    '@media(min-width: 2560px)': {
      marginLeft: '4.4vw',
    },
  },
  countinuaMargin: {
    '@media(max-width: 575.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 768.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 992px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(min-width: 992px)': {
      marginLeft: '8vw',
    },
    '@media(min-width: 1200px)': {
      marginLeft: '8vw',
    },
    '@media(min-width: 1440px)': {
      marginLeft: '8vw',
    },
    // '@media(min-width: 1600px) and (max-width: 2559.98px)': {
    //   marginLeft: '6.5vw',
    // },
    // '@media(min-width: 2560px)': {
    //   marginLeft: '5vw',
    // },
  },
  link: {
    display: 'block',
    textAlign: 'center',
    fontFamily: 'Oxygen-Regular',
    fontSize: 15,
    flex: 'none',
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    lineHeight: '19px',
    color: '#ffffff',
    // backgroundColor: 'black',
    pointerEvents: 'auto',
    '@media(max-width: 575.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      display: 'none',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        display: 'none',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        display: 'none',
      },
    '@media(min-width: 768px) and (max-width: 991.98px)': {
      fontSize: 12,
      width: 80,
    },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        fontSize: 14,
        marginTop: 50,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 15,
        marginTop: 50,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      fontSize: 12,
      marginTop: 50,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 13,
      marginTop: 50,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      fontSize: 15,
      marginTop: 51,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      fontSize: 18,
      marginTop: 51,
    },
    '@media(min-width: 2560px)': {
      fontSize: 24,
      marginTop: 61,
    },
  },
})
