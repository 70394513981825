/** Summary :
 * camera - setting up Camera with default position.
 * cameraUpadate - update orbitControls.
 * Scene - passes two props: controls and camerUpdate
 */

import React, { useRef, useEffect, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import TweenManger from './TweenManager'
import { OrbitControls } from '@react-three/drei'
import { useLocation } from 'wouter'
import { useCamVal } from '../stores'

export default function CameraTween({ controlEnabled }) {
  const { camera } = useThree()
  const controls = useRef()
  const [rotate, setRotate] = useState(true)
  const [location] = useLocation()
  const { setCameraRef, setControlRef } = useCamVal()

  const regress = useThree((state) => state.performance.regress)
  useEffect(() => {
    if (controls.current) controls.current.addEventListener('change', regress)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // //console.log(controls)

  useEffect(() => {
    camera.fov = 45
    //  camera.position.set(-7.6,2.9, 2.86)
    camera.updateProjectionMatrix()
    controls.current.update()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    controls.current.enabled = controlEnabled
    //
    // controls.current.addEventListener('change', invalidate)
  }, [controlEnabled])

  // useEffect(() => {
  //   //  gl.domElement.addEventListener('click',pauseRotation)
  //   //  gl.domElement.addEventListener('clickup',pauseRotation)
  //   //  gl.domElement.addEventListener('touchstart',pauseRotation)
  //   //  gl.domElement.addEventListener('touchend',pauseRotation)
  //   //  return() => {
  //   //     gl.domElement.removeEventListener('click',pauseRotation)
  //   //     gl.domElement.removeEventListener('clickup',pauseRotation)
  //   //     gl.domElement.removeEventListener('touchstart',pauseRotation)
  //   //     gl.domElement.removeEventListener('touchend',pauseRotation)
  //   //  }
  // }, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (location !== '/') {
      if (rotate) setRotate(false)
    }
  })

  useEffect(() => {
    setControlRef(controls)
    setCameraRef(camera)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const pauseRotation = () => {
  //   if (location === '/') {
  //     setRotate(false)
  //     if (time) clearTimeout(time)
  //     time = null
  //     time = setTimeout(() => {
  //       setRotate(true)
  //     }, 30000)
  //   }
  // }

  const cameraUpdate = () => {
    controls.current.update()
  }

  // useFrame(() => {
  //   //  cameraLogs();
  //   // console.log('Control')
  //   // console.log(controls.current.target)
  //   // console.log('position')
  //   // console.log(camera.position)
  // })

  // function cameraLogs() {
  //   //console.log("["+Math.round(camera.position.x * 10000) / 10000+","+Math.round(camera.position.y * 10000) / 10000+","+Math.round(camera.position.z * 10000) / 10000+"]");
  // }

  return (
    <group>
      <OrbitControls
        regress
        ref={controls}
        rotateSpeed={0.3}
        enableDamping={false}
        maxDistance={5.5}
        enablePan={true}
        maxPolarAngle={Math.PI / 2}
      />

      <TweenManger control={controls} cameraUpdate={cameraUpdate} />
    </group>
  )
}

// autoRotate={isMobile ? false : rotate} autoRotateSpeed={0.1}
