import { useFrame } from '@react-three/fiber'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import { TweenTargets } from '../constants'
import CameraTween from '../core/CameraTween'
import { useTween } from '../stores'

export default function CameraHandler() {
  // const { camera } = useThree()
  const [location] = useLocation()
  const { startTween } = useTween()

  const [lastLocation, setLastLocation] = useState('/')

  useEffect(() => {
    startTween(TweenTargets[location])
    setLastLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useFrame(() => {
    // console.log(
    //   '[' +
    //     (Math.round(camera.position.x * 10000) / 10000).toFixed(3) +
    //     ',' +
    //     (Math.round(camera.position.y * 10000) / 10000).toFixed(3) +
    //     ',' +
    //     (Math.round(camera.position.z * 10000) / 10000).toFixed(3) +
    //     ']'
    // )
  })

  return (
    <>
      <CameraTween
        controlEnabled={TweenTargets[lastLocation].controlEnabled}
        // controlEnabled={true}
      />
    </>
  )
}
