import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import Circle from '../../components/icons/Circle'
import carengine from '../../audio/carengine.wav'
import { useMaster } from '../../stores'

export default function StartEngineButtonUI() {
  const [colorState, setColorState] = useState('#3F3F3F')
  const [engineState, setEngineState] = useState(false)
  const { misc } = useMaster()

  function engine() {
    if (engineState) {
      setEngineState(false)
      setColorState('#FF6B00')
      document.getElementById('audio').play()
    } else {
      setEngineState(true)
      setColorState('#3F3F3F')
      document.getElementById('audio').pause()
      document.getElementById('audio').currentTime = 0
    }
  }

  // useEffect(() => {
  //   if (misc != null) {
  //     console.log(misc['start_engine'])
  //   }
  // }, [engineState])

  return (
    <>
      {misc != null && (
        <div className={css(styles.container)}>
          <button className={css(styles.button)} onClick={engine}>
            <Circle />
          </button>
          <div className={css(styles.buttonText)}>
            {misc['start_engine'] != null && misc['start_engine'].title}
          </div>
          <div
            className={css(styles.circle)}
            style={{ backgroundColor: colorState }}
          ></div>
          <audio id="audio" src={carengine} type="audio/wav" />
        </div>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    height: 140,
    width: 140,
    top: 400,
    right: 300,
    textAlign: 'center',
    // backgroundColor: 'blue',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      top: 180,
      right: 120,
      height: 80,
      width: 80,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        top: 190,
        right: 130,
        height: 80,
        width: 80,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        top: 200,
        right: 210,
        height: 80,
        width: 80,
      },
  },
  button: {
    position: 'absolute',
    outline: 'none',
    background: 'none',
    border: 'none',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    // textAlign: 'center',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    padding: 0,
    pointerEvents: 'auto',
    cursor: 'pointer',
    // zIndex: -1
    // '@media(max-width: 575.98px) and (orientation:landscape)': {
    //     height:100,
    //     width: 100
    // },
    // '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)': {
    //     height:100,
    //     width: 100
    // },
    // '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)': {
    //     height:100,
    //     width: 100
    // },
  },
  buttonText: {
    color: '#ffffff',
    textAlign: 'center',
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    fontSize: 17,
    width: 71,
    marginTop: 45,
    marginLeft: 'auto',
    marginRight: 'auto',
    lineHeight: '23px',
    cursor: 'pointer',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 40,
      fontSize: 10,
      lineHeight: '13px',
      marginTop: 20,
      left: 25,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 50,
        fontSize: 11,
        lineHeight: '14px',
        marginTop: 24,
        left: 25,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 50,
        fontSize: 11,
        lineHeight: '14px',
        marginTop: 24,
        left: 25,
      },
    // zIndex: 200
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    // backgroundColor: '#3F3F3F',
    marginTop: 4,
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 12,
      height: 12,
      marginTop: 3,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 12,
        height: 12,
        marginTop: 3,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 12,
        height: 12,
        marginTop: 3,
      },
  },
})
