import React from 'react'
import BackArrow from '../../../components/icons/BackArrow'
import { css, StyleSheet } from 'aphrodite'
import { useState } from 'react'
import { useEffect } from 'react'
import NextArrow from '../../../components/icons/NextArrow'
import { isMobileOnly } from 'react-device-detect'

function ProductCarousel(props) {
  const { children, show, infiniteLoop, productLength } = props

  const [currentIndex, setCurrentIndex] = useState(infiniteLoop ? show : 0)
  const [length, setLength] = useState(productLength)

  const [isRepeating, setIsRepeating] = useState(
    infiniteLoop && productLength > show
  )
  const [transitionEnabled, setTransitionEnabled] = useState(true)

  const [touchPosition, setTouchPosition] = useState(null)

  // Set the length to match current children from props
  useEffect(() => {
    setLength(productLength)
    setIsRepeating(infiniteLoop && productLength > show)
  }, [children, infiniteLoop, show])

  useEffect(() => {
    console.log('isRepeating: ' + isRepeating + 'length: ' + length)
    if (isRepeating) {
      if (currentIndex === show || currentIndex === length) {
        setTransitionEnabled(true)
      }
    }
  }, [currentIndex, isRepeating, show, length])

  const next = () => {
    if (isRepeating || currentIndex < length - show) {
      setCurrentIndex((prevState) => prevState + 1)
    }
  }

  const prev = () => {
    console.log('current index: ' + currentIndex)
    if (isRepeating || currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1)
    }
  }

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = (e) => {
    const touchDown = touchPosition

    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch

    if (diff > 5) {
      next()
    }

    if (diff < -5) {
      prev()
    }

    setTouchPosition(null)
  }

  const handleTransitionEnd = () => {
    if (isRepeating) {
      if (currentIndex === 0) {
        setTransitionEnabled(false)
        setCurrentIndex(length)
      } else if (currentIndex === length + show) {
        setTransitionEnabled(false)
        setCurrentIndex(show)
      }
    }
  }

  const renderExtraPrev = () => {
    let output = []
    for (let index = 0; index < show; index++) {
      output.push(children[length - 1 - index])
    }
    output.reverse()
    return output
  }

  const renderExtraNext = () => {
    let output = []
    for (let index = 0; index < show; index++) {
      output.push(children[index])
    }
    return output
  }
  const [showItems, setShowItems] = useState(false)
  useEffect(() => {
    if (isMobileOnly) {
      if (length < 2) {
        setShowItems(true)
      } else {
        setShowItems(false)
      }
    } else {
      if (length < 3) {
        setShowItems(true)
      } else {
        setShowItems(false)
      }
    }
  })
  return (
    <div className={css(styles.CarouselContainer)}>
      <div className={css(styles.CarouselWrapper)}>
        {/* You can alwas change the content of the button to other things */}
        {(isRepeating || length >= 3) && (
          <button
            type="button"
            onClick={prev}
            className={css(styles.btn)}
            style={{
              marginRight: '1.5vh',
            }}
          >
            <BackArrow />
          </button>
        )}
        <div
          className={css(styles.CarouselContentWrapper)}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className={`carousel-content show-${show}`}
            style={{
              transform: showItems
                ? `translateX(0%)`
                : `translateX(-${currentIndex * (100 / show)}%)`,
              transition: !transitionEnabled ? 'none' : undefined,
            }}
            onTransitionEnd={() => handleTransitionEnd()}
          >
            {length > show && isRepeating && renderExtraPrev()}
            {children}
            {length > show && isRepeating && renderExtraNext()}
          </div>
        </div>
        {/* You can alwas change the content of the button to other things */}
        {(isRepeating || length >= 3) && (
          <button
            onClick={next}
            className={css(styles.btn)}
            style={{
              marginLeft: '1.5vh',
              // background: 'black',
            }}
          >
            <NextArrow />
          </button>
        )}
      </div>
    </div>
  )
}

export default ProductCarousel

const styles = StyleSheet.create({
  imgContainer: {
    background: 'none',
    outline: 'none',
    border: 'none',
    ':hover': {
      opacity: 0.5,
    },
    ':active': {
      opacity: 1,
    },
    display: 'inline-block',
    // margin: '10px 11px 0px 11px',
    textAlign: 'center',
    // '@media(min-width:556px) and (orientation:landscape)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 768px) and (orientation:landscape)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 992px) and (orientation:landscape)': {
    //   margin: '10px 9px 0px 9px',
    // },
    // '@media(min-width: 1200px)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 1400px)': {
    //   margin: '10px 11px 0px 11px',
    // },
    // '@media(min-width: 1600px)': {
    //   margin: '10px 11px 0px 11px',
    // },
  },
  imgConent: {
    border: '3px solid grey',
    padding: '10px',
    // height: '5vh',
    borderRadius: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginTop: '50%',
    width: 'fit-content',
    fontFamily: 'Roboto-Regular',
    fontSize: 20,
    fontWeight: 'normal',
    color: '#ffffff',
    lineHeight: '23px',
    whiteSpace: 'nowrap',
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 14,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 14,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 16,
    },
    '@media(min-width: 1200px)': {
      fontSize: 16,
    },
    '@media(min-width: 1400px)': {
      fontSize: 20,
    },
    '@media(min-width: 1600px)': {
      fontSize: 20,
    },
  },
  btnContainer: {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    transform: 'translateY(-50%)',
    // top: 'calc(50% - 15px)',
    height: '100%',
    // width: '5vh',
    // borderRadius: '50%',
    background: 'rgb(60, 60, 60)',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // opacity: 0.5,
  },
  container: {
    // width: '100%',
    // height: 110,
    // display: 'inline-block',
    // marginBottom: 12,
    height: 'fit-content',
  },
  btn: {
    // width: 30,
    // height: 30,
    // paddingTop: '0.8vh',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 0,
    border: 0,
    background: 'none',
    // height: 'auto',
    // width: 'auto',
    pointerEvents: 'auto',
    // width: '100%',
    height: 'auto',
    // width: isMobileOnly ? '3.5vw' : '4.3vh',
    // height: isMobileOnly ? '3.5vw' : '4.3vh',
  },
  CarouselContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  CarouselWrapper: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },

  CarouselContentWrapper: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },

  CarouselContent: {
    display: 'flex',
    transition: 'all 250ms linear',
    '::-ms-overflow-style': 'none' /* hide scrollbar in IE and Edge */,
    scrollbarWidth: 'none' /* hide scrollbar in Firefox */,
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },

  /* hide scrollbar in webkit browser */
  // .carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
  //     display: none;
  // }

  CarouselContent: {
    width: '100%',
    flexShrink: 0,
    flexGrow: 1,
  },
})
