import React, { useEffect } from 'react'
import { Color } from 'three'

//Opacity .75
//metalnes .1
//R .1

function Glass(props) {
  useEffect(() => {
    props.materials.Glass.transparent = true
    props.materials.Glass.color = new Color('#000100')
    props.materials.Glass.roughness = 0.1
    props.materials.Glass.metalness = 0.1
    props.materials.Glass.opacity = 0.3
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.object != null) {
      if (props.object.opacity != null) {
        props.materials.Glass.opacity = props.object.opacity
      } else {
        props.materials.Glass.opacity = 0.3
      }
    } else {
      props.materials.Glass.opacity = 0.3
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.object])

  return <primitive object={props.scene} dispose={null} />
}

export default Glass
