import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import BackArrow from '../../components/icons/BackArrow'
import NextArrow from '../../components/icons/NextArrow'
import { isMobile, isMobileOnly } from 'react-device-detect'
import ProductcardCarousel from './ProductcardCarousel'
import MultiProductCarousel from './MultiProductCarousel'
import ProductItems from './Carousel/ProductItems'

export default function DetailCardSubcomponent(props) {
  const [state, setState] = useState({
    scrollValue: 110,
    productsOfferedLength: 2,
  })

  function ArrowClicked(direction) {
    switch (direction) {
      case 'Left':
        document.getElementById('imageContainer').scrollBy({
          top: 0,
          left: -state.scrollValue,
          behavior: 'smooth',
        })
        // console.log("Left")
        break
      case 'Right':
        document.getElementById('imageContainer').scrollBy({
          top: 0,
          left: state.scrollValue,
          behavior: 'smooth',
        })
        // console.log("right")
        break
      default:
        break
    }
  }

  function reportWindowSize(event) {
    if (document.documentElement.clientWidth < 576) {
      if (
        document.documentElement.clientWidth >
        document.documentElement.clientHeight
      ) {
        setState({
          scrollValue: 120,
          productsOfferedLength: 1,
        })
      }
    } else if (
      document.documentElement.clientWidth >= 576 &&
      document.documentElement.clientWidth < 768
    ) {
      if (
        document.documentElement.clientWidth >
        document.documentElement.clientHeight
      ) {
        setState({
          scrollValue: 120,
          productsOfferedLength: 1,
        })
      }
    } else if (
      document.documentElement.clientWidth >= 768 &&
      document.documentElement.clientWidth < 992
    ) {
      if (
        document.documentElement.clientWidth >
        document.documentElement.clientHeight
      ) {
        setState({
          scrollValue: 110,
          productsOfferedLength: 1,
        })
      }
    } else if (
      document.documentElement.clientWidth >= 992 &&
      document.documentElement.clientWidth < 1200
    ) {
      setState({
        scrollValue: 125,
        productsOfferedLength: 2,
      })
    } else if (
      document.documentElement.clientWidth >= 1200 &&
      document.documentElement.clientWidth < 1400
    ) {
      setState({
        scrollValue: 125,
        productsOfferedLength: 2,
      })
    } else if (
      document.documentElement.clientWidth >= 1400 &&
      document.documentElement.clientWidth < 1600
    ) {
      setState({
        scrollValue: 110,
        productsOfferedLength: 2,
      })
    } else if (
      document.documentElement.clientWidth >= 1600 &&
      document.documentElement.clientWidth < 2560
    ) {
      setState({
        scrollValue: 110,
        productsOfferedLength: 2,
      })
    } else if (document.documentElement.clientWidth >= 2560) {
      setState({
        scrollValue: 110,
        productsOfferedLength: 2,
      })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      reportWindowSize(event)
    })

    return function cleanup() {
      window.removeEventListener('resize', reportWindowSize)
    }
  })

  useEffect(() => {
    reportWindowSize()
  }, [])

  return (
    <>
      <div className={css(styles.container)}>
        <ProductItems productOffered={props.productsOffered} />
        {/* {props.productsOffered != null && isMobile ? (
          <MultiProductCarousel productOffered={props.productsOffered} />
        ) : (
          // <ProductcardCarousel productOffered={props.productsOffered} />
          <>
            <div
              className={css(
                props.productsOffered.length > state.productsOfferedLength
                  ? styles.buttonContainer
                  : styles.displayNone
              )}
            >
              <button
                className={css(styles.btn)}
                onPointerDown={() => {
                  ArrowClicked('Left')
                }}
              >
                <BackArrow />
              </button>
            </div>
            <div
              className={css(
                props.productsOffered.length > state.productsOfferedLength
                  ? styles.buttonContainerRight
                  : styles.displayNone
              )}
            >
              <button
                className={css(styles.btn)}
                onPointerDown={() => {
                  ArrowClicked('Right')
                }}
              >
                <NextArrow />
              </button>
            </div>
            <div className={css(styles.imageContainer)} id="imageContainer">
              {props.productsOffered.map((data, index) => {
                return (
                  <button key={index} className={css(styles.imgContainer)}>
                    <div
                      className={css(styles.imgConent)}
                      onClick={() => {
                        window.open(data.url, '_blank')
                        window.focus()
                      }}
                    >
                      {data.title}
                    </div>
                  </button>
                )
              })}
            </div>
          </>
        )} */}
      </div>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    // height: 110,
    // display: 'inline-block',
    // display: 'flex',
    marginBottom: 12,
    height: 'fit-content',
    // background: 'red',
  },
  buttonContainer: {
    height: 'fit-content',
    width: `${isMobileOnly ? '3.8vh' : '3vh'}`,
    float: 'left',
    marginRight: '1.2vw',
    marginTop: '6%',
    '@media(min-width:556px) and (orientation:landscape)': {
      marginTop: '10%',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      marginTop: '10%',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      marginTop: '8%',
    },
    '@media(min-width: 1200px)': {
      marginTop: '6%',
    },
    '@media(min-width: 1400px)': {
      marginTop: '6%',
    },
    '@media(min-width: 1600px)': {
      marginTop: '6%',
    },
  },
  buttonContainerRight: {
    height: 'fit-content',
    width: `${isMobileOnly ? '3.8vh' : '3vh'}`,
    float: 'right',
    marginLeft: '1.2vw',
    marginTop: '6%',
    '@media(min-width:556px) and (orientation:landscape)': {
      marginTop: '10%',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      marginTop: '10%',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      marginTop: '8%',
    },
    '@media(min-width: 1200px)': {
      marginTop: '6%',
    },
    '@media(min-width: 1400px)': {
      marginTop: '6%',
    },
    '@media(min-width: 1600px)': {
      marginTop: '6%',
    },
  },
  btn: {
    background: 'none',
    border: 'none',
    outline: 'none',
    textAlign: 'center',
    pointerEvents: 'auto',
    cursor: 'pointer',
    padding: 0,
    height: '100%',
    width: '100%',
  },
  imageContainer: {
    height: 'fit-content',
    overflowY: 'hidden',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    // overflowX: 'auto',
    // height: 110,
    // width: '80%',
    textAlign: 'left',
  },
  imgContainer: {
    background: 'none',
    outline: 'none',
    border: 'none',
    ':hover': {
      opacity: 0.5,
    },
    ':active': {
      opacity: 1,
    },
    display: 'inline-block',
    margin: '10px 11px 0px 11px',
    textAlign: 'center',
    '@media(min-width:556px) and (orientation:landscape)': {
      margin: '10px 11px 0px 11px',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      margin: '10px 11px 0px 11px',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      margin: '10px 9px 0px 9px',
    },
    '@media(min-width: 1200px)': {
      margin: '10px 11px 0px 11px',
    },
    '@media(min-width: 1400px)': {
      margin: '10px 11px 0px 11px',
    },
    '@media(min-width: 1600px)': {
      margin: '10px 11px 0px 11px',
    },
  },
  imgConent: {
    border: '3px solid grey',
    padding: '10px',
    borderRadius: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginTop: '50%',
    width: 'fit-content',
    fontFamily: 'Roboto-Regular',
    fontSize: 20,
    fontWeight: 'normal',
    color: '#ffffff',
    lineHeight: '23px',
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 14,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 14,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 16,
    },
    '@media(min-width: 1200px)': {
      fontSize: 16,
    },
    '@media(min-width: 1400px)': {
      fontSize: 20,
    },
    '@media(min-width: 1600px)': {
      fontSize: 20,
    },
  },
  displayNone: {
    display: 'none',
  },
  img: {
    width: 100,
    height: 66,
    borderRadius: 10,
    backgroundColor: '#C4C4C4',
    marginTop: 3,
    '@media(min-width:556px) and (orientation:landscape)': {
      width: 90,
      height: 66,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      width: 90,
      height: 66,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      width: 100,
      height: 66,
    },
    '@media(min-width: 1200px)': {
      width: 100,
      height: 66,
    },
    '@media(min-width: 1400px)': {
      width: 109,
      height: 75,
    },
    '@media(min-width: 1600px)': {
      width: 109,
      height: 75,
    },
  },
})
