import { useEffect } from 'react'
import { useMaster } from '../stores'

export default function StoreUpdate({ onChange }) {
  useEffect(() => {
    const unsub = useMaster.subscribe((store) => {
      onChange(store)
      // console.log(store)
    })

    return function cleanup() {
      unsub()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
