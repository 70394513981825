import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useEffect, useState } from 'react'
import { fadeIn, fadeOut } from 'react-animations'
import { useLocation } from 'wouter'

function SFDCFormSuccessPopup() {
  const [render, setRender] = useState(false)
  const [popup, setPopup] = useState(true)
  const queryParams = new URLSearchParams(window.location.search)
  const [, setLocation] = useLocation()

  // Check if the 'showPopup' query parameter is present and set to 'true'
  const showPopup = queryParams.get('thankyou') === 'true'

  // useEffect(() => {
  //   if (showPopup) {
  //     setPopup(true)
  //   }
  // }, [showPopup])

  // useEffect(() => {
  //   console.log('param: ' + queryParams.get('thankyou'))
  //   let timeout = null
  //   timeout = setTimeout(() => {
  //     setPopup(false)
  //     // setLocation('/')
  //   }, 8000)
  //   return () => clearTimeout(timeout)
  // }, [])

  useEffect(() => {
    if (popup) setRender(true)
  }, [popup])

  const onAnimationEnded = () => {
    if (!popup) setRender(false)
  }

  return (
    <>
      {render && (
        <div
          className={css(
            styles.container,
            popup ? styles.fadeInAnim : styles.fadeOutAnim
          )}
          onAnimationEnd={onAnimationEnded}
        >
          <div className={css(styles.logoContainer)}>
            <img
              className={css(styles.logoImage)}
              src="./images/logoImage.jpg"
            />
          </div>
          <div className={css(styles.popup)}>
            <div className={css(styles.title)}>Thank you!</div>
            <div className={css(styles.subTitle)}>
              Your information has been submitted successfully.
            </div>
            <button
              className={css(styles.button)}
              onClick={() => {
                setLocation('/')
                setPopup(false)
              }}
            >
              Back to Home
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default SFDCFormSuccessPopup

const styles = StyleSheet.create({
  button: {
    padding: '1.5vh 3vh',
    backgroundColor: 'rgb(255,255,255)',
    color: '#3C3C3C',
    fontSize: '2vh',
    fontFamily: 'Oxygen-Regular',
    border: 'none',
    outline: 'none',
    borderRadius: '0.5vh',
    // float: 'right',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '3vh',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  logoContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '8vw',
    height: 'auto',
  },
  logoImage: {
    width: '100%',
    height: 'auto',
  },
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '0.2s',
    animationFillMode: 'forwards',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.8s',
    animationFillMode: 'forwards',
  },
  container: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255,255,255,1)',
    inset: 0,
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 17695054,
  },
  popup: {
    padding: '8vh',
    backgroundColor: '#3C3C3C',
    color: 'white',
    borderRadius: '0.8vh',
  },
  title: {
    fontSize: '4vh',
    fontFamily: 'Oxygen-Bold',
    fontWeight: 'normal',
    letterSpacing: 0.5,
  },
  subTitle: {
    fontSize: '2vh',
    fontFamily: 'Oxygen-Regular',
    letterSpacing: 0.5,
  },
})
