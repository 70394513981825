import React from 'react';

export default function RotateMobileIcon(){
    return(
        <>
        <svg viewBox="0 0 68 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.1158 59.3001H23.2166C22.3372 59.2993 21.4941 58.9526 20.8723 58.3361C20.2505 57.7196 19.9008 56.8837 19.9 56.0119L19.9 16.7804C19.9008 15.9085 20.2505 15.0726 20.8723 14.4561C21.4941 13.8396 22.3372 13.493 23.2166 13.4922H43.1158C43.9951 13.493 44.8382 13.8396 45.46 14.4561C46.0818 15.0726 46.4315 15.9085 46.4323 16.7804V56.0119C46.4315 56.8837 46.0818 57.7196 45.46 58.3361C44.8382 58.9526 43.9951 59.2993 43.1158 59.3001ZM23.2166 19.7992V53.0992H43.1158V19.7992H23.2166Z" fill="#737373"/>
            <path d="M67.8037 18.085L65.466 15.7672L61.1707 20.0053V11.5087C61.1672 8.45744 59.9431 5.53219 57.7669 3.37465C55.5908 1.21711 52.6403 0.00348114 49.5628 0H41.2715V3.28819H49.5628C51.761 3.29089 53.8683 4.15785 55.4227 5.6989C56.977 7.23995 57.8514 9.32928 57.8541 11.5087V20.0083L53.5589 15.7701L51.2211 18.085L59.5124 26.3055L67.8037 18.085Z" fill="#737373"/>
            <path d="M-3.78207e-05 53.915L2.33775 56.2328L6.63303 51.9947L6.63303 60.4913C6.63654 63.5426 7.86064 66.4678 10.0368 68.6254C12.2129 70.7829 15.1634 71.9965 18.2409 72L26.5322 72L26.5322 68.7118L18.2409 68.7118C16.0427 68.7091 13.9354 67.8422 12.3811 66.3011C10.8267 64.7601 9.95229 62.6707 9.94956 60.4913L9.94956 51.9918L14.2448 56.2299L16.5826 53.915L8.29129 45.6945L-3.78207e-05 53.915Z" fill="#737373"/>
        </svg>
        </>
    )
}