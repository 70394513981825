import { css, StyleSheet } from 'aphrodite'
import React from 'react'
// import { a as web } from '@react-spring/web'
import { useHud, useInfo, useLoader } from '../../stores'
import { useState, useEffect, useRef } from 'react'
import { fadeIn, fadeOut } from 'react-animations'
import RotateSign from '../../components/icons/RotateSign'
import { useLocation } from 'wouter'

export default function Info(props) {
  const [leftInfo, setLeftInfo] = useState(false)
  const [isFadeOut, setIsFadeOut] = useState(false)
  const [rightInfo, setRightInfo] = useState(true)
  const { everythingLoaded } = useLoader()
  const [location] = useLocation()
  let time
  const ref = useRef(null)
  const [continuaState, setContinuaState] = useState(false)
  const AudioState = useHud()

  function fadeOutAnim() {
    setIsFadeOut(true)
  }

  function fadeOutInfo() {
    setRightInfo(false)
    setIsFadeOut(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.addEventListener('click', showInfo)
    document.addEventListener('clickup', showInfo)
    document.addEventListener('touchstart', showInfo)
    document.addEventListener('touchend', showInfo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showInfo = () => {
    setLeftInfo(true)
    if (time) clearTimeout(time)
    time = null
    time = setTimeout(() => {
      setLeftInfo(false)
    }, 10000)
  }

  const handleClickOutside = (event) => {
    if (useLoader.getState().everythingLoaded) {
      if (ref.current && !ref.current.contains(event.target)) {
        fadeOutAnim(setTimeout(() => fadeOutInfo(), 15000))
      }
    }
  }

  function closeInfo() {
    console.log('clicked')
    setIsFadeOut(false)
    setRightInfo(!rightInfo)

    // AudioStates.setAudio('playBG')
  }
  const Continua = useHud()

  useEffect(() => {
    if (location === '/continua' && !Continua.continuaLoading) {
      setContinuaState(true)
    } else {
      setContinuaState(false)
    }
  }, [location, Continua.continuaLoading])

  return (
    <>
      {!leftInfo &&
        !rightInfo &&
        !useInfo.getState().detailsPage &&
        location !== '/coatings/color' && (
          <>
            <div className={css(styles.leftInfoContainer)}>
              <div className={css(styles.leftInfoText)}>
                {props.data['click_component'] != null
                  ? props.data['click_component'].title
                  : 'Click a component to learn more'}
              </div>
            </div>
          </>
        )}
      {everythingLoaded && rightInfo && (location === '/' || continuaState) && (
        <>
          <div
            ref={ref}
            className={css(
              styles.infoContainer,
              continuaState ? styles.continuaBg : styles.birlaBg,
              isFadeOut ? styles.FadeOut : styles.FadeIn
            )}
          >
            <div className={css(styles.title)}>
              {props.data['instruction'] != null
                ? props.data['instruction'].title
                : 'INSTRUCTION'}
            </div>
            <div className={css(styles.desc)}>
              {props.data['click_hotspot'] != null
                ? props.data['click_hotspot'].title
                : 'Click a hotspot on the car to learn more about the component'}
            </div>
            <div className={css(styles.desc)}>
              {props.data['look_around'] != null
                ? props.data['look_around'].title
                : 'Move your mouse to look around the vehicle'}
            </div>
            <div className={css(styles.buttonContainer)}>
              <button
                className={css(styles.btn)}
                onClick={() => fadeOutAnim(setTimeout(() => closeInfo(), 500))}
              >
                {props.data['understood'] != null
                  ? props.data['understood'].title
                  : 'UNDERSTOOD'}
              </button>
            </div>
          </div>
          <div
            ref={ref}
            className={css(
              styles.signContainer,
              isFadeOut ? styles.FadeOut : styles.FadeIn
            )}
          >
            <div className={css(styles.sign)}>
              <RotateSign />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  continuaBg: {
    backgroundColor: 'rgba(98, 187, 70, 0.55)',
  },
  birlaBg: {
    backgroundColor: 'rgba(126, 126, 126, 0.55)',
  },
  FadeIn: {
    animationName: [fadeIn],
    animationDuration: '1s, 300ms',
  },
  FadeOut: {
    animationName: [fadeOut],
    animationDuration: '15s, 300ms',
  },
  leftInfoContainer: {
    position: 'absolute',
    bottom: 16,
    left: '50%',
    transform: 'translateX(-50%)',
    // left: 21,
    '@media(max-width: 575.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 768px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 992px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      bottom: 8,
      // left: 11,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        bottom: 8,
        left: 'unset',
        // right: 17,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        bottom: 8,
        // right: 11,
        // left: 'unset',
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      bottom: 16,
      // left: 21,
    },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        bottom: 16,
        // left: 21,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      bottom: 16,
      // left: 21,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        bottom: 16,
        // left: 21,
      },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      bottom: 16,
      // left: 21,
    },
    '@media(min-width: 1600px) and (max-width: 2255.98px)': {
      bottom: 16,
      // left: 21,
    },
    '@media(min-width: 2256px) and (max-width: 2559.98px)': {
      bottom: 18,
      // left: 21,
    },
    '@media(min-width: 2560px)': {
      bottom: 26,
      // left: 21,
    },
  },
  leftInfoText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 30,
    lineHeight: '35px',
    color: '#ffffff',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 14,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 14,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 14,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      fontSize: 20,
    },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        fontSize: 20,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 24,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 24,
      },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      fontSize: 24,
    },
    '@media(min-width: 1600px) and (max-width: 1919.98px)': {
      fontSize: 24,
    },
    '@media(min-width: 1920px) and (max-width: 2255.98px)': {
      fontSize: 30,
    },
    '@media(min-width: 2256px) and (max-width: 2559.98px)': {
      fontSize: 36,
    },
    '@media(min-width: 2560px)': {
      fontSize: 40,
    },
  },
  infoContainer: {
    position: 'absolute',
    bottom: 0,
    right: 21,

    padding: '3vh',
    marginTop: 10,
    '@media(max-width: 575.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 768px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 992px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      bottom: 18,
      right: 11,
      zIndex: 1,
    },
    '@media(max-width: 575.98px) and (orientation:portrait)': {
      bottom: 18,
      right: 11,
      zIndex: 100,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        bottom: 18,
        right: 'unset',
        left: 11,
        zIndex: 1,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        bottom: 18,
        right: 'unset',
        left: 11,
      },

    // add tablet media queries
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        bottom: 18,
        right: 11,
      },

    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        bottom: 18,
        right: 11,
      },

    // laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      bottom: 18,
      right: 11,
    },

    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      bottom: 18,
      right: 11,
    },

    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      bottom: 18,
      right: 11,
    },

    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      bottom: 18,
      right: 11,
    },

    '@media(min-width: 2560px)': {
      height: 300,
      marginTop: 0,
      marginRight: 40,
    },
  },
  title: {
    textAlign: 'center',
    // marginTop: 12,
    fontSize: 20,
    fontFamily: 'Roboto-Bold',
    lineHeight: '23px',
    color: '#ffffff',
    fontWeight: 'normal',
    borderTop: '3px solid #FFFFFF',
    paddingTop: '2vh',

    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 14,
      lineHeight: '18px',
      marginTop: 10,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 14,
        lineHeight: '18px',
        marginTop: 10,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 14,
        lineHeight: '18px',
        marginTop: 10,
      },

    // add tablet media queries
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        fontSize: 20,
        lineHeight: '23px',
        marginTop: 19,
      },

    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 20,
        lineHeight: '23px',
        // marginTop: 19,
      },

    // laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      fontSize: 20,
      lineHeight: '23px',
      // marginTop: 19,
    },

    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 20,
      lineHeight: '23px',
      // marginTop: 19,
    },

    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      fontSize: 20,
      lineHeight: '23px',
      // marginTop: 19,
    },

    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      fontSize: 20,
      lineHeight: '23px',
      // marginTop: 19,
    },

    '@media(min-width: 2560px)': {
      fontSize: 26,
      // marginTop: 19,
    },
  },
  desc: {
    width: 330,
    textAlign: 'center',
    fontWeight: 'normal',
    fontFamily: 'Roboto-Regular',
    fontSize: '20',
    lineHeight: '23px',
    color: '#ffffff',
    // marginLeft: 15,
    marginTop: 15,

    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 12,
      marginTop: 10,
      lineHeight: '15px',
      width: 230,
    },

    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 12,
        marginTop: 5,
        lineHeight: '15px',
        width: 230,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 12,
        marginTop: 5,
        lineHeight: '18px',
        width: 230,
      },

    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        fontSize: 18,
        marginTop: 18,
        lineHeight: '23px',
      },

    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 18,
        marginTop: 18,
        lineHeight: '23px',
      },

    // laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      fontSize: 18,
      marginTop: 18,
      lineHeight: '23px',
    },

    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 18,
      marginTop: 18,
      lineHeight: '23px',
    },

    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      fontSize: 20,
      // width: 490,
      marginTop: 18,
      lineHeight: '23px',
    },

    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      fontSize: 20,
      // width: 490,
      marginTop: 18,
      lineHeight: '23px',
    },

    '@media(min-width: 2560px)': {
      fontSize: 26,
      width: 520,
      marginTop: 25,
      lineHeight: '32px',
    },
  },
  buttonContainer: {
    width: '100%',
    marginTop: 20,
    textAlign: 'center',
    // backgroundColor: 'black',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      marginTop: 5,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        marginTop: 5,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        marginTop: 5,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        marginTop: 20,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        marginTop: 20,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      marginTop: 20,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      marginTop: 20,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      marginTop: 20,
    },
    '@media(min-width: 2560px)': {
      marginTop: 20,
    },
  },
  btn: {
    background: 'none',
    border: '1px solid #ffffff',
    borderRadius: 10,
    width: '100%',
    height: 40,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Oxygen-Regular',
    color: '#ffffff',
    cursor: 'pointer',
    pointerEvents: 'auto',
    // backgroundColor:'yellow',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      // width: 204,
      height: 32,
      borderRadius: 8,
      fontSize: 14,
      lineHeight: '10px',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        // width: 204,
        height: 32,
        borderRadius: 8,
        fontSize: 14,
        lineHeight: '10px',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        // width: 204,
        height: 32,
        borderRadius: 8,
        fontSize: 14,
        lineHeight: '10px',
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        // width: 304,
        height: 52,
        borderRadius: 12,
        fontSize: 15,
        lineHeight: '19px',
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        // width: 304,
        height: 52,
        borderRadius: 12,
        fontSize: 15,
        lineHeight: '19px',
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      // width: 304,
      height: 52,
      borderRadius: 12,
      fontSize: 15,
      lineHeight: '19px',
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      // width: 304,
      height: 52,
      borderRadius: 12,
      fontSize: 15,
      lineHeight: '19px',
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      // width: 304,
      height: 52,
      borderRadius: 12,
      fontSize: 15,
      lineHeight: '19px',
    },

    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      // width: 304,
      height: 52,
      borderRadius: 12,
      fontSize: 15,
      lineHeight: '19px',
    },

    '@media(min-width: 2560px)': {
      // width: 520,
      height: 60,
      borderRadius: 12,
      fontSize: 22,
    },
  },
  signContainer: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 146,
    textAlign: 'center',
    // backgroundColor: 'red',
    '@media(max-width: 575.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      bottom: 50,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        bottom: 50,
        // left: 100,
        // right: 300
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        bottom: 50,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      bottom: 100,
    },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        bottom: 100,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      bottom: 100,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        bottom: 100,
      },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      bottom: 120,
    },
    '@media(min-width: 1600px) and (max-width: 2255.98px)': {
      bottom: 146,
    },
    '@media(min-width: 2256px) and (max-width: 2559.98px)': {
      bottom: 146,
    },
    '@media(min-width: 2560px)': {
      bottom: 146,
    },
  },
  sign: {
    height: 51,
    width: 44,
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      height: 31,
      width: 34,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        height: 31,
        width: 34,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        height: 31,
        width: 34,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      height: 41,
      width: 34,
    },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        height: 41,
        width: 34,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      height: 51,
      width: 54,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        height: 51,
        width: 54,
      },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      height: 51,
      width: 54,
    },
    '@media(min-width: 1600px) and (max-width: 2255.98px)': {
      height: 51,
      width: 54,
    },
    '@media(min-width: 2256px) and (max-width: 2559.98px)': {
      height: 71,
      width: 74,
    },
    '@media(min-width: 2560px)': {
      height: 71,
      width: 74,
    },
  },
})
