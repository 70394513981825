import { useSpring } from '@react-spring/core'
import React, { useEffect, useState } from 'react'
import { a as animated } from '@react-spring/three'
import { useTexture } from '@react-three/drei'
import { Color, MathUtils } from 'three'
import { useFrame } from '@react-three/fiber'
import { isMobile, isMobileOnly } from 'react-device-detect'

let flipper = 0

function InteriorGLTF(props) {
  const { intensity } = useSpring({
    intensity: props.light ? 1.3 : 0,
    delay: 1500,
  })

  const emissiveTextures = useTexture(
    isMobileOnly ? [] : props.emissiveMapsArray
  )

  const [currentSelected, setCurrentSelected] = useState(-1)

  useEffect(() => {
    props.materials.Interior.emissiveIntensity = 0
    props.materials.Interior.vertexColors = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.object != null) {
      if (props.object.highlight != null) {
        if (props.object.highlight.index !== currentSelected) {
          let nom = props.object.highlight.index
          if (!isMobileOnly) {
            if (nom !== -1) {
              props.materials.Interior.emissiveMap = emissiveTextures[nom]
              props.materials.Interior.emissive = new Color(
                props.object.highlight.color
              )
              props.materials.Interior.needsUpdate = true
            }
          }
          setCurrentSelected(props.object.highlight.index)
        }
        setCurrentSelected(-1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.object])

  function handleHighlightWithPulse(delta) {
    if (props.object != null && props.materials.Interior.emissiveMap != null) {
      if (props.object.highlight != null) {
        if (
          props.object.highlight.pulse != null &&
          props.object.highlight.pulse
        ) {
          flipper += delta * 5
          if (flipper < 5) {
            props.materials.Interior.emissiveIntensity = MathUtils.lerp(
              props.materials.Interior.emissiveIntensity,
              1,
              delta * 2
            )
          } else {
            props.materials.Interior.emissiveIntensity = MathUtils.lerp(
              props.materials.Interior.emissiveIntensity,
              0,
              delta * 2
            )

            if (flipper > 20) flipper = 0
          }
        } else {
          props.materials.Interior.emissiveIntensity = MathUtils.lerp(
            props.materials.Interior.emissiveIntensity,
            1,
            delta * 3
          )
        }
      } else {
        // let col = new Color('#000000')
        props.materials.Interior.emissiveIntensity = MathUtils.lerp(
          props.materials.Interior.emissiveIntensity,
          0,
          delta * 3
        )
      }
    } else {
      if (props.materials.Interior.emissiveIntensity !== 0) {
        props.materials.Interior.emissiveIntensity = MathUtils.lerp(
          props.materials.Interior.emissiveIntensity,
          0,
          delta * 3
        )
      }
    }
  }

  useFrame((state, delta) => {
    if (!isMobileOnly) {
      handleHighlightWithPulse(delta)
    }
  })

  return (
    <>
      <primitive object={props.scene} dispose={null} />
      <animated.pointLight
        position={props.light ? props.object.lightPosition : [-3, 1.2, 0]}
        color={'#ded6be'}
        intensity={intensity}
      />
    </>
  )
}

export default InteriorGLTF
