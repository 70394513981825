import React, { useState } from 'react'
// import { StyleSheet } from 'aphrodite'
import { useBehaviour, useHud, useMaster } from '../stores'
import Hotspot from './Hotspot'
// import HotspotIcon from './icons/HotspotIcon'
import { useEffect } from 'react'
import { useLocation } from 'wouter'
import { LottieHotspot } from '../context/lottie/LottieHotspot'
import { isMobile, isMobileOnly } from 'react-device-detect'

function getCategoryFromLocation(location) {
  if (location.includes('mrg')) {
    return 0
  } else if (location.includes('coatings')) {
    return 1
  } else if (location.includes('inks')) {
    return 2
  }
  // else if (location.includes('niche')) {
  //   return 3
  // }
  else if (location.includes('plastics')) {
    if (location.includes('continua')) {
      return 3
    } else {
      return 3
    }
  } else if (location.includes('tyre')) {
    if (location.includes('continua')) {
      return 2
    } else {
      return 4
    }
  } else if (location.includes('energy')) {
    return 5
  } else if (
    location.includes('rearseat') ||
    location.includes('driverseat') ||
    location.includes('frontpassengerseat')
  ) {
    return 6
  } else return -1
}

export default function HotspotManager(props) {
  const [location, setLocation] = useLocation()
  const { getObject } = useMaster()

  const [currentObject, setObejct] = useState({ hotspots: [] })
  const { setCurrentCategory } = useBehaviour()
  const { data, misc } = useMaster()
  // const [componentTitleId, setComponentTitleId] = useState(null)

  //* Hotspot Audio
  const AudioState = useHud()
  const [hsAudio, setHSAudio] = useState(null)
  let audioSrc = hsAudio != null ? new Audio(hsAudio) : null

  useEffect(() => {
    let cat = getCategoryFromLocation(location)
    setCurrentCategory(cat)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // function getTitleFromID(id) {
  //   let iter = data.components

  //   // console.log(iter === location)
  //   for (var ind in iter) {
  //     if (iter[ind].id === id) {
  //       // console.log(iter[ind].id)
  //       return iter[ind].element
  //     }
  //     // if (iter[ind].id != id) {
  //     //   id = iter[ind].id

  //     //   matched.push(id)

  //     //   for (var i in matched) {
  //     //     if (matched[i] == iter[ind].id) {
  //     //       titleData = iter[ind].element
  //     //       // console.log(titleData)
  //     //       // return iter[ind].element

  //     //       // console.log(iter[ind].element)
  //     //     }
  //     //   }
  //     //   // if (true) {
  //     //   //   return titleData
  //     //   // }

  //     //   console.log(titleData)
  //     //   return titleData
  //     // }
  //   }
  // }

  // function getTitleFromAnnotation(title) {
  //   let iter = data.components
  //   // console.log(iter)
  //   for (var ind in iter) {
  //     console.log(iter[ind].annotation)
  //     if (iter[ind].annotation === title) {
  //       return iter[ind].element
  //       // console.log(iter[ind].element)
  //     }
  //   }
  // }

  function getTitleFromMisc(id) {
    let iter = misc
    // console.log(iter[id].title)
    if (iter[id] == null) return ''
    return iter[id].title
  }

  useEffect(() => {
    setObejct(getObject(location))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  //* Play hostpot audio and on end removed audio object
  useEffect(() => {
    if (hsAudio !== null && audioSrc !== null) {
      audioSrc.play()
      AudioState.setObjAudio(true)
      audioSrc.onended = function () {
        audioSrc.pause()
        AudioState.setObjAudio(false)
      }
    }
  }, [hsAudio])

  //* func gets called on hsClick
  function HotspotClick(locationTarget, audio) {
    if (audio != null) {
      setHSAudio(audio)
    }
    setLocation(locationTarget)
  }

  return (
    <>
      {currentObject.hotspots != null && (
        <>
          {currentObject.hotspots.map((hotspot, index) => {
            // let titlechange = null
            // if (hotspot.titleID != null) {
            //   titlechange = getTitleFromID(hotspot.titleID)
            // }
            // if (hotspot.misc != null) {
            //   titlechange = getTitleFromMisc(hotspot.misc)
            // }
            let titlechange = null
            let iter = data.components

            if (hotspot.titleID != null) {
              if (iter.hasOwnProperty(hotspot.target)) {
                // console.log(iter[hotspot.target].element)
                titlechange = iter[hotspot.target].element
              }
            }
            if (hotspot.misc != null) {
              titlechange = getTitleFromMisc(hotspot.misc)
            }

            // if(hotspot.data.components != null){
            //     titlechange = getTitleFromComponents(hotspot.data.components)
            // }
            return (
              <Hotspot
                key={index}
                position={
                  isMobile
                    ? hotspot.mobilePosition != null
                      ? hotspot.mobilePosition
                      : hotspot.position
                    : hotspot.position
                  // hotspot.position
                }
              >
                <button
                  onClick={() => {
                    HotspotClick(
                      hotspot.target,
                      hotspot.audio !== null ? hotspot.audio : null
                    )
                    // setLocation(hotspot.target)
                  }}
                  style={{
                    outline: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                    background: 'none',
                  }}
                >
                  {/* <Link
                    href={hotspot.target}
                    style={{ pointerEvents: 'auto', background: 'green' }}
                  > */}
                  <LottieHotspot
                    className="lottieHotspot"
                    id={index}
                    title={titlechange != null ? titlechange : hotspot.title}
                    type={hotspot.type != null ? hotspot.type : 'right'}
                    continuaHotspot={hotspot.green != null && hotspot.green}
                    href={hotspot.target}
                  />

                  {/* </Link> */}
                </button>
              </Hotspot>
            )
          })}
        </>
      )}
    </>
  )
}

// const opacityKeyframes = {
//   ' 0%': {
//     opacity: 0,
//   },

//   '100%': {
//     opacity: 1,
//   },
// }

// const sizeKeyFrames = {
//   ' 0%': {
//     transform: 'scale(1)',
//   },

//   '100%': {
//     transform: 'scale(2)',
//   },
// }

// const styles = StyleSheet.create({
//   button: {
//     background: 'none',
//     outline: 'none',
//     border: 'none',
//     pointerEvents: 'auto',
//   },

//   hotspotContainer: {
//     animationDuration: '0.5s, 100ms',
//     //   animationName: [opacityKeyframes],

//     ':hover': {
//       cursor: 'pointer',
//       animationName: [sizeKeyFrames],
//       animationFillMode: 'forwards',
//     },
//   },
// })
