import { useEffect } from 'react'
import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHud } from '../../stores'
import { countryData } from '../../constants'

function WebViewUI({ setWebview, webview }) {
  const CountryData = useHud()
  var ua = navigator.userAgent.toLowerCase()
  var is_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
    navigator.userAgent
  )

  useEffect(() => {
    if (ua.includes('wv') || is_uiwebview) {
      setWebview(true)
    }
  }, [])

  return (
    <>
      {webview && (
        <div className={css(styles.webviewContainer)}>
          <div className={css(styles.logoContainer)}>
            <img
              className={css(styles.logoImage)}
              src="/images/logoImage.jpg"
              alt="Logo"
            />
          </div>
          <div className={css(styles.webviewContent)}>
            {CountryData.country_name != null &&
              countryData[CountryData.country_name].webviewTitle}
          </div>
        </div>
      )}
    </>
  )
}

export default WebViewUI

const styles = StyleSheet.create({
  webviewContainer: {
    position: 'absolute',
    inset: 0,
    backgroundColor: '#ffffff',
    width: '100%',
    height: '100%',
    zIndex: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2.5vh',
    fontFamily: 'Roboto-Regular',
    color: '#4B4B4B',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
    paddingLeft: '2.5vh',
    paddingRight: '2.5vh',
  },
  logoContainer: {
    width: '12vh',
    height: 'auto',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
})
