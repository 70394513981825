import React, { Suspense, useEffect, useState } from 'react'
import { useGLTF } from '@react-three/drei'
import { useCarMaterial, useLoader, useMaster } from '../stores'
import InteriorGLTF from './InteriorGLTF'
import ExteriorGLTF from './ExteriorGLTF'
import PaintGLTF from './PaintGLTF'
import Glass from './Glass'
import { useLocation } from 'wouter'
import TyreGLTF from './TyreGLTF'
import RoofGLTF from './RoofGLTF'
import BrakePadGLTF from './BrakePadGLTF'
import Battery from './Battery'
// import CarDesktop from './CarDesktop'
const url = '/gltfP2LR/emissiveMaps'

export default function ConditionalMeshesLowRes(props) {
  return (
    <Suspense fallback={null}>
      <CarMeshPrimitive {...props} />
    </Suspense>
  )
}
// "extensionsUsed" : [
//     "KHR_materials_clearcoat"
// ],
function CarMeshPrimitive() {
  const interiorGLTF = useGLTF('/gltfP2LR/Interior.gltf')
  const exteriorGLTF = useGLTF('/gltfP2LR/Exterior.gltf')
  const paintGLTF = useGLTF('/gltfP2LR/CarPaint.gltf')
  const glassGLTF = useGLTF('/gltfP2LR/Glass.gltf')
  const tyreGLTF = useGLTF('/gltfP2LR/Tyre.gltf')
  const roofGLTF = useGLTF('/gltfP2LR/Roof.gltf')
  const brakePadGLTF = useGLTF('/gltfP2LR/DiskBreak.gltf')
  const batteryGLTF = useGLTF('/gltfP2V2/Battery.glb')

  const { material } = useCarMaterial()
  const { getObject } = useMaster()
  const { everythingLoaded, setLoadedModel } = useLoader()
  const [currentObject, setObject] = useState()
  const [location] = useLocation()
  const emissiveTexturesInterior = [
    url + '/PUSeatfoam.webp',
    url + '/EntertainmentUnit.webp',
  ]
  const emissiveTexturesExterior = [
    url + '/AntiVibrationBushings.webp',
    url + '/AntiVibrationEngineMounts.webp',
    url + '/CoolantHose.webp',
    url + '/DriveBelt.webp',
    url + '/PneumaticHose.webp',
    url + '/Weatherstrip.webp',
    url + '/Wipers.webp',
    url + '/MRG_All.webp',
    url + '/Niche_All.webp',
    url + '/Windshield.webp',
    url + '/LeadAcidBattery.webp',
    url + '/Plastics_All.webp',
    url + '/AirIntakeCover.webp',
    url + '/RadiatorGrill.webp',
  ]

  const emissiveTexturesTyre = [
    url + '/Rim.webp',
    url + '/Bead.webp',
    url + '/BeadApex.webp',
    url + '/Belt.webp',
    url + '/CapePiles.webp',
    url + '/InnerLiner.webp',
    url + '/RadialPiles.webp',
    '/gltfP2V2/emissiveMaps/fullRubber.webp',
  ]

  useEffect(() => {
    setObject(getObject(location).threed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (!everythingLoaded) {
      setTimeout(() => {
        setLoadedModel('renderStuff')
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    interiorGLTF,
    exteriorGLTF,
    paintGLTF,
    glassGLTF,
    tyreGLTF,
    roofGLTF,
    brakePadGLTF,
  ])

  return (
    <>
      {currentObject != null && (
        <group rotation={[0, Math.PI, 0]} position={[-0.305, 0, 0]}>
          <InteriorGLTF
            {...interiorGLTF}
            light={
              currentObject.InteriorProps != null
                ? currentObject.InteriorProps.light != null
                  ? currentObject.InteriorProps.light
                  : false
                : false
            }
            object={
              currentObject.InteriorProps != null
                ? currentObject.InteriorProps
                : null
            }
            emissiveMapsArray={emissiveTexturesInterior}
          />
          <ExteriorGLTF
            {...exteriorGLTF}
            object={
              currentObject.ExteriorProps != null
                ? currentObject.ExteriorProps
                : null
            }
            emissiveMapsArray={emissiveTexturesExterior}
          />
          <PaintGLTF
            {...paintGLTF}
            carMaterial={material}
            object={
              currentObject.PaintProps != null ? currentObject.PaintProps : null
            }
          />
          <Glass
            {...glassGLTF}
            object={
              currentObject.GlassProps != null ? currentObject.GlassProps : null
            }
          />
          <TyreGLTF
            {...tyreGLTF}
            object={
              currentObject.TyreProps != null ? currentObject.TyreProps : null
            }
            emissiveMapsArray={emissiveTexturesTyre}
          />
          <RoofGLTF
            {...roofGLTF}
            object={
              currentObject.RoofProps != null ? currentObject.RoofProps : null
            }
            carPaintMaterial={paintGLTF.materials}
          />
          <BrakePadGLTF
            {...brakePadGLTF}
            object={
              currentObject.BrakePadProps != null
                ? currentObject.BrakePadProps
                : null
            }
          />
          <Battery
            {...batteryGLTF}
            object={
              currentObject.EnergyProps != null
                ? currentObject.EnergyProps
                : null
            }
          />
        </group>
      )}
    </>
  )
}
