import React from 'react'

export default function NextButtonIcon(props) {
  return (
    <>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 29 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29 17L0.500002 33.4545L0.500004 0.545516L29 17Z"
          fill="#F8F8F8"
        />
      </svg>
    </>
  )
}
