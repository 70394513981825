import React, { useRef, useEffect } from 'react'
import { AllowedLocales, languageIndices } from '../constants'
import { useLoader, useMaster } from '../stores'
import StoreUpdate from './StoreUpdate'

const tryAgain = 3000
const tries = 5
let currentTries = 0

let currentCat = null

function ContentGetter(props) {
  const { getCategories, getItems, getTitles, getMisc, getApiToken } =
    useMaster()

  const selectedLocale = useRef(AllowedLocales[languageIndices.english])

  const { setLoadedModel } = useLoader()

  useEffect(() => {
    // let timer = setTimeout(() => {
    //     getCategories(onGetCategories);
    // }, 1000)

    let timer1 = setTimeout(() => {
      console.log('get api from timer 1')
      getApiToken(onGetApiToken)
    }, 250)

    return function cleanup() {
      if (timer1 != null) clearTimeout(timer1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // function onGetCategories(current) { //!Cached
  //   //console.log(resp)
  //   // if (resp.status == 200) {
  //   // if (resp == 200) {
  //   currentTries = 0
  //   currentCat = current
  //   // loadedFully();
  //   loadItems(current)
  //   // } else {
  //   // retry logic, tries 'tries' number of times and waits for tryAgain ms
  //   //console.log("Categories: trying again in ms: "+tryAgain)
  //   // currentTries++
  //   // if (currentTries < tries) {
  //   //   setTimeout(() => {
  //   //     getCategories(onGetCategories)
  //   //   }, tryAgain)
  //   // } else {
  //   //   Failed()
  //   // }
  //   // }
  // }

  function onGetCategories(resp, current) {
    //console.log(resp)
    if (resp.status === 200) {
      currentTries = 0
      currentCat = current
      // loadedFully();
      loadItems(current)
    } else {
      // retry logic, tries 'tries' number of times and waits for tryAgain ms
      //console.log("Categories: trying again in ms: "+tryAgain)
      currentTries++
      if (currentTries < tries) {
        setTimeout(() => {
          getCategories(onGetCategories)
        }, tryAgain)
      } else {
        Failed()
      }
    }
  }

  function loadItems(current) {
    //console.log("Getting items");
    getItems(onLoadItems, current)
  }

  function onLoadItems(resp) {
    //console.log("Got Items");
    if (resp !== false) {
      currentTries = 0
      loadComponentTitles(onLoadedComponentTitles)
    } else {
      //console.log("Items: trying again in ms: "+tryAgain)
      currentTries++
      if (currentTries < tries) {
        setTimeout(() => {
          loadItems(currentCat)
        }, tryAgain)
      } else {
        Failed()
      }
    }
  }

  function loadComponentTitles() {
    getTitles(loadMisc)
  }

  function onLoadedComponentTitles(resp) {
    if (resp !== false) {
      currentTries = 0
      loadMisc()
    } else {
      //console.log("Titles: trying again in ms: "+tryAgain)
      currentTries++
      if (currentTries < tries) {
        setTimeout(() => {
          loadComponentTitles(onLoadedComponentTitles)
        }, tryAgain)
      } else {
        Failed()
      }
    }
  }

  function loadMisc() {
    getMisc(onLoadedMisc)
  }

  function onLoadedMisc(resp) {
    if (resp === false) {
      currentTries++
      if (currentTries < tries) {
        setTimeout(() => {
          getMisc(onLoadedMisc)
        }, tryAgain)
      } else {
        Failed()
      }
    } else {
      currentTries = 0
      loadedFully()
    }
  }

  function loadedFully() {
    console.log('LOADED fully')
    console.log('All Data Loaded')

    // console.log(useMaster.getState().data)
    // console.log(useMaster.getState().titles)
    // console.log(useMaster.getState().misc)

    if (props.onContentFetched != null) props.onContentFetched()
    setLoadedModel('WebsiteData')
    // console.log("Content Loaded")
  }

  function Failed() {
    window.alert('Could not connect to server')
  }

  function onStoreChange(store) {
    if (store.locale !== selectedLocale.current) {
      selectedLocale.current = store.locale
      getCategories(onGetCategories)
    }
  }

  function onGetApiToken(bool) {
    if (bool) {
      currentTries = 0
      getCategories(onGetCategories)
    } else {
      // retry logic, tries 'tries' number of times and waits for tryAgain ms
      //console.log("Categories: trying again in ms: "+tryAgain)
      currentTries++
      if (currentTries < tries) {
        setTimeout(() => {
          getApiToken(onGetApiToken)
        }, tryAgain)
      } else {
        Failed()
      }
    }
  }

  return (
    <>
      <StoreUpdate onChange={onStoreChange} />
    </>
  )
}

export default ContentGetter
