import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import PrevButtonIcon from '../../components/icons/PrevButtonIcon'
import NextButtonIcon from '../../components/icons/NextButtonIcon'
import { useLocation } from 'wouter'
import { useHud, useMaster, useSFDC } from '../../stores'
import { isMobile, isMobileOnly } from 'react-device-detect'

export default function NextPrevButtonUI() {
  const [location, setLocation] = useLocation()
  const { getObject, misc } = useMaster()
  const AudioState = useHud()
  const { showForm, setShowForm } = useSFDC()

  function changeLocation(button) {
    if (showForm) {
      setShowForm(false)
    }
    if (getObject(location).changeLocation != null) {
      switch (button) {
        case 'previous':
          setLocation(getObject(location).changeLocation.previous)
          break

        case 'next':
          setLocation(getObject(location).changeLocation.next)
          break

        default:
          break
      }
    }
  }

  if (location !== '/' && location !== '/continua') {
    return (
      <>
        {misc != null && (
          <>
            {/* <div className={css(styles.prevContainer)}> */}
            <div className={css(styles.buttonContainer)}>
              <button
                className={css(styles.button)}
                onClick={() => {
                  changeLocation('previous')
                }}
              >
                <PrevButtonIcon
                  width={`${
                    isMobileOnly ? '2.5vw' : isMobile ? '2.5vw' : '5vw'
                  }`}
                  height={`${
                    isMobileOnly ? '6.5vh' : isMobile ? '6.5vh' : '4.5vh'
                  }`}
                />
                <div className={css(styles.text)}>
                  {misc['previous'] != null
                    ? misc['previous'].title
                    : 'Previous'}
                </div>
              </button>
            </div>

            {/* </div> */}
            {/* <div className={css(styles.nextContainer)}> */}
            <div className={css(styles.nextButtonContainer)}>
              <button
                className={css(styles.button)}
                onClick={() => {
                  changeLocation('next')
                }}
              >
                <NextButtonIcon
                  width={`${
                    isMobileOnly ? '2.5vw' : isMobile ? '2.5vw' : '5vw'
                  }`}
                  height={`${
                    isMobileOnly ? '6.5vh' : isMobile ? '6.5vh' : '4.5vh'
                  }`}
                />
                <div className={css(styles.text)}>
                  {misc['next'] != null ? misc['next'].title : 'Next'}
                </div>
              </button>
            </div>

            {/* </div> */}
          </>
        )}
      </>
    )
  } else {
    return null
  }
}

const styles = StyleSheet.create({
  prevContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    bottom: 0,
    width: 150,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'blue',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 80,
      // height: 60,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 80,
        // height: 60,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 80,
        // height: 60,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        width: 100,
        // height: 100,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        width: 120,
        // height: 100,
      },
    // Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      width: 100,
      // height: 100,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      width: 120,
      // height: 100,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      width: 150,
      // height: 120,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      width: 150,
      // height: 120,
    },
    '@media(min-width: 2560px)': {
      width: 150,
      // height: 120,
    },
  },
  buttonContainer: {
    // width: 150,
    // height: 50,
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '1.2vw',
    // backgroundColor: 'black',
    // '@media(max-width: 575.98px) and (orientation:landscape)': {
    //   width: 80,
    //   height: 30,
    // },
    // '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
    //   {
    //     width: 80,
    //     height: 30,
    //   },
    // '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
    //   {
    //     width: 80,
    //     height: 30,
    //   },
    // '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
    //   {
    //     width: 100,
    //     height: 50,
    //   },
    // '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
    //   {
    //     width: 120,
    //     height: 50,
    //   },
    // // Laptops & larger screens
    // '@media(min-width: 992px) and (max-width: 1199.98px)': {
    //   width: 100,
    //   height: 50,
    // },
    // '@media(min-width: 1200px) and (max-width: 1399.98px)': {
    //   width: 120,
    //   height: 50,
    // },
    // '@media(min-width: 1400px) and (max-width: 1599.98px)': {
    //   width: 150,
    //   height: 50,
    // },
    // '@media(min-width: 1600px) and (max-width: 2559.98px)': {
    //   width: 150,
    //   height: 50,
    // },
    // '@media(min-width: 2560px)': {
    //   width: 150,
    // },
  },
  nextButtonContainer: {
    // width: 150,
    // height: 50,
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '1.2vw',
  },
  button: {
    outline: 0,
    border: 0,
    background: 'none',
    // width: 29,
    // height: 34,
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    pointerEvents: 'auto',
    cursor: 'pointer',
    marginLeft: 'auto',
    marginRight: 'auto',
    // color: '#ffffff',
    // marginTop: '50%',
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        // width: 39,
        // height: 44,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        // width: 39,
        // height: 44,
      },
    // Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      // width: 39,
      // height: 44,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      // width: 39,
      // height: 44,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      // width: 39,
      // height: 44,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      // width: 39,
      // height: 44,
    },
    '@media(min-width: 2560px)': {
      // width: 39,
      // height: 44,
    },
  },
  text: {
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 20,
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: `${isMobileOnly ? '2vh' : isMobile ? '0vh' : '1vh'}`,
    // marginLeft: '-1vh',
    // width: 150,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 12,
      // width: 80,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 12,
        // width: 80,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 12,
        // width: 80,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        fontSize: 16,
        // width: 100,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 18,
        // width: 120,
      },
    // Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      fontSize: 16,
      // width: 100,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 18,
      // width: 120,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      fontSize: 20,
      // width: 150,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      fontSize: 20,
      // width: 150,
    },
    '@media(min-width: 2560px)': {
      fontSize: 24,
    },
  },
  nextContainer: {
    position: 'absolute',
    top: '53%',
    right: 0,
    bottom: 0,
    width: 150,
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'blue',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 80,
      // height: 60,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 80,
        // height: 60,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 80,
        // height: 60,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        width: 100,
        // height: 100,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        width: 120,
        // height: 100,
      },
    // Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      width: 100,
      // height: 100,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      width: 120,
      // height: 100,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      width: 150,
      // height: 120,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      width: 150,
      // height: 120,
    },
    '@media(min-width: 2560px)': {
      width: 150,
      // height: 120,
    },
  },
})
