/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { LoopOnce } from 'three'
import { useFrame } from '@react-three/fiber'

export default function Headlights(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF(
    '/glb/conditional/Headlights.glb'
  )
  const { actions } = useAnimations(animations, group)
  const [lightState, setLightState] = useState({
    direction: true,
    isAnimating: false,
  })

  useEffect(() => {
    animateLights(props.animateLight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.animateLight])

  useFrame(() => {
    if (lightState.isAnimating) {
      if (
        actions.Animation.time >= actions.Animation.getClip().duration &&
        lightState.direction
      ) {
        actions.Animation.paused = true
        setLightState({ isAnimating: false, direction: false })
      } else if (!lightState.direction) {
        if (actions.Animation.time <= 0 && !lightState.direction) {
          actions.Animation.paused = true
          setLightState({ isAnimating: false, direction: true })
        }
      }
    }
  })

  function animateLights(state) {
    if (!state) {
      setTimeout(() => {
        actions.Animation.setLoop(LoopOnce)
        actions.Animation.paused = false
        actions.Animation.clampWhenFinished = true
        actions.Animation.timeScale = 1
        actions.Animation.play()
        setLightState({ ...lightState, isAnimating: true })
      }, 2500)
    } else {
      actions.Animation.paused = true
      actions.Animation.setLoop(LoopOnce)
      actions.Animation.paused = false
      actions.Animation.clampWhenFinished = true
      actions.Animation.timeScale = -1
      actions.Animation.play()
      setLightState({ ...lightState, isAnimating: true })
    }
  }

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="light"
          geometry={nodes.light.geometry}
          material={materials.light}
          material-opacity={0.6}
          morphTargetDictionary={nodes.light.morphTargetDictionary}
          morphTargetInfluences={nodes.light.morphTargetInfluences}
        />
      </group>
    </group>
  )
}
