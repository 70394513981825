import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useEnvironment, useHud, useSFDC } from '../../stores'
import { useLocation } from 'wouter'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { useEffect } from 'react'

function ModeDropdown() {
  const { continuaToggle, categoriesClick, current } = useEnvironment()
  const [location, setLocation] = useLocation()
  const Continua = useHud()
  const [mode, setMode] = useState('continua')
  const { showForm, setShowForm } = useSFDC()

  useEffect(() => {
    if (location.includes('/continua')) {
      setTimeout(() => {
        setMode('home')
      }, 500)
    } else {
      setTimeout(() => {
        setMode('continua')
      }, 500)
    }
  }, [location.includes('/continua')])

  function OnContinuaToggle() {
    if (showForm) {
      setShowForm(false)
    }
    setMode('continua')
    continuaToggle()
    setLocation('/continua')
    if (current !== 2) {
      Continua.setContinuaLoading(true)
    } else {
      Continua.setContinuaLoading(false)
    }
  }

  function OnExperienceClick() {
    if (showForm) {
      setShowForm(false)
    }
    setMode('home')
    categoriesClick()
    setLocation('/')
    if (current !== 0 && current !== 1) {
      Continua.setContinuaLoading(true)
    } else {
      Continua.setContinuaLoading(false)
    }
  }

  return (
    <div
      // className={css(isMobileOnly ? styles.modeMobPos : styles.modeContainer)}
      className={css(styles.modeMobPos)}
    >
      {mode === 'home' && (
        <button
          className={css(
            styles.button,
            styles.customMargin,
            styles.customStyle
          )}
          onClick={() => {
            OnExperienceClick()
          }}
          style={{
            pointerEvents: 'auto',
            cursor: 'pointer',
            padding: `${isMobileOnly ? '1.1vh 1.5vh' : '0.5vh 1.2vh'}`,
          }}
        >
          <span style={{ marginTop: '0.1vh' }}>HOME</span>
        </button>
      )}
      {mode === 'continua' && isMobileOnly && (
        <button
          className={css(
            styles.button,
            styles.buttonMargin,
            styles.customMargin,
            location.includes('/continua')
              ? styles.activeButton
              : styles.deactiveButton
          )}
          style={{ pointerEvents: 'auto', cursor: 'pointer' }}
          onClick={() => {
            OnContinuaToggle()
          }}
        >
          <div
            style={{
              width: `${isMobile ? '5.5vw' : '5vw'}`,
              height: 'auto',
              // marginTop: '0.1vh',
            }}
          >
            <img
              src="/images/continua_logo.png"
              alt="Continua_logo"
              className={css(styles.image01)}
            />
          </div>
        </button>
      )}
    </div>
  )
}

export default ModeDropdown

const styles = StyleSheet.create({
  customStyle: {
    fontSize: `${isMobile ? '1.1vw' : '0.95vw'}`,
    color: '#ffffff',
    // padding: `1.1vh 1.8vh`,
    border: '0.25vh solid rgba(255,255,255,1)',
    borderRadius: '3vh',
  },
  customMargin: {
    marginTop: `${isMobileOnly ? '4vh' : isMobile ? '40px' : '42px'}`,
    // '@media(max-width: 575.98px) and (orientation:portrait)': {
    //   display: 'none',
    // },
    // '@media(max-width: 575.98px) and (orientation:landscape)': {
    //   display: 'none',
    // },
    // '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
    //   {
    //     display: 'none',
    //   },
    // '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
    //   {
    //     display: 'none',
    //   },
    // '@media(min-width: 768px) and (max-width: 991.98px)': {
    //   fontSize: 12,
    //   width: 80,
    // },
    // '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
    //   {
    //     fontSize: 14,
    //     marginTop: 50,
    //   },
    // '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
    //   {
    //     fontSize: 15,
    //     marginTop: 50,
    //   },
    // '@media(min-width: 1400px)': {
    //   marginTop: 35,
    // },
  },
  buttonMargin: {
    // marginLeft: '8vh',
    padding: `${
      isMobileOnly ? '1.1vh 1.2vh' : isMobile ? '1.1vh 1.2vh' : '0.5vh 1.2vh'
    }`,
    border: '0.25vh solid rgba(255,255,255,1)',
    borderRadius: '3vh',
  },
  activeButton: {
    // borderBottom: '0.2vh solid rgba(255,255,255,1)',
  },
  deactiveButton: {
    // borderBottom: 'none',
  },
  arrowUp: {
    width: 0,
    height: 0,
    borderLeft: '2vw solid transparent',
    borderRight: '2vw solid transparent',
    borderBottom: '2.5vw solid white',
    position: 'absolute',
    marginLeft: '0.9%',
    marginTop: '1vh',
  },
  modeMobPos: {
    position: 'absolute',
    left: `${isMobileOnly ? '16vw' : '13vw'}`,
  },
  modeContainer: {
    marginRight: `${isMobile ? '2vh' : '2vh'}`,
    // padding: '4.4vh',
    // justifyContent: '',
    // alignItems: 'center',
    // background: 'blue',
  },
  button: {
    background: 'none',
    height: 'auto',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',

    // color: 'white',
    // fontSize: `${isMobile ? '1.3vw' : '1vw'}`,
    // pointerEvents: 'auto',
    // padding: 0,
    margin: 0,
    // ':hover': {
    //   cursor: 'pointer',
    // },
  },
  downArrow: {
    marginLeft: '0.5vw',
    width: 12,
    height: 7,
    '@media(min-width: 2560px)': {
      width: 22,
      height: 17,
    },
  },
  dropdownContainer: {
    position: 'relative',
    marginTop: '1.5vw',
    padding: '2vh',
    width: '100%',
    background: 'white',
    borderRadius: '1vh',
  },
  dropdownButton: {
    width: '100%',
    height: 'auto',
    background: 'none',
    outline: 'none',
    border: 'none',
    fontSize: `${isMobile ? '1.3vw' : '1vw'}`,
    textAlign: 'left',
    pointerEvents: 'auto',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  image01: {
    width: '100%',
    height: 'auto',
  },
})
