import React from 'react'

export default function PrevButtonIcon(props) {
  return (
    <>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 29 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.26573e-07 17L28.5 0.545513L28.5 33.4545L2.26573e-07 17Z"
          fill="white"
        />
      </svg>
    </>
  )
}
