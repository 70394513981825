import { useEffect } from 'react'
import { useEnvironment, useMaster } from '../stores'

function EnvironmentState({ onChange }) {
  useEffect(() => {
    const unsub = useEnvironment.subscribe((data) => onChange(data.current))

    return function cleanup() {
      unsub()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useMaster])

  return null
}

export default EnvironmentState
