import React, { useEffect, useRef } from 'react'
// import Lottie from 'react-lottie'
import Lottie from 'lottie-react'
import animationDataRight from './right.json'
import animationDataLeft from './left.json'
import continuaAnimationDataRight from './continua_right.json'
import continuaAnimationDataLeft from './continua_left.json'
import { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { fadeIn } from 'react-animations'
import useLocation from 'wouter/use-location'
import { isMobile } from 'react-device-detect'

export function LottieHotspot({
  type = 'right',
  title = 'sample title here',
  href,
  continuaHotspot,
}) {
  const [, setLocation] = useLocation()
  // const [prevLocation, setPrevLocation] = useState(null)
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: continuaHotspot
      ? continuaAnimationDataLeft
      : animationDataLeft,
    rendererSettings: {
      preserveAspectRatio: 'xMaxYMax slice',
    },
  }

  const defaultOptionsRight = {
    loop: false,
    autoplay: false,
    animationData: continuaHotspot
      ? continuaAnimationDataRight
      : animationDataRight,
    rendererSettings: {
      preserveAspectRatio: 'xMinYMin slice',
    },
  }

  const [touch, setTouch] = useState(false)

  const lottie = useRef()

  const [hover, setHover] = useState(false)

  function onAnyTouch() {
    if (hover && touch) {
      onHoverExit()
    }
  }

  useEffect(() => {
    document.addEventListener('touchstart', onAnyTouch)
    return () => {
      document.removeEventListener('touchstart', onAnyTouch)
    }
  }, [])

  function onHoverEnter() {
    setHover(true)
    lottie.current.play()
    lottie.current.setSpeed(4)
    lottie.current.setDirection(1)
  }

  function onHoverExit() {
    setHover(false)
    lottie.current.play()
    lottie.current.setSpeed(4)
    lottie.current.setDirection(-1)
  }

  function onClick() {
    // desktop only
    if (!isMobile && !touch) {
      if (href != null) {
        setLocation(href)
        // audio = new Audio('/audio/hotspot_click.wav')
        // audio.load()
        // audio.pause()
        // audio.play()
      }
    }
  }

  function onTouch() {
    if (touch) {
      setLocation(href)
      setTouch(false)
    } else {
      onHoverEnter()
      setTouch(true)
      // setPrevLocation(href)
    }

    // if(prevLocation === href){
    //     setPrevLocation(null)
    //     setHover(false)
    //     lottie.current.play();
    //     setLocation(href);
    // }
    // else{
    //     setPrevLocation(href)
    //     setHover(true)
    //     lottie.current.play();
    // }
  }
  // useEffect event listener for touch

  // function onAnyTouch
  // if ( hover && touch ) on Hover Exit
  // useEffect(() => {
  //   if (hover) {
  //     lottie.current.setDirection(1)
  //   } else {
  //     lottie.current.setDirection(-1)
  //   }
  // }, [hover, lottie.current])
  // const onAnimDirection = () => {
  //   console.log('mobile night')

  // }

  return (
    <>
      {type === 'right' && (
        <div
          className={css(
            styles.lottieWidth,
            hover ? styles.lottieanimation : null,
            isMobile ? styles.mobileHotspots : null
          )}
        >
          <Lottie
            lottieRef={lottie}
            // onComplete={onAnimComplete}
            speed={4}
            animationData={
              continuaHotspot ? continuaAnimationDataRight : animationDataRight
            }
            onMouseEnter={!isMobile ? onHoverEnter : null}
            onMouseLeave={!isMobile ? onHoverExit : null}
            loop={false}
            autoplay={false}
            style={{ pointerEvents: 'none' }}
          />

          {/* <div
            className={css(
              styles.lottieTextRight,
              title.length < 20 ? styles.textRightShort : styles.textRightLong,
              hover ? styles.fadeInAnimation : styles.fadeOutAnimation
            )}
          >
            {title}
          </div> */}
        </div>
      )}

      {type === 'left' && (
        <div
          className={css(
            styles.lottieWidthLeft,
            hover ? styles.lottieanimationLeft : null,
            isMobile ? styles.mobileHotspots : null
          )}
        >
          <Lottie
            lottieRef={lottie}
            // onComplete={onAnimComplete}
            animationData={
              continuaHotspot ? continuaAnimationDataLeft : animationDataLeft
            }
            onMouseEnter={!isMobile ? onHoverEnter : null}
            onMouseLeave={!isMobile ? onHoverExit : null}
            loop={false}
            autoplay={false}
            style={{ pointerEvents: 'none' }}
          />
          {/* <Lottie
            onMouseEnter={!isMobile ? onHoverEnter : null}
            onMouseLeave={!isMobile ? onHoverExit : null}
            style={{ pointerEvents: 'none' }}
            direction={hover === true ? 1 : -1}
            ref={lottie}
            speed={4}
            options={defaultOptions}
            eventListeners={[
              {
                eventName: 'complete',
                callback: (obj) => {},
              },
            ]}
          /> */}
          {/* <div className={css(styles.lottieText, styles.textLeft, hover ? styles.fadeInAnimation : styles.fadeOutAnimation)}>
                    {title}
                </div> */}
        </div>
      )}

      {type === 'right' && (
        <div
          className={css(
            styles.lottieTextRight,
            styles.textRightLong,
            hover ? styles.fadeInAnimation : styles.fadeOutAnimation
          )}
        >
          {title != null && title}
        </div>
      )
      // title.length < 20 ? styles.textRightShort :
      }
      {type === 'left' && (
        <div
          className={css(
            styles.lottieText,
            styles.textLeft,
            hover ? styles.fadeInAnimation : styles.fadeOutAnimation
          )}
        >
          {title != null && title}
        </div>
      )}
      <div
        className={css(
          type === 'left'
            ? styles.colliderLeft
            : isMobile
            ? styles.colliderRightMobile
            : styles.colliderRight
        )}
        onMouseOver={!isMobile ? onHoverEnter : null}
        onMouseOut={!isMobile ? onHoverExit : null}
        onClick={onClick}
        onTouchEnd={onTouch}
      />
    </>
  )
}

const styles = StyleSheet.create({
  colliderRight: {
    top: 10,
    height: 35,
    width: 32,
    left: -6,
    position: 'absolute',
    zIndex: 100,
  },
  colliderRightMobile: {
    // backgroundColor: 'black',
    // top: 15,
    // height: 25,
    // width: 20,
    // padding: '8px',
    // position: 'absolute',
    // zIndex: 100,

    top: '16px',
    padding: '13px 36px',
    position: 'absolute',
    transform: 'scale(0.8)',
    zIndex: '100',
  },
  colliderLeft: {
    position: 'absolute',
    height: 35,
    width: 35,
    top: 35,
    right: 10,
  },
  lottieTextRight: {
    position: 'absolute',
    // top:0,
    textAlign: 'left',
    color: '#FFFFFF',
    fontSize: 22,
    backgroundColor: '#212121DF',
    fontFamily: 'Roboto',
    pointerEvents: 'none',
    // backgroundColor:'yellow',
    // marginRight:0,
    width: 512 / 2.7,
  },
  lottieText: {
    position: 'absolute',
    color: '#FFFFFF',
    fontSize: 22,
    backgroundColor: '#212121DF',
    fontFamily: 'Roboto',
    pointerEvents: 'none',
    width: 512 / 2.7,
  },
  textLeft: {
    textAlign: 'left',
    top: 37,
    right: 50,
  },
  textRightShort: {
    textAlign: 'left',
    top: 37,
    right: 0,
  },
  textRightLong: {
    textAlign: 'right',
    top: 37,
    left: 60,
  },
  fadeInAnimation: {
    animationName: [fadeIn],
    animationFillMode: 'forwards',
    animationDuration: '0.5s,100ms',
    // animationDelay: '2s',
  },
  fadeOutAnimation: {
    opacity: 0,
    // animationName:[fadeOut],
    // animationFillMode:'forwards',
    // animationDuration:'0.5s,100ms',
  },
  //style={{height:210/2, width:512/2}}
  lottieWidth: {
    position: 'absolute',
    top: -20,
    left: -20,
    height: 210 / 2,
    width: 512 / 1.9,
    pointerEvents: 'none',
    // '-webkit-transition': 'width 0.5s',
    // backgroundColor: 'red',
  },
  lottieWidthLeft: {
    height: 210 / 2,
    width: 512 / 1.9,
    position: 'absolute',
    top: 0,
    right: 0,
    '-webkit-transition': 'width 0.5s',
    // backgroundColor:'red'
  },
  lottieanimationLeft: {
    width: 512 / 1.9,
  },
  lottieanimation: {
    width: 512 / 1.9,
  },
  mobileHotspots: {
    transform: 'scale(0.8)',
  },
})
