import React, { useEffect, useRef, useState } from 'react'

import { useHud } from '../stores'
import AudioButton from './ui/AudioButton'

function AudioManager(props) {
  // const audioContext = new AudioContext()
  const audio = useRef(null)
  const [visibility, setVisibility] = useState(true)
  const [audioState, setAudioState] = useState('paused')
  const [clicked, setClicked] = useState(false)

  const AudioState = useHud()

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()

    return function cleanup() {
      window.removeEventListener('resize', onResize)
    }
  }, [window])

  function onResize() {
    if (window.innerWidth < window.innerHeight) {
      setAudioState('paused')
    } else {
      if (audioState === 'playing') {
        setAudioState('playing')
      } else {
        setAudioState('paused')
      }
    }
  }

  const handleClickOutside = () => {
    setClicked(true)
  }
  useEffect(() => {
    if (clicked) {
      setAudioState('playing')
    }
  }, [clicked])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibility, false)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibility, false)
    }
  }, [visibility])

  //* function calls when user interact anywhere on current page. get calls only first time.

  useEffect(() => {
    if (audio.current != null) {
      if (audioState === 'playing') {
        audio.current.play()
        if (AudioState.objAudio) {
          audio.current.volume = 0.1
        } else {
          audio.current.volume = 0.5
        }
      } else {
        audio.current.pause()
      }
    }
  }, [audioState, AudioState.objAudio])

  //* function calls when current tab is not focused
  function handleVisibility() {
    setVisibility(false)
  }

  useEffect(() => {
    if (!visibility) {
      setAudioState('paused')
    }
  }, [visibility])

  return (
    <>
      <audio id="bgAudio" ref={audio} loop>
        <source src="/audio/bg.wav" type="audio/wav"></source>
      </audio>
      <AudioButton audioState={audioState} setAudioState={setAudioState} />
      <div
        style={{
          position: 'absolute',
          inset: 0,
          width: '100%',
          height: '100%',
          pointerEvents: `none`,
          display: `${clicked ? 'none' : 'block'}`,
          // background: 'red',
        }}
      ></div>
    </>
  )
}

export default AudioManager
