import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTexture } from '@react-three/drei'
import { MathUtils } from 'three'
import { Color } from 'three'
import { useFrame } from '@react-three/fiber'
import { isMobile, isMobileOnly } from 'react-device-detect'

// const url = '/gltfP2V2/emissiveMaps'
let flipper = 0

function ExteriorGLTF(props) {
  const emissiveTextures = useTexture(
    isMobileOnly ? [] : props.emissiveMapsArray
  )

  const [currentSelected, setCurrentSelected] = useState(-1)

  useEffect(() => {
    if (props.object != null) {
      console.log(props.object)
      if (props.object.highlight != null) {
        if (props.object.highlight.index !== currentSelected) {
          let nom = props.object.highlight.index
          if (nom !== -1) {
            if (!isMobileOnly) {
              props.materials.Exterior.emissiveMap = emissiveTextures[nom]
              console.log('hight color: ' + props.object.highlight.color)
              props.materials.Exterior.emissive = new Color(
                props.object.highlight.color
              )
              props.materials.Exterior.needsUpdate = true
            }
          }
          setCurrentSelected(props.object.highlight.index)
        }
      }
      setCurrentSelected(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.object, currentSelected])

  function handleHighlightWithPulse(delta) {
    if (props.object != null) {
      if (props.object.highlight != null) {
        if (
          props.object.highlight.pulse != null &&
          props.object.highlight.pulse
        ) {
          flipper += delta * 5
          if (flipper < 5) {
            props.materials.Exterior.emissiveIntensity = MathUtils.lerp(
              props.materials.Exterior.emissiveIntensity,
              1,
              delta * 2
            )
          } else {
            props.materials.Exterior.emissiveIntensity = MathUtils.lerp(
              props.materials.Exterior.emissiveIntensity,
              0,
              delta * 2
            )

            if (flipper > 20) flipper = 0
          }
        } else {
          props.materials.Exterior.emissiveIntensity = MathUtils.lerp(
            props.materials.Exterior.emissiveIntensity,
            1,
            delta * 3
          )
        }
      } else {
        // let col = new Color('#000000')
        props.materials.Exterior.emissiveIntensity = MathUtils.lerp(
          props.materials.Exterior.emissiveIntensity,
          0,
          delta * 3
        )
      }
    } else {
      if (props.materials.Exterior.emissiveIntensity !== 0) {
        props.materials.Exterior.emissiveIntensity = MathUtils.lerp(
          props.materials.Exterior.emissiveIntensity,
          0,
          delta * 3
        )
      }
    }
  }

  useFrame((state, delta) => {
    if (!isMobileOnly) {
      handleHighlightWithPulse(delta)
    }
  })

  return <primitive object={props.scene} dispose={null} />
}

export default ExteriorGLTF
