import React, { useEffect, useRef, useState } from 'react'
import Tween from './Tween'
import { useHud, useLoader, useTween } from '../stores'
import { useFrame, useThree } from '@react-three/fiber'
import { isMobile } from 'react-device-detect'

export default function TweenManger(props) {
  const tweenPoint = useTween((state) => state.activeTweenPoint)

  const { invalidate } = useThree()

  const {
    tweenDelay,
    tweenDuration,
    controlDuration,
    tweenInterpolation,
    tweenFinished,
  } = useTween()
  const { everythingLoaded } = useLoader()

  const [isTweenActive, setActive] = useState(useTween.getState().tweenActive)

  const { setHideHotspots } = useHud()

  const frameLoopRef = useRef(false)

  function onTweenChange(state) {
    if (state) {
      onTweenStart()
    } else {
      onTweenEnd(useTween.getState().tweenName)
    }
  }

  function onTweenStart() {
    frameLoopRef.current = true
    setHideHotspots(true)
    setTimeout(() => {
      setActive(true)
    }, 500)
    // something more
  }

  function onTweenEnd(name) {
    setActive(false)
    frameLoopRef.current = false
    if (everythingLoaded) {
      setHideHotspots(false)
    }
    // something more
  }

  useEffect(() => {
    const unsub = useTween.subscribe((data, oldData) => {
      if (oldData != data) onTweenChange(data.tweenActive)
    })
    return () => {
      unsub()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useTween, everythingLoaded])

  useFrame(() => {
    props.cameraUpdate()
    if (frameLoopRef.current) {
      if (isMobile) invalidate()
    }
  })

  return (
    <>
      {isTweenActive && (
        <Tween
          tweenPoint={tweenPoint}
          controls={props.control}
          tweenDuration={tweenDuration}
          controlDuration={controlDuration}
          tweenInterpolation={tweenInterpolation}
          tweenDelay={tweenDelay}
          tweenFinished={tweenFinished}
        />
      )}
    </>
  )
}
