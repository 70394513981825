import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { isMobileOnly } from 'react-device-detect'

function AudioButton({ audioState, setAudioState }) {
  function onAudioButtonClick() {
    if (audioState === 'playing') {
      setAudioState('paused')
    } else {
      setAudioState('playing')
    }
  }
  return (
    <div
      className={css(
        isMobileOnly ? styles.mobileAudioButton : styles.audioButton
      )}
    >
      <button
        className={css(styles.button)}
        onClick={() => onAudioButtonClick()}
      >
        {audioState === 'playing' ? (
          <svg
            width={'3.3vw'}
            height={'3.3vw'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.21976 19.8582H10.5468C10.7174 19.8582 10.8733 19.9257 10.9877 20.0348L18.0236 26.2319V5.78751L10.9685 12.0015C10.8471 12.1082 10.6967 12.1604 10.5476 12.1604V12.1611H5.22056V19.8589L5.21976 19.8582ZM21.7564 22.1837C21.5077 21.935 21.5077 21.5302 21.7564 21.2814C22.0052 21.0327 22.41 21.0327 22.6588 21.2814L26.7698 25.3925C27.0186 25.6413 27.0186 26.0461 26.7698 26.2948C26.5211 26.5436 26.1163 26.5436 25.8675 26.2948L21.7564 22.1837ZM22.6588 10.7373C22.41 10.9861 22.0052 10.9861 21.7564 10.7373C21.5077 10.4886 21.5077 10.0838 21.7564 9.83504L25.8675 5.72395C26.1163 5.4752 26.5211 5.4752 26.7698 5.72395C27.0186 5.9727 27.0186 6.37753 26.7698 6.62626L22.6588 10.7373ZM22.2071 16.6473C21.8546 16.6473 21.5689 16.3616 21.5689 16.0091C21.5689 15.6566 21.8546 15.3709 22.2071 15.3709H27.4195C27.772 15.3709 28.0577 15.6566 28.0577 16.0091C28.0577 16.3616 27.772 16.6473 27.4195 16.6473H22.2071ZM10.3059 21.1348H4.58109C4.22859 21.1348 3.94287 20.8491 3.94287 20.4966V11.5224C3.94287 11.1699 4.22859 10.8842 4.58109 10.8842H10.3059L18.2195 3.91367C18.334 3.80385 18.4898 3.73706 18.6604 3.73706C19.0129 3.73706 19.2986 4.02277 19.2986 4.37528V27.6443H19.2979C19.2979 27.7941 19.2457 27.9447 19.139 28.0652C18.9063 28.3293 18.5031 28.3556 18.2388 28.1229L10.3051 21.1354L10.3059 21.1348Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width={isMobileOnly ? '3.5vw' : '3.3vw'}
            height={isMobileOnly ? '3.5vw' : '3.3vw'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.7587 6.6499C16.5419 6.54186 16.2821 6.56543 16.088 6.71186L9.06857 12.0088L5.81358 12.0086C5.19785 12.0086 4.69714 12.5097 4.69714 13.1252V18.8445C4.69714 19.4599 5.19785 19.9606 5.81358 19.9606H9.06857L16.088 25.2571C16.2012 25.3426 16.3371 25.3863 16.4735 25.3863C16.5709 25.3863 16.6685 25.364 16.7587 25.319C16.9764 25.2108 17.1135 24.989 17.1135 24.7464V7.22224C17.1135 6.97956 16.9762 6.75778 16.7587 6.64958L16.7587 6.6499ZM15.8336 23.4622L9.66853 18.8104C9.55745 18.7264 9.42192 18.6811 9.28299 18.6811H5.97712V13.2891H9.28317C9.42246 13.2891 9.55764 13.2437 9.66871 13.1598L15.8337 8.50744L15.8336 23.4622ZM27.1364 19.548L23.5728 15.9844L27.1359 12.4214C27.386 12.1712 27.386 11.766 27.1359 11.5164C26.8857 11.2662 26.4807 11.2662 26.2309 11.5164L22.6678 15.0796L19.1048 11.5165C18.8546 11.2664 18.4496 11.2664 18.1998 11.5165C17.95 11.7667 17.9496 12.1717 18.1998 12.4216L21.7628 15.9848L18.2 19.548C17.9498 19.7981 17.9498 20.2033 18.2 20.453C18.325 20.578 18.4887 20.6403 18.6526 20.6403C18.8166 20.6403 18.9801 20.5778 19.1053 20.453L22.6683 16.8898L26.2319 20.453C26.3569 20.578 26.5206 20.6403 26.6846 20.6403C26.8485 20.6403 27.0121 20.5778 27.1372 20.453C27.3864 20.2031 27.3864 19.7981 27.1364 19.548H27.1364Z"
              fill="white"
            />
          </svg>
        )}
      </button>
    </div>
  )
}

export default AudioButton

const styles = StyleSheet.create({
  mobileAudioButton: {
    position: 'absolute',
    left: '10vw',
    top: '3vh',
    color: '#ffffff',
    fontSize: '2vh',
  },
  audioButton: {
    position: 'absolute',
    left: '4vh',
    bottom: '1.8vh',
    color: '#ffffff',
    fontSize: '2vh',
  },
  button: {
    background: 'none',
    border: 'none',
    outline: 'none',
    margin: 0,
    padding: 0,
    height: 'auto',
    width: 'auto',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
  },
})
