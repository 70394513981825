import { useSpring } from '@react-spring/core'
import { a as animated } from '@react-spring/web'
import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { CoatingMessage } from '../../constants'
import { useCarMaterial, useMaster } from '../../stores'

// function MapRange(value){
//     const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;
// }

let overrideUndertone = false
let overrideJetness = false
let jetnessLow = false
let undertoneLow = false

export default function CoatingSliderScreen({ presetChange, sliderValues }) {
  const [actives, setActives] = useState(['low', 'high', 'high'])
  const { misc } = useMaster()
  const [colorState, setColorState] = useState('blue')
  const { setUndertone, setJetness } = useCarMaterial()

  const animatedSpring = useSpring({
    s1: overrideJetness ? (jetnessLow ? '12%' : '91%') : sliderValues.s1,
    s2: overrideUndertone ? (undertoneLow ? '12%' : '91%') : sliderValues.s2,
    s3: sliderValues.s3,
    s4: sliderValues.s4,
  })

  function onClick(n) {
    let te = actives
    if (n < 6) {
      overrideUndertone = false
      overrideJetness = false
      jetnessLow = false
      undertoneLow = false
    }
    switch (n) {
      case 0:
        setActives(['low', te[1], te[2]])
        break

      case 1:
        setActives(['high', te[1], te[2]])
        break

      case 2:
        setActives([te[0], 'low', te[2]])
        break

      case 3:
        setActives([te[0], 'high', te[2]])
        break

      case 4:
        setActives([te[0], te[1], 'low'])
        break

      case 5:
        setActives([te[0], te[1], 'high'])
        break
      case 6:
        jetnessLow = true
        overrideJetness = true
        setJetness('#1A1A1A')
        break
      case 7:
        overrideJetness = true
        jetnessLow = false
        setJetness('#0A0A0A')
        break
      case 8:
        overrideUndertone = true
        undertoneLow = true
        setUndertone('#020100')
        setColorState('#7f583a')
        break
      case 9:
        overrideUndertone = true
        undertoneLow = false
        setUndertone('#000003')
        setColorState('#0088f7')
        break
      default:
    }
  }

  useEffect(() => {
    presetChange(actives)
    //console.log("Use Effect Coating Slider")
    // console.log(sliderValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actives])

  useEffect(() => {
    var value = sliderValues.s2
    var finalValue = value.replace('%', '')
    if (finalValue <= 34) {
      setColorState('#7f583a')
    } else if (finalValue > 67.33) {
      setColorState('#0088f7')
    } else {
      setColorState('#ffffff')
    }
  }, [sliderValues.s2])

  useEffect(() => {
    console.log(misc['carpaint'].title)
  }, [])

  return (
    <>
      {misc != null && (
        <>
          <div className={css(styles.sliderContainer)}>
            <div className={css(styles.row)}>
              <div className={css(styles.title)}>
                {misc['carpaint'] != null && misc['carpaint'].title}
              </div>
            </div>
            <div className={css(styles.row1)}>
              <div className={css(styles.row2)}>
                <div className={css(styles.column)}>
                  {/* <div className={css(styles.row3)}>
                            <div className={css(styles.indicator)}>{misc['low'].title}</div>
                            <div className={css(styles.indicator)}>{misc['high'].title}</div>
                        </div> */}
                  <div className={css(styles.row3)}>
                    <label className={css(styles.label)}>
                      {misc['jetness'] != null && misc['jetness'].title}
                    </label>
                    <div>
                      <div className={css(styles.progress)}>
                        <animated.div
                          className={css(styles.progress01)}
                          style={{ width: animatedSpring.s1 }}
                        />
                      </div>
                      <div className={css(styles.buttonContainer1)}>
                        <button
                          onClick={() => onClick(6)}
                          className={css(
                            jetnessLow
                              ? styles.buttonSelected
                              : styles.buttonUnselected,
                            styles.button
                          )}
                        >
                          {misc['low'] != null && misc['low'].title}
                        </button>
                        <button
                          onClick={() => onClick(7)}
                          className={css(
                            jetnessLow
                              ? styles.buttonUnselected
                              : styles.buttonSelected,
                            styles.button
                          )}
                        >
                          {misc['high'] != null && misc['high'].title}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className={css(styles.row3)}>
                    <label className={css(styles.label)}>
                      {misc['undertone'] != null && misc['undertone'].title}
                    </label>
                    <div>
                      <div className={css(styles.progress)}>
                        <animated.div
                          className={css(styles.undertoneProgress)}
                          style={{
                            width: animatedSpring.s2,
                            backgroundColor: colorState,
                          }}
                        />
                      </div>
                      <div className={css(styles.buttonContainer1)}>
                        <button
                          onClick={() => onClick(8)}
                          className={css(
                            undertoneLow
                              ? styles.buttonSelected
                              : styles.buttonUnselected,
                            styles.button
                          )}
                        >
                          {misc['low'] != null && misc['low'].title}
                        </button>
                        <button
                          onClick={() => onClick(9)}
                          className={css(
                            undertoneLow
                              ? styles.buttonUnselected
                              : styles.buttonSelected,
                            styles.button
                          )}
                        >
                          {misc['high'] != null && misc['high'].title}
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* <div className={css(styles.row3)}>
                            <label className={css(styles.label)}>{misc['viscocity'].title}</label>
                            <div className={css(styles.progress)}>
                                <animated.div className={css(styles.progress01)} style={{width: animatedSpring.s3}} />
                            </div>
                        </div>
                        <div className={css(styles.row3)}>
                            <label className={css(styles.label)}>{misc['gloss'].title}</label>
                            <div className={css(styles.progress)}>
                                <animated.div className={css(styles.progress01)} style={{width: animatedSpring.s4}} />
                            </div> 
                        </div> */}
                </div>
                <div className={css(styles.column)}>
                  <div className={css(styles.toggleRow)}>
                    <div
                      className={css(
                        styles.toggleColumn,
                        styles.toggleColumnBorder
                      )}
                    >
                      <div className={css(styles.label, styles.toggleTitle)}>
                        {misc['stsa'] != null && misc['stsa'].title}
                      </div>
                      <div className={css(styles.buttonContainer)}>
                        <button
                          onClick={() => onClick(0)}
                          className={css(
                            actives[0] === 'low'
                              ? styles.buttonSelected
                              : styles.buttonUnselected,
                            styles.button
                          )}
                        >
                          {misc['low'] != null && misc['low'].title}
                        </button>
                        <button
                          onClick={() => onClick(1)}
                          className={css(
                            actives[0] === 'high'
                              ? styles.buttonSelected
                              : styles.buttonUnselected,
                            styles.button
                          )}
                        >
                          {misc['high'] != null && misc['high'].title}
                        </button>
                      </div>
                    </div>
                    <div
                      className={css(
                        styles.toggleColumn,
                        styles.toggleColumnBorder
                      )}
                    >
                      <div className={css(styles.label, styles.toggleTitle)}>
                        {misc['oan'] != null && misc['oan'].title}
                      </div>
                      <div className={css(styles.buttonContainer)}>
                        <button
                          onClick={() => onClick(2)}
                          className={css(
                            actives[1] === 'low'
                              ? styles.buttonSelected
                              : styles.buttonUnselected,
                            styles.button
                          )}
                        >
                          {misc['low'] != null && misc['low'].title}
                        </button>
                        <button
                          onClick={() => onClick(3)}
                          className={css(
                            actives[1] === 'high'
                              ? styles.buttonSelected
                              : styles.buttonUnselected,
                            styles.button
                          )}
                        >
                          {misc['high'] != null && misc['high'].title}
                        </button>
                      </div>
                    </div>
                    <div className={css(styles.toggleColumn)}>
                      <div className={css(styles.label, styles.toggleTitle)}>
                        {misc['dispersion'] != null && misc['dispersion'].title}
                      </div>
                      <div className={css(styles.buttonContainer)}>
                        <button
                          onClick={() => onClick(4)}
                          className={css(
                            actives[2] === 'low'
                              ? styles.buttonSelected
                              : styles.buttonUnselected,
                            styles.button
                          )}
                        >
                          {misc['low'] != null && misc['low'].title}
                        </button>
                        <button
                          onClick={() => onClick(5)}
                          className={css(
                            actives[2] === 'high'
                              ? styles.buttonSelected
                              : styles.buttonUnselected,
                            styles.button
                          )}
                        >
                          {misc['high'] != null && misc['high'].title}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={css(styles.toggleRow, styles.infoText)}>
                    {misc['coatingchange'] != null &&
                      misc['coatingchange'].title}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
// const progressAnimation = {
//   '0%': {
//     width: 0,
//   },
// }

const styles = StyleSheet.create({
  row: {
    position: 'absolute',
    top: `${isMobileOnly ? '10vh' : '12vh'}`,
    right: 0,
    // backgroundColor: 'blue'
  },
  toggleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: '2vh',
    // backgroundColor: 'red'
  },
  infoText: {
    lineHeight: '19px',
    fontSize: '2.8vh',
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    color: '#ffffff',
    marginBottom: 0,
    textAlign: 'center',
  },
  title: {
    color: '#ffffff',
    fontSize: 50,
    fontFamily: 'Oxygen-Regular',
    fontWeight: 'normal',
    lineHeight: '63px',
    textAlign: 'center',
    // width: 652,
    marginTop: 15,
    marginRight: 20,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 20,
      marginTop: 0,
      marginRight: 10,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 20,
        marginTop: 0,
        marginRight: 10,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 20,
        marginTop: 0,
        marginRight: 10,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        fontSize: 30,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 45,
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      fontSize: 30,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 45,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      fontSize: 40,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      fontSize: 45,
    },
    '@media(min-width: 2560px)': {
      fontSize: 45,
    },
  },
  row1: {
    display: 'flex',
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    height: 240,
    // backgroundColor: 'black',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      height: 120,
    },
    '@media(max-width: 575.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        height: 120,
      },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:portrait)':
      {
        display: 'none',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        height: 120,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:portrait)':
      {
        display: 'none',
      },
  },
  row2: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginTop:20,
    textAlign: 'center',
    width: 1078,
    height: '100%',
    background: 'rgba(126, 126, 126, 0.29)',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 500,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
      marginTop: 0,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 550,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        marginTop: 0,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 600,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        marginTop: 0,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        width: 900,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        width: 1010,
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      width: 900,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      width: 1010,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      width: 1078,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      width: 1078,
    },
    '@media(min-width: 2560px)': {
      width: 1078,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // flexWrap: 'wrap',
    // backgroundColor: 'blue',
    // height: '100%',
  },
  row3: {
    display: 'flex',
    flexDirection: 'row',
    width: 350,
    // height: 30,
    // backgroundColor: 'pink',
    justifyContent: 'space-between',
    marginTop: 25,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 180,
      marginTop: 2,
      // height: 20
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 180,
        marginTop: 2,
        // height: 20
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 180,
        marginTop: 2,
        // height: 20
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        width: 300,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        width: 300,
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      width: 300,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      width: 300,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      width: 350,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      width: 350,
    },
    '@media(min-width: 2560px)': {
      width: 350,
    },
  },
  label: {
    fontSize: 20,
    fontFamily: 'Roboto-Regular',
    fontWeight: 400,
    fontStyle: 'normal',
    color: '#ffffff',
    flex: 'none',
    textAlign: 'left',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 12,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 12,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 12,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        fontSize: 18,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 20,
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      fontSize: 18,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 20,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      fontSize: 20,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      fontSize: 20,
    },
    '@media(min-width: 2560px)': {
      fontSize: 20,
    },
  },
  progress: {
    height: 12,
    width: 210,
    marginTop: 6,
    border: '2px solid #ffffff',
    borderRadius: 40,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 110,
      height: 10,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 110,
        height: 10,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 110,
        height: 10,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        width: 180,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        width: 180,
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      width: 180,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      width: 180,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      width: 210,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      width: 210,
    },
    '@media(min-width: 2560px)': {
      width: 210,
    },
  },
  progress01: {
    // width: 0,
    height: 5,
    backgroundColor: '#ffffff',
    borderRadius: 6,
    margin: 2,

    '@media(max-width: 575.98px) and (orientation:landscape)': {
      height: 4,
      margin: 1,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        height: 4,
        margin: 1,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        height: 4,
        margin: 1,
      },
  },
  undertoneProgress: {
    height: 5,
    borderRadius: 6,
    margin: 2,

    '@media(max-width: 575.98px) and (orientation:landscape)': {
      height: 4,
      margin: 1,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        height: 4,
        margin: 1,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        height: 4,
        margin: 1,
      },
  },
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'center',
    // backgroundColor: 'red',
    color: '#ffffff',
    fontSize: 15,
    marginTop: 10,
    height: 20,
    fontFamily: 'Roboto-Regular',

    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 12,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 12,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 12,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 18,
      },
    //Laptops & larger screens

    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 18,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      fontSize: 18,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      fontSize: 18,
    },
    '@media(min-width: 2560px)': {
      fontSize: 18,
    },
  },
  toggleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    height: 120,
    justifyContent: 'space-evenly',
    // backgroundColor: 'pink',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 100,
      height: 70,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 100,
        height: 70,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 120,
        height: 70,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        width: 160,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        width: 190,
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      width: 160,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      width: 190,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      width: 200,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      width: 200,
    },
    '@media(min-width: 2560px)': {
      width: 190,
    },
  },
  toggleTitle: {
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    // marginLeft: 50,
    marginTop: 0,
    // backgroundColor: 'pink'
  },
  buttonContainer1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 6,
  },
  buttonSelected: {
    backgroundColor: '#ffffff',
    color: '#3E3E3E',
    border: 0,
    outline: 0,
  },
  buttonUnselected: {
    border: '2px solid #ffffff',
    background: 'none',
    color: '#ffffff',
  },
  button: {
    pointerEvents: 'auto',
    outline: 'none',
    borderRadius: 10,
    height: 30,
    width: 72,
    fontSize: 12,
    lineHeight: '14px',
    fontFamily: 'Roboto-Regular',
    // background: 'none',
    textAlign: 'center',
    ':active': {
      transform: 'scale(0.9)',
    },
    // marginLeft: 20
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 40,
      height: 20,
      fontSize: 12,
      border: '1px solid #ffffff',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 40,
        height: 20,
        fontSize: 10,
        border: '1px solid #ffffff',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 40,
        height: 20,
        fontSize: 10,
        border: '1px solid #ffffff',
      },
  },
  toggleColumnBorder: {
    borderRight: '1px solid #ffffff',
  },
})
