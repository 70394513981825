import React, { useEffect } from 'react'
import { a, config, useSpring } from '@react-spring/web'
import { useEnvironment, useHud, useMaster } from '../../stores'
import { useLocation } from 'wouter'
import { css, StyleSheet } from 'aphrodite'

function ModeLoadingScreen() {
  const [location] = useLocation()
  const { continuaToggle } = useEnvironment()
  const Continua = useHud()
  const { misc } = useMaster()

  const animatedStyle = useSpring({
    opacity: Continua.continuaLoading ? 2 : 0,
    config: config.gentle,
  })

  // useEffect(() => {
  //   setTimeout(() => {
  //     Continua.setContinuaLoading(false)
  //   }, 2000)
  // }, [Continua.continuaLoading])

  //* Called when refresh website in continua mode
  useEffect(() => {
    if (location.includes('/continua')) {
      continuaToggle()
    }
  }, [])

  return (
    <>
      {Continua.continuaLoading && (
        <a.div className={css(styles.fadeOut)} style={animatedStyle}>
          <div className={css(styles.text)}>
            {misc['continuamode'] != null && misc['continuamode'].title}
            {/* Please wait while we change the mode */}
          </div>
        </a.div>
      )}
    </>
  )
}

export default ModeLoadingScreen

const styles = StyleSheet.create({
  fadeOut: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100vh',
    backgroundColor: '#000000',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: '100%',
      position: 'fixed',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: '100%',
        position: 'fixed',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: '100%',
        position: 'fixed',
      },
    textAlign: 'center',
  },
  text: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    top: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'space-around',
    color: 'white',
    fontSize: 40,
    // paddingTop: 260,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 30,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 30,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 30,
      },
  },
})
