import { useAnimations, useTexture } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import React, { useEffect, useState } from 'react'
import { Color, LoopOnce, MathUtils } from 'three'
import { a as animated } from '@react-spring/three'
import { useSpring } from '@react-spring/core'
import { isMobile, isMobileOnly } from 'react-device-detect'

let flipper = 0

function TyreGLTF(props) {
  const emissiveTextures = useTexture(
    isMobileOnly ? [] : props.emissiveMapsArray
  )
  const [currentSelected, setCurrentSelected] = useState(-1)
  const { ref, actions } = useAnimations(props.animations)

  const anim = useSpring({
    pos:
      props.object != null &&
      props.object.moveOut != null &&
      props.object.moveOut
        ? 0.4
        : props.object != null &&
          props.object.moveOutInterior != null &&
          props.object.moveOutInterior
        ? 0.97
        : -0.04,
  })

  useEffect(() => {
    props.materials.Tyre.color = new Color(0x212121)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    props.materials.Tyre.roughness = 1
    props.materials.Tyre.metalness = 0
    props.materials.Tyre.reflectivity = 0
  }, [props])

  useEffect(() => {
    if (props.object != null) {
      if (props.object.highlight != null) {
        if (props.object.highlight.index !== currentSelected) {
          let nom = props.object.highlight.index
          if (nom !== -1) {
            if (!isMobileOnly) {
              props.materials.Tyre.emissiveMap = emissiveTextures[nom]
              props.materials.Tyre.emissive = new Color(
                props.object.highlight.color
              )
              props.materials.Tyre.needsUpdate = true
            }
          }
          setCurrentSelected(props.object.highlight.index)
        }
        setCurrentSelected(-1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.object])

  useEffect(() => {
    console.log(props.object)
    if (props.object != null) {
      if (props.object.animate != null) {
        if (props.object.animate) {
          actions.Tyre.clampWhenFinished = true
          actions.Tyre.loop = LoopOnce
          actions.Tyre.setEffectiveTimeScale(1)
          actions.Tyre.paused = false
          actions.Tyre.play()
        } else {
          actions.Tyre.clampWhenFinished = true
          actions.Tyre.loop = LoopOnce
          actions.Tyre.setEffectiveTimeScale(-2)
          actions.Tyre.paused = false
        }
      } else {
        actions.Tyre.clampWhenFinished = true
        actions.Tyre.loop = LoopOnce
        actions.Tyre.setEffectiveTimeScale(-2)
        actions.Tyre.paused = false
      }
    } else {
      actions.Tyre.clampWhenFinished = true
      actions.Tyre.loop = LoopOnce
      actions.Tyre.setEffectiveTimeScale(-2)
      actions.Tyre.paused = false
    }
  }, [actions, props.object])

  function handleHighlightWithPulse(delta) {
    if (props.object != null) {
      if (props.object.highlight != null) {
        if (
          props.object.highlight.pulse != null &&
          props.object.highlight.pulse
        ) {
          flipper += delta * 5
          if (flipper < 5) {
            props.materials.Tyre.emissiveIntensity = MathUtils.lerp(
              props.materials.Tyre.emissiveIntensity,
              1,
              delta * 2
            )
          } else {
            props.materials.Tyre.emissiveIntensity = MathUtils.lerp(
              props.materials.Tyre.emissiveIntensity,
              0,
              delta * 2
            )

            if (flipper > 20) flipper = 0
          }
        } else {
          props.materials.Tyre.emissiveIntensity = MathUtils.lerp(
            props.materials.Tyre.emissiveIntensity,
            1,
            delta * 3
          )
        }
      } else {
        props.materials.Tyre.emissiveIntensity = MathUtils.lerp(
          props.materials.Tyre.emissiveIntensity,
          0,
          delta * 3
        )
      }
    } else {
      if (props.materials.Tyre.emissiveIntensity !== 0) {
        props.materials.Tyre.emissiveIntensity = MathUtils.lerp(
          props.materials.Tyre.emissiveIntensity,
          0,
          delta * 3
        )
      }
    }
  }
  function handleColor() {}
  // const AudioSrc = useHud()
  useFrame((state, delta) => {
    if (!isMobileOnly) {
      handleHighlightWithPulse(delta)
      handleColor()
      if (props.object != null && props.object.animate != null) {
        if (props.object.animate) {
          props.materials.Tyre.color.lerpColors(
            props.materials.Tyre.color,
            new Color('#8c8c8c'),
            delta * 3
          )
        } else {
          props.materials.Tyre.color.lerpColors(
            props.materials.Tyre.color,
            new Color('#212121'),
            delta * 3
          )
        }
      } else {
        if (props.materials.Tyre.color.getHex() !== 0x212121) {
          props.materials.Tyre.color.lerpColors(
            props.materials.Tyre.color,
            new Color('#212121'),
            delta * 4
          )
        }
      }
    }
  })

  return (
    //0.44
    <animated.group position-z={anim.pos}>
      <primitive ref={ref} object={props.scene} dispose={null} />
    </animated.group>
  )
}
export default TyreGLTF
