import React, { Suspense } from 'react'
import { isMobile } from 'react-device-detect'
import ConditionalMeshesLowRes from '../context/ConditionalMeshesLowRes'
import ConditionalMeshes from '../context/ConditionalMeshesV2'
export default function ConditionalHolder(props) {
  return (
    <Suspense fallback={null}>
      {isMobile && <ConditionalMeshesLowRes />}
      {!isMobile && (
        <>
          {props.tier >= 2 && props.tier != null && <ConditionalMeshes />}
          {props.tier <= 1 && props.tier != null && <ConditionalMeshesLowRes />}
          {props.tier == null && <ConditionalMeshesLowRes />}
        </>
      )}
    </Suspense>
  )
}
