import React, { useState } from 'react'
import SFDCForm from '../context/ui/SFDCForm'
import SalesforceSFDC from '../context/ui/SalesforceSFDC'
import StoreUpdate from '../context/StoreUpdate'
import { useLoader } from '../stores'
import TopNavbar from '../context/ui/TopNavbar'
import ConditionalUI from '../context/ConditionalUI'
import DetailsPage from '../context/ui/DetailsPage'
import Info from '../context/ui/Info'
import NextPrevButtonUI from '../context/ui/NextPrevButtonUI'
import StartEngineButtonUI from '../context/ui/StartEngineButtonUI'
import { Route } from 'wouter'
import AudioManager from '../context/AudioManager'
import SFDCFormSuccessPopup from '../context/ui/SFDCFormSuccessPopup'
// import ObjectAudio from '../context/ObjectAudio'

function UIContentBridge() {
  const [dataState, setDataState] = useState([])
  const [dataComponent, setDataComponent] = useState([])
  const [titleState, setTitleState] = useState(null)

  function onStoreUpdate(store) {
    // console.log(store.data.components)
    setDataState(store.misc)
    setDataComponent(store.data.components)
    setTitleState(store.titles)
    // console.log(store.data.components)
  }

  // useEffect(() =>{
  //     console.log("Setting Data for the first time")
  //     setDataState(useMaster.getState().misc)
  //     setDataComponent(useMaster.getState().components)
  //     setTitleState(useMaster.getState().titles)
  // },[])

  return (
    <>
      {dataState !== null && useLoader.getState().everythingLoaded && (
        <>
          {/* {console.log(dataState)} */}
          {/* <SFDCForm data={dataState} /> */}
          <SalesforceSFDC data={dataState} />
          <TopNavbar data={dataState} />
          <ConditionalUI />
          {/* <CookieBanner data={dataState} /> */}
          <Info data={dataState} />
          <DetailsPage component={dataComponent} title={titleState} />
          <NextPrevButtonUI />
          <Route path="/energy/lead">
            <StartEngineButtonUI />
          </Route>
          <AudioManager />
          {/* <SFDCFormSuccessPopup /> */}
          {/* <ObjectAudio /> */}
        </>
      )}
      <StoreUpdate onChange={onStoreUpdate} />
    </>
  )
}

export default UIContentBridge
