import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import { slideInUp, slideOutDown } from 'react-animations'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'wouter'
import { infocardTitleIds } from '../../constants'
import { useInfo, useMaster, useSFDC } from '../../stores'
import DetailCardSubcomponent from './DetailCardSubcomponent'
import LearnMoreButton from './LearnMoreButton'
import CloseIcon from '../../components/icons/CloseIcon'

// let arr = null

export default function ComponentDetailsCard(props) {
  const [data, setData] = useState(null)
  const { setDetailsPage } = useInfo()
  const [location, setLocation] = useLocation()
  const [greenBorder, setGreenBorder] = useState(false)
  const { locale } = useMaster()
  const [titleId, setTitleId] = useState([])

  useEffect(() => {
    if (props.element != null) {
      setData(props)
    }
    if (props.enabled) {
      setDetailsPage(true)
    } else {
      setDetailsPage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  //* get title ids from constants. If you update content with new locale,then add relative ids in constants
  useEffect(() => {
    if (locale === 'en') {
      setTitleId(infocardTitleIds.en)
    } else if (locale === 'zh-CN') {
      setTitleId(infocardTitleIds['zh-CN'])
    } else {
      setTitleId(infocardTitleIds.en)
    }
  }, [locale])

  useEffect(() => {
    if (location.includes('/continua')) {
      setGreenBorder(true)
    } else {
      setGreenBorder(false)
    }
  }, [location])

  //* get title ids from constants. If you update content with new locale,then add relative ids in constants
  useEffect(() => {
    if (locale === 'en') {
      setTitleId(infocardTitleIds.en)
    } else if (locale === 'zh-CN') {
      setTitleId(infocardTitleIds['zh-CN'])
    } else {
      setTitleId(infocardTitleIds.en)
    }
  }, [locale])

  function getNameWhereId(arr, id) {
    for (var i in arr) {
      if (arr[i].id === id) {
        return arr[i].Name
      }
    }

    return 'Not Found'
  }

  function handleCloseButton() {
    const newLoc = location.substring(0, location.lastIndexOf('/'))
    setLocation(newLoc)
    // console.log('location: ', location.substring(0, location.lastIndexOf('/')))
  }

  return (
    <>
      {data != null && (
        <>
          <div
            className={css(
              props.type === 'left'
                ? styles.containerLeft
                : styles.containerRight,
              props.enabled
                ? useSFDC.getState().showFormLearnMore
                  ? styles.containerAnimationOut
                  : styles.containerAnimationIn
                : styles.containerAnimationOut,
              styles.container
            )}
          >
            <div className={css(styles.header_title_container)}>
              <div className={css(styles.header_title)}>{data.element}</div>
              <button
                className={css(styles.closeButton)}
                onClick={handleCloseButton}
              >
                <CloseIcon />
              </button>
            </div>
            <div className={css(styles.header_desc)}>{data.annotation}</div>

            {/* {console.log(props.titles)} */}
            {props.titles[0] != null && (
              <>
                <div className={css(styles.detail_container)}>
                  {/* Subcontainer 01 */}
                  {data.performanceNeeds.length !== 0 && (
                    <div className={css(styles.sub_container)}>
                      <div className={css(styles.titleContainer)}>
                        <div
                          className={css(styles.title)}
                          style={{
                            borderLeft: greenBorder
                              ? '0.8vh solid #78D23D'
                              : '0.8vh solid #F5D34F',
                          }}
                        />
                        <div className={css(styles.desc01)}>
                          {getNameWhereId(props.titles, titleId[3])}
                        </div>
                        {data.performanceNeeds != null && (
                          <>
                            {data.performanceNeeds.length === 1 && (
                              <ul className={css(styles.solul)}>
                                {data.performanceNeeds.map((data, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={css(styles.soldesc)}
                                    >
                                      {data.text_value != 'NA' &&
                                        data.text_value}
                                    </li>
                                  )
                                })}
                              </ul>
                            )}
                            {data.performanceNeeds.length > 1 && (
                              <ul className={css(styles.solul)}>
                                {data.performanceNeeds.map((data, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={css(styles.soldesc)}
                                    >
                                      {data.text_value != 'NA' &&
                                        data.text_value}
                                    </li>
                                  )
                                })}
                              </ul>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Subcontainer 02 */}
                  {data.value.text != 0 && (
                    <div className={css(styles.sub_container)}>
                      <div className={css(styles.titleContainer)}>
                        <div
                          className={css(styles.title02)}
                          style={{
                            borderLeft: greenBorder
                              ? '0.8vh solid #78D23D'
                              : '0.8vh solid #F5D34F',
                          }}
                        />
                        <div className={css(styles.desc01)}>
                          {getNameWhereId(props.titles, titleId[0])}
                        </div>

                        {data.value.text != null && (
                          <>
                            {data.value.text.length === 1 && (
                              <ul className={css(styles.solul)}>
                                {data.value.text.map((data, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={css(styles.soldesc)}
                                    >
                                      {data.text_value != 'NA' && data.title}
                                    </li>
                                  )
                                })}
                              </ul>
                            )}
                            {data.value.text.length > 1 && (
                              <ul className={css(styles.solul)}>
                                {data.value.text.map((data, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={css(styles.soldesc)}
                                    >
                                      {data.text_value != 'NA' && data.title}
                                    </li>
                                  )
                                })}
                              </ul>
                            )}
                          </>
                        )}

                        {/* {data.value.text.includes('●') && arr != null && (
                          //   <div className={css(styles.desc02)}>
                          <ul className={css(styles.solul)}>
                            {arr.map((val, index) => {
                              return (
                                <li key={index} className={css(styles.soldesc)}>
                                  {val}
                                </li>
                              )
                            })}
                          </ul>
                          //   </div>
                        )}
                        {data.value.text.includes('●') == false && (
                          <ul className={css(styles.solul)}>
                            <li className={css(styles.soldesc)}>
                              {data.value.text}
                            </li>
                          </ul>
                        )} */}

                        {/* data.value.text */}
                      </div>
                    </div>
                  )}

                  {/* Subcontainer 03 */}
                  {data.benefits.length !== 0 && (
                    <div className={css(styles.sub_container)}>
                      <div className={css(styles.titleContainer)}>
                        <div
                          className={css(styles.title03)}
                          style={{
                            borderLeft: greenBorder
                              ? '0.8vh solid #78D23D'
                              : '0.8vh solid #F5D34F',
                          }}
                          s
                        />
                        <div className={css(styles.desc01)}>
                          {getNameWhereId(props.titles, titleId[2])}
                        </div>
                        {data.benefits != null && (
                          <>
                            {data.benefits.length === 1 && (
                              <div className={css(styles.soldesc)}>
                                {data.benefits[0].text_value}
                              </div>
                            )}
                            {data.benefits.length > 1 && (
                              <ul className={css(styles.solul)}>
                                {data.benefits.map((data, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={css(styles.soldesc)}
                                    >
                                      {data.text_value}
                                    </li>
                                  )
                                })}
                              </ul>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Subcontainer 04 */}
                  {data.productsOffered.length !== 0 && (
                    <div className={css(styles.sub_container)}>
                      <div className={css(styles.titleContainer)}>
                        <div
                          className={css(styles.title04)}
                          style={{
                            borderLeft: greenBorder
                              ? '0.8vh solid #78D23D'
                              : '0.8vh solid #8e1519',
                          }}
                        />
                        <div className={css(styles.desc01)}>
                          {getNameWhereId(props.titles, titleId[1])}
                        </div>
                        {/* <div className={css(styles.desc02)}>N550</div> */}
                      </div>

                      <DetailCardSubcomponent
                        productsOffered={data.productsOffered}
                      />
                    </div>
                  )}

                  {/* Subcontainer 04 */}
                  {/* <div className={css(styles.sub_container, styles.imgDetailContainer)}>
                                <div className={css(styles.titleContainer)}>
                                    <div className={css(styles.title04)}></div>
                                    <div className={css(styles.desc01)}>{props.titles[3].Name}</div>
                                </div>
                                <DetailCardSubcomponent productsOffered={data.nextGenProducts} />
                            </div> */}
                  {/* {(location.includes('/lithium') ||
                    (location.includes('/energy/lead') &&
                      locale === 'zh-CN')) && (
                    <div
                      style={{
                        fontSize: '5vh',
                        color: '#ffffff',
                        padding: '5vh',
                      }}
                    >
                      Coming Soon...
                    </div>
                  )} */}
                </div>
              </>
            )}
          </div>
          <>
            {/* {
                    props.enableButtons &&
                    <NextPrevButtonUI/>
                } */}
            {props.enabled && <LearnMoreButton />}
          </>
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: '#3C3C3C',
    borderRadius: 12,
  },
  containerLeft: {
    top: 80,
    bottom: 0,
    left: 90,
    width: 230,
    padding: '1.5vw',
    // Small devices (landscape phones, 576px and up)
    'media(max-width:576px) and (orientation:portrait)': {
      display: 'none',
    },
    'media(min-width:576px) and (orientation:landscape)': {
      left: 100,
      top: 80,
      width: 230,
      display: 'flex',
    },
    'media(min-width:576px) and (orientation:portrait)': {
      display: 'none',
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation:landscape)': {
      left: 100,
      top: 80,
      // width: 280,
      width: 310,
      display: 'flex',
    },
    '@media (min-width: 768px) and (orientation:portrait)': {
      display: 'none',
    },
    // Large devices (desktops, 992px and up)
    '@media (min-width: 992px) and (orientation:landscape)': {
      left: 150,
      top: 200,
      width: 450,
      display: 'flex',
    },
    '@media (min-width: 992px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media (min-width: 1200px)': {
      left: 150,
      top: 200,
      width: 400,
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      left: 200,
      top: 200,
      width: 483,
    },
    '@media (min-width:1600px)': {
      left: 250,
      top: 200,
      width: 483,
    },
    '@media(min-width: 2560px)': {
      left: 300,
      top: 300,
      width: 600,
    },
  },
  containerRight: {
    top: 150,
    bottom: 0,
    right: 200,
    // '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)': {
    //     top: 150,
    // },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      right: 300,
    },
  },
  containerAnimationIn: {
    animationName: [slideInUp],
    animationDuration: '1s, 300ms',
    animationFillMode: 'forwards',
  },
  containerAnimationOut: {
    animationName: [slideOutDown],
    animationDuration: '0.5s, 300ms',
    animationFillMode: 'forwards',
  },
  header_title_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header_title: {
    marginTop: 7,
    marginLeft: 16,
    marginRight: 26,
    fontFamily: 'Oxygen-Regular',
    fontWeight: 'normal',
    fontSize: 20,
    lineHeight: '28px',
    color: '#ffffff',

    '@media(min-width: 576px) and (orientation:landscape)': {
      marginTop: 7,
      marginLeft: 16,
      marginRight: 26,
      fontSize: 20,
      lineHeight: '38px',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 20,
      marginTop: 7,
      marginLeft: 20,
      marginRight: 30,
      lineHeight: '38px',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 30,
      marginTop: 15,
      marginLeft: 20,
      lineHeight: '38px',
      marginRight: 30,
    },
    '@media(min-width:1200px)': {
      fontSize: 30,
      marginTop: 15,
      marginLeft: 20,
      lineHeight: '38px',
      marginRight: 30,
    },
    '@media(min-width:1400px)': {
      fontSize: 30,
      marginTop: 15,
      marginLeft: 20,
      lineHeight: '38px',
      marginRight: 30,
    },
    '@media(min-width:1600px)': {
      marginTop: 27,
      marginLeft: 32,
      marginRight: 30,
      fontSize: 40,
      lineHeight: '51px',
    },
    '@media(min-width:2560px)': {
      marginTop: 27,
      marginLeft: 32,
      marginRight: 30,
      fontSize: 45,
      lineHeight: '51px',
    },
  },
  closeButton: {
    outline: 'none',
    border: 0,
    background: 'none',
    // height: 40,
    // width: 'auto',
    zIndex: 1,
    cursor: 'pointer',
    pointerEvents: 'auto',
    // backgroundColor: 'blue',
    '@media(min-width: 576px) and (orientation:landscape)': {
      marginTop: 7,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      marginTop: 7,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      marginTop: 15,
    },
    '@media(min-width:1200px)': {
      marginTop: 15,
    },
    '@media(min-width:1400px)': {
      marginTop: 15,
    },
    '@media(min-width:1600px)': {
      marginTop: 27,
    },
    '@media(min-width:2560px)': {
      marginTop: 27,
    },
  },
  header_desc: {
    fontSize: 15,
    lineHeight: '18px',
    marginLeft: 16,
    marginRight: 25,
    marginTop: 5,
    marginBottom: 2,
    fontFamily: 'Oxygen-Regular',
    fontWeight: 'normal',
    color: 'rgb(255, 255, 255, 0.65)',
    '@media(min-width: 576px) and (orientation:landscape)': {
      fontSize: 15,
      lineHeight: '18px',
      marginLeft: 16,
      marginRight: 25,
      marginTop: 5,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 15,
      lineHeight: '18px',
      marginLeft: 20,
      marginRight: 25,
      marginTop: 2,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 22,
      marginTop: 5,
      marginLeft: 20,
      lineHeight: '28px',
      marginRight: 25,
    },
    '@media(min-width:1200px)': {
      fontSize: 22,
      marginTop: 5,
      marginLeft: 20,
      lineHeight: '28px',
      marginRight: 25,
    },
    '@media(min-width:1400px)': {
      marginTop: 11,
      marginLeft: 32,
      marginRight: 30,
      marginBottom: 2,
      fontSize: 20,
      lineHeight: '25px',
    },
    '@media(min-width:1600px)': {
      marginTop: 11,
      marginLeft: 32,
      marginRight: 30,
      marginBottom: 2,
      fontSize: 20,
      lineHeight: '25px',
    },
    '@media(min-width:2560px)': {
      marginTop: 11,
      marginLeft: 32,
      marginRight: 30,
      marginBottom: 2,
      fontSize: 35,
      lineHeight: '40px',
    },
  },
  detail_container: {
    display: 'flex',
    flexDirection: 'column',
    // overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    marginTop: 5,
    paddingRight: `${isMobile ? '2.3vh' : '0vh'}`,
    // '::-webkit-overflow-scrolling': 'touch',
    '::-webkit-scrollbar': {
      width: 20,
    },
    '::-webkit-scrollbar-thumb': {
      background: '#DBDBDB',
      // height: 10,
      borderRadius: 8,
      borderLeft: '5px solid rgba(0, 0, 0, 0)',
      borderRight: '5px solid rgba(0, 0, 0, 0)',
      backgroundClip: 'padding-box',
      boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.025)',
    },

    '::-webkit-scrollbar-track': {
      // width: 2,
      // height: 357,
      borderRadius: 52,
      background: '#5C5C5C',
      borderLeft: '8px solid #3C3C3C',
      borderRight: '8px solid #3C3C3C',
    },
    overflowY: 'scroll',
    '@media(min-width:556px) and (orientation:landscape)': {
      marginTop: 5,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      marginTop: 7,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      marginTop: 10,
    },
    '@media(min-width: 1200px)': {
      marginTop: 12,
    },
    '@media(min-width: 1400px)': {
      marginTop: 12,
    },
    '@media(min-width: 1600px)': {
      marginTop: 16,
    },
  },
  sub_container: {
    marginLeft: 16,
    marginTop: 5,
    '@media(max-width: 576px) and (orientation:landscape)': {
      marginLeft: 16,
      marginTop: 5,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      marginLeft: 20,
      marginTop: 7,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      marginLeft: 25,
    },
    '@media(min-width: 1200px)': {
      marginLeft: 20,
      marginTop: 16,
      marginRight: 10,
    },
    '@media(min-width: 1400px)': {
      marginLeft: 29,
      marginTop: 16,
      marginRight: 10,
    },
    '@media(min-width: 1600px)': {
      marginLeft: 32,
      marginTop: 16,
    },
    '@media(min-width: 2560px)': {
      marginLeft: 32,
      marginTop: 20,
    },
  },
  titleContainer: {
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
  },
  desc01: {
    height: 'auto',
    fontFamily: 'Roboto-Bold',
    fontWeight: 'normal',
    color: '#ffffff',
    float: 'left',
    fontSize: 16,
    lineHeight: '17px',
    width: '95%',
    '@media(max-width: 576px) and (orientation:landscape)': {
      fontSize: 16,
      lineHeight: '17px',
      width: '95%',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 18,
      lineHeight: '19px',
      width: '95%',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 15,
      lineHeight: '19px',
      width: '95%',
    },
    '@media(min-width: 1200px)': {
      fontSize: 20,
      lineHeight: '25px',
      width: '95%',
    },
    '@media(min-width: 1400px)': {
      fontSize: 26,
      lineHeight: '29px',
      width: '97%',
    },
    '@media(min-width: 1600px)': {
      fontSize: 28,
      lineHeight: '32px',
      width: '97%',
      float: 'left',
    },
    '@media(min-width: 2560px)': {
      fontSize: 32,
      lineHeight: '38px',
      width: '97%',
      float: 'left',
    },
  },
  desc02: {
    // width: 376,
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    color: '#ffffff',
    float: 'left',
    paddingTop: 2,
    fontSize: 13,
    lineHeight: '17px',
    width: '95%',
    '@media(max-width: 576px) and (orientation:landscape)': {
      paddingTop: 2,
      fontSize: 13,
      lineHeight: '17px',
      width: '95%',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      paddingTop: 2,
      fontSize: 14,
      lineHeight: '19px',
      width: '95%',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      paddingTop: 2,
      fontSize: 14,
      lineHeight: '19px',
      width: '95%',
    },
    '@media(min-width: 1200px)': {
      fontSize: 16,
      lineHeight: '20px',
      width: '97%',
    },
    '@media(min-width: 1400px)': {
      fontSize: 21,
      lineHeight: '26px',
      width: '97%',
    },
    '@media(min-width: 1600px)': {
      fontSize: 23,
      lineHeight: '28px',
      width: '97%',
    },
    '@media(min-width: 2560px)': {
      fontSize: 28,
      lineHeight: '30px',
      width: '97%',
    },
  },

  title: {
    // borderLeft: '4px solid #F5D34F',
    height: 28,
    float: 'left',
    width: '5%',
    '@media(max-width: 576px) and (orientation:landscape)': {
      // borderLeft: '4px solid #F5D34F',
      height: 30,
      width: '5%',
    },
    '@media(min-width: 768x) and (orientation:landscape)': {
      // borderLeft: '6px solid #F5D34F',
      height: 33,
      width: '5%',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      // borderLeft: '6px solid #F5D34F',
      height: 33,
      width: '5%',
    },
    '@media(min-width: 1200px)': {
      // borderLeft: '6px solid #F5D34F',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 1400px)': {
      // borderLeft: '6px solid #F5D34F',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 1600px)': {
      // borderLeft: '6px solid #F5D34F',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 2560px)': {
      // borderLeft: '6px solid #F5D34F',
      height: 43,
      width: '3%',
    },
  },

  title02: {
    // borderLeft: '4px solid #F68529',
    height: 28,
    float: 'left',
    width: '5%',
    '@media(max-width: 576px) and (orientation:landscape)': {
      // borderLeft: '4px solid #F68529',
      height: 30,
      width: '5%',
    },
    '@media(min-width: 768x) and (orientation:landscape)': {
      // borderLeft: '6px solid #F68529',
      height: 33,
      width: '5%',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      // borderLeft: '6px solid #F68529',
      height: 33,
      width: '5%',
    },
    '@media(min-width: 1200px)': {
      // borderLeft: '6px solid #F68529',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 1400px)': {
      // borderLeft: '6px solid #F68529',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 1600px)': {
      // borderLeft: '6px solid #F68529',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 2560px)': {
      // borderLeft: '6px solid #F68529',
      height: 43,
      width: '3%',
    },
  },

  title03: {
    // borderLeft: '4px solid #D51D25',
    height: 28,
    float: 'left',
    width: '5%',
    '@media(max-width: 576px) and (orientation:landscape)': {
      // borderLeft: '4px solid #D51D25',
      height: 30,
      width: '5%',
    },
    '@media(min-width: 768x) and (orientation:landscape)': {
      // borderLeft: '6px solid #D51D25',
      height: 33,
      width: '5%',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      // borderLeft: '6px solid #D51D25',
      height: 33,
      width: '5%',
    },
    '@media(min-width: 1200px)': {
      // borderLeft: '6px solid #D51D25',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 1400px)': {
      // borderLeft: '6px solid #D51D25',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 1600px)': {
      // borderLeft: '6px solid #D51D25',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 2560px)': {
      // borderLeft: '6px solid #D51D25',
      height: 43,
      width: '3%',
    },
  },

  title04: {
    // borderLeft: '4px solid #8e1519',
    height: 28,
    float: 'left',
    width: '5%',
    '@media(max-width: 576px) and (orientation:landscape)': {
      // borderLeft: '4px solid #8e1519',
      height: 30,
      width: '5%',
    },
    '@media(min-width: 768x) and (orientation:landscape)': {
      // borderLeft: '6px solid #8e1519',
      height: 33,
      width: '5%',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      // borderLeft: '6px solid #8e1519',
      height: 33,
      width: '5%',
    },
    '@media(min-width: 1200px)': {
      // borderLeft: '6px solid #8e1519',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 1400px)': {
      // borderLeft: '6px solid #8e1519',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 1600px)': {
      // borderLeft: '6px solid #8e1519',
      height: 43,
      width: '3%',
    },
    '@media(min-width: 2560px)': {
      // borderLeft: '6px solid #8e1519',
      height: 43,
      width: '3%',
    },
  },

  solul: {
    margin: '-10px 0',
  },

  soldesc: {
    color: '#ffffff',
    fontFamily: 'Roboto-Regular',
    fontSize: 13,
    lineHeight: '17px',
    width: '95%',
    marginTop: 2,
    float: 'left',
    '@media(max-width: 576px) and (orientation:landscape)': {
      fontSize: 13,
      lineHeight: '17px',
      width: '95%',
      marginTop: 2,
      float: 'left',
    },
    '@media(min-width: 768px) and  (orientation:landscape)': {
      fontSize: 14,
      lineHeight: '19px',
      marginTop: 2,
      width: '95%',
      float: 'left',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 14,
      lineHeight: '19px',
      marginTop: 2,
      width: '95%',
      float: 'left',
    },
    '@media(min-width: 1200px)': {
      fontSize: 16,
      lineHeight: '20px',
      marginTop: 2,
      width: '97%',
      float: 'left',
    },
    '@media(min-width: 1400px)': {
      fontSize: 21,
      lineHeight: '26px',
      marginTop: 2,
      width: '97%',
      float: 'left',
    },
    '@media(min-width: 1600px)': {
      fontSize: 23,
      lineHeight: '28px',
      marginTop: 2,
      width: '97%',
      float: 'left',
    },
    '@media(min-width: 2560px)': {
      fontSize: 28,
      lineHeight: '30px',
      marginTop: 2,
      width: '97%',
      float: 'left',
    },
  },

  subheadImg: {
    height: 140, //change after adding image from database
    marginLeft: 7,
    marginTop: 7,
    width: '90%',
    borderRadius: 10,
    float: 'left',
    backgroundColor: '#ffffff',
    '@media(min-width: 576px) and (orientation:landscape)': {
      height: 100, //change after adding image from database
      marginLeft: 7,
      marginTop: 7,
      width: '90%',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      height: 120,
      marginTop: 7,
      marginLeft: 7,
      width: '90%',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      height: 140, //change after adding image from database
      marginLeft: 7,
      marginTop: 7,
      width: '90%',
    },
    '@media(min-width: 1200px)': {
      height: 140, //change after adding image from database
      marginLeft: 7,
      marginTop: 7,
      width: '90%',
    },
    '@media(min-width: 1400px)': {
      height: 140, //change after adding image from database
      marginLeft: 7,
      marginTop: 7,
      width: '90%',
    },
    '@media(min-width: 1600px)': {
      height: 140, //change after adding image from database
      marginLeft: 7,
      marginTop: 7,
      width: '90%',
    },
    '@media(min-width: 2560px)': {
      height: 140, //change after adding image from database
      marginLeft: 7,
      marginTop: 7,
      width: '90%',
    },
  },
})
