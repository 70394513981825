import { useEffect } from 'react'
import { useHud } from '../stores'
const axios = require('axios')

function CountryDetection() {
  const CountryData = useHud()

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data
        // console.log(data)
        if (data.country_name === 'India' || data.country_name === 'China') {
          CountryData.setCountryName(data.country_name)
        } else {
          CountryData.setCountryName('India')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  return null
}

export default CountryDetection

/**
 * Detect countries for ip api and set it to the store variable.
 * Please add condition to detect countries required for project.
 * For other countries just show everything in english.
 */
