/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState, useEffect } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import {
  Color,
  DoubleSide,
  // LinearEncoding,
  // RGBAFormat,
  // sRGBEncoding,
  // UnsignedByteType,
} from 'three'
import EnvironmentState from '../EnvironmentState'
import { isMobile } from 'react-device-detect'

export default function Model(props) {
  const group = useRef()
  const [
    nightWall,
    nightFloor,
    nightCeiling,
    dayWall,
    dayFloor,
    dayCeiling,
    continuaWall,
    continuaFloor,
    continuaCeiling,
  ] = useTexture([
    '/glb/environment/Walls_Alb_night.webp',
    '/glb/environment/Floor_Alb_night.webp',
    '/glb/environment/Ceiling_Alb_night.webp',
    '/glb/environment/Wall_Alb.webp',
    '/glb/environment/Floor3A_Base.webp',
    '/glb/environment/Ceiling_Alb.webp',
    // ! Continua
    // '/glb/environment/continua_env/Wall_Alb.webp',
    // '/glb/environment/continua_env/Floor3A_Base.webp',
    // '/glb/environment/continua_env/Ceiling_Alb.webp',
    '/glb/environment/continua_env/Wall_Alb.jpg',
    '/glb/environment/continua_env/Floor3A_Base.jpg',
    '/glb/environment/continua_env/Ceiling_Alb.jpg',
  ])

  // const { nodes, materials } = useGLTF(
  //   '/glb/environment/continua_env/Env_Continua.gltf'
  // )

  const [mode, setMode] = useState('day')

  const { scene, nodes, materials } = useGLTF(
    mode === 'continua'
      ? '/glb/environment/continua_env/Env_Continua.gltf'
      : '/glb/environment/dayscene_1.gltf'
  )

  useEffect(() => {
    // if (day) {
    //   materials.Wall.map = dayWall
    //   materials.Floor.map = dayFloor
    //   materials.Ceiling.map = dayCeiling
    // } else {
    //   materials.Wall.map = nightWall
    //   materials.Floor.map = nightFloor
    //   materials.Ceiling.map = nightCeiling
    // }
    if (mode === 'day') {
      materials.Wall.map = dayWall
      materials.Floor.map = dayFloor
      materials.Ceiling.map = dayCeiling
    } else if (mode === 'night') {
      materials.Wall.map = nightWall
      materials.Floor.map = nightFloor
      materials.Ceiling.map = nightCeiling
    } else if (mode === 'continua') {
      materials.Posters.color = new Color(0x383838)
      // materials.Floor.map = continuaFloor
      // materials.Ceiling.map = continuaCeiling
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  useEffect(() => {
    let to = null
    if (
      nightWall != null &&
      nightFloor != null &&
      nightCeiling != null &&
      dayWall != null &&
      dayFloor != null &&
      dayCeiling != null &&
      continuaWall != null &&
      continuaFloor != null &&
      continuaCeiling != null &&
      nodes != null
    ) {
      // dayWall.format = RGBAFormat
      // dayFloor.format = RGBAFormat
      // dayCeiling.format = RGBAFormat
      // nightWall.format = RGBAFormat
      // nightFloor.format = RGBAFormat
      // nightCeiling.format = RGBAFormat

      // dayWall.type = UnsignedByteType
      // dayFloor.type = UnsignedByteType
      // dayCeiling.type = UnsignedByteType
      // nightWall.type = UnsignedByteType
      // nightFloor.type = UnsignedByteType
      // nightCeiling.type = UnsignedByteType

      // dayWall.encoding = sRGBEncoding
      // dayFloor.encoding = sRGBEncoding
      // dayCeiling.encoding = sRGBEncoding
      // nightWall.encoding = sRGBEncoding
      // nightFloor.encoding = sRGBEncoding
      // nightCeiling.encoding = sRGBEncoding

      if (isMobile) {
        // behaviour is required only on mobile
        to = setTimeout(() => {
          props.done(true)
        }, 1000)
      } else props.done(true)
    }

    return function cleanup() {
      if (to != null) clearTimeout(to)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nightWall,
    nightFloor,
    nightCeiling,
    dayWall,
    dayFloor,
    dayCeiling,
    continuaWall,
    continuaFloor,
    continuaCeiling,
    nodes,
  ])

  useEffect(() => {
    materials.Glass.transparent = true
    materials.Glass.side = DoubleSide
    materials.Glass.opacity = mode === 'continua' ? 0.35 : 0.4
    // materials.Glass.color = new Color(0x030303)
    materials.Glass.color =
      mode === 'continua' ? new Color(0x383838) : new Color(0x030303)

    materials.Floor.roughness = 1
    materials.Floor.metalness = 1
  }, [materials, mode])

  function onChange(env) {
    setTimeout(() => {
      switch (env) {
        case 0:
          setMode('day')
          break
        case 1:
          setMode('night')
          break
        case 2:
          setMode('continua')
          break
        default:
          setMode('day')
          break
      }
    }, 500)
  }

  return (
    <>
      {mode === 'continua' ? ( //? Continua
        <group dispose={null} {...props}>
          <primitive object={scene} />
        </group>
      ) : (
        <group ref={group} {...props} dispose={null}>
          <group name="Scene">
            <group name="env" position={[-2.4, 4.83, 0]} scale={[1.33, 1, 1]}>
              <mesh
                name="Plane006"
                material={materials.Lights1}
                geometry={nodes.Plane006.geometry}
              />
              <mesh
                name="Plane006_1"
                material={materials.DoorFrame}
                geometry={nodes.Plane006_1.geometry}
              />
              <mesh
                name="Plane006_2"
                material={materials.Glass}
                geometry={nodes.Plane006_2.geometry}
              />
              <mesh
                name="Plane006_3"
                material={materials.Floor}
                material-color={'#414141'}
                geometry={nodes.Plane006_3.geometry}
              />
              <mesh
                name="Plane006_4"
                material={materials.Posters}
                geometry={nodes.Plane006_4.geometry}
              />
              <mesh
                name="Plane006_5"
                material={materials.Wall}
                material-color={'#202020'}
                geometry={nodes.Plane006_5.geometry}
              />
              <mesh
                name="Plane006_6"
                material={materials.Ceiling}
                material-color={'#414141'}
                geometry={nodes.Plane006_6.geometry}
              />
            </group>
            <group
              name="outside"
              position={[-37.62, 2.48, 0]}
              scale={[11.6, 3.67, 11.6]}
            >
              <mesh
                name="Cube045"
                material={materials.ConcreteFlooring}
                geometry={nodes.Cube045.geometry}
              />
              <mesh
                name="Cube045_1"
                material={materials['Wall.003']}
                geometry={nodes.Cube045_1.geometry}
              />
            </group>
          </group>
        </group>
      )}

      <EnvironmentState onChange={onChange} />
    </>
  )
}
