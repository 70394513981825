import { useAnimations } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import React, { useEffect } from 'react'
import { Color, LoopOnce, MathUtils } from 'three'

// let flipper = 0

export default function RoofGLTF(props) {
  const { ref, actions } = useAnimations(props.animations)

  useEffect(() => {
    props.materials.ClearCoat.metalness = 0.1
    props.materials.ClearCoat.roughness = 0.1
    props.materials.ClearCoat.transparent = true
    props.materials.ClearCoat.color = new Color('#212121')
    props.materials.ClearCoat.opacity = 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   if (props.object != null) {
  //     if (props.object.highlight != null) {
  //       props.materials.CarColor.color = new Color(props.object.highlight.color)
  //     } else {
  //       props.materials.CarColor.color =
  //         props.carMaterial != null
  //           ? new Color(props.carMaterial.color)
  //           : new Color('#020202')
  //     }
  //   } else {
  //     props.materials.CarColor.color =
  //       props.carMaterial != null
  //         ? new Color(props.carMaterial.color)
  //         : new Color('#020202')
  //   }
  // }, [props.object])

  useEffect(() => {
    if (props.object != null) {
      if (props.object.animate != null) {
        if (props.object.animate) {
          // props.materials.ClearCoat.opacity = 0.5
          actions.Animation.clampWhenFinished = true
          actions.Animation.loop = LoopOnce
          actions.Animation.setEffectiveTimeScale(1)
          actions.Animation.paused = false
          actions.Animation.play()
        } else {
          actions.Animation.clampWhenFinished = true
          actions.Animation.loop = LoopOnce
          actions.Animation.setEffectiveTimeScale(-2)
          actions.Animation.paused = false
        }
      } else {
        actions.Animation.clampWhenFinished = true
        actions.Animation.loop = LoopOnce
        actions.Animation.setEffectiveTimeScale(-2)
        actions.Animation.paused = false
        console.log('status02: ')
      }
    } else {
      actions.Animation.clampWhenFinished = true
      actions.Animation.loop = LoopOnce
      actions.Animation.setEffectiveTimeScale(-2)
      actions.Animation.paused = false
      props.materials.ClearCoat.opacity = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, props.object])

  useFrame((state, delta) => {
    if (props.object != null) {
      if (props.object.animate == null) {
        props.materials.ClearCoat.opacity = MathUtils.lerp(
          props.materials.ClearCoat.opacity,
          0,
          delta * 3
        )
      } else {
        if (props.object.animate != null) {
          if (props.object.animate) {
            props.materials.ClearCoat.opacity = MathUtils.lerp(
              props.materials.ClearCoat.opacity,
              0.5,
              delta * 3
            )
          }
        }
      }
    }
  })

  //   if (props.carMaterial != null && props.scene !== null) {
  //     props.materials.CarColor.color.lerpColors(
  //       new Color(props.materials.CarColor.color),
  //       new Color(props.carMaterial.color),
  //       delta * 2
  //     )
  //     props.materials.CarColor.roughness = MathUtils.lerp(
  //       props.materials.CarColor.roughness,
  //       props.carMaterial.roughness,
  //       delta * 2
  //     )
  //     props.materials.CarColor.metalness = MathUtils.lerp(
  //       props.materials.CarColor.metalness,
  //       props.carMaterial.metalness,
  //       delta * 2
  //     )
  //     props.materials.CarColor.clearcoat = MathUtils.lerp(
  //       props.materials.CarColor.clearcoat,
  //       props.carMaterial.clearcoat,
  //       delta * 2
  //     )
  //     props.materials.CarColor.clearcoatRoughness = MathUtils.lerp(
  //       props.materials.CarColor.clearcoatRoughness,
  //       props.carMaterial.clearcoatRoughness,
  //       delta * 2
  //     )
  //     props.materials.CarColor.transmission = MathUtils.lerp(
  //       props.materials.CarColor.transmission,
  //       props.carMaterial.transmission,
  //       delta * 2
  //     )
  //     props.materials.CarColor.specularTint.lerp(
  //       new Color(props.materials.CarColor.specularTint),
  //       new Color(props.carMaterial.specularTint),
  //       delta * 4
  //     )
  //     props.materials.CarColor.specularIntensity = MathUtils.lerp(
  //       props.materials.CarColor.specularIntensity,
  //       props.carMaterial.specularIntensity,
  //       delta * 2
  //     )
  //   }
  // })

  // useEffect(() => {
  //   props.materials.CarColor.color =
  //     props.carMaterial != null
  //       ? new Color(props.carMaterial.color)
  //       : new Color('#020202')
  //   props.materials.CarColor.roughness =
  //     props.carMaterial != null ? props.carMaterial.roughness : 0.2
  //   props.materials.CarColor.metalness =
  //     props.carMaterial != null ? props.carMaterial.metalness : 0.3
  //   props.materials.CarColor.clearcoat =
  //     props.carMaterial != null ? props.carMaterial.clearcoat : 0
  //   props.materials.CarColor.clearcoatRoughness =
  //     props.carMaterial != null ? props.carMaterial.clearcoatRoughness : 0.1
  //   props.materials.CarColor.transmission =
  //     props.carMaterial != null ? props.carMaterial.transmission : 0
  //   // props.materials.CarColor.specularTint =
  //   //   props.carMaterial != null
  //   //     ? new Color(props.carMaterial.specularTint)
  //   //     : new Color('#000000')
  //   // props.materials.CarColor.specularIntensity =
  //   //   props.carMaterial != null ? props.carMaterial.specularIntensity : 1
  // }, [])

  useEffect(() => {
    ref.current.children[0].children.forEach((element) => {
      if (element.name === 'BaseColor') {
        element.material = props.carPaintMaterial.CarColor
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.carPaintMaterial])

  return <primitive ref={ref} object={props.scene} />
}
