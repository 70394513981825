import React, { useMemo } from 'react'
import { a as animated } from '@react-spring/three'
import { useSpring } from '@react-spring/core'
import { useLocation } from 'wouter'

function Battery(props) {
  const anim = useSpring({
    pos: props.object != null && props.object.moveOut != null ? -1.8 : -0.05,
    // onStart: () =>
    //   props.object != null && props.object.moveOut != null
    //     ? (props.materials.CarBattery.opacity = 1)
    //     : (props.materials.CarBattery.opacity = 0),
  })
  // useMemo(() => {
  //   console.log('battery opacity: ' + anim.opacity)
  //   props.materials.CarBattery.transparent = true
  //   props.materials.CarBattery.opacity = anim.opacity
  // }, [props.object])
  return (
    <>
      {/* {location.includes('/lithium') && ( */}
      <animated.group
        position-z={anim.pos}
        position-y={-0.091}
        // visible={anim.opacity}
      >
        <primitive object={props.scene} dispose={null} />
      </animated.group>
      {/* )} */}
    </>
  )
}

export default Battery
