import { useEffect } from 'react'
import { useLocation } from 'wouter'
import { sendGAPageview } from './GoogleAnalytics'

function LocationTrack() {
  const [location] = useLocation()

  useEffect(() => {
    sendGAPageview(location)
  }, [location])

  return null
}

export default LocationTrack
