import React, { useEffect, useState } from 'react'
// import * as THREE from 'three'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import {
  BackSide,
  LinearEncoding,
  PMREMGenerator,
  // UnsignedByteType,
} from 'three'
import { useThree, useLoader } from '@react-three/fiber'
import { Environment, Lightformer, Sphere, useTexture } from '@react-three/drei'
import EnvironmentState from '../context/EnvironmentState'
import daySB from '../textures/bc_day.hdr'
import nightSB from '../textures/night_hdri_3.hdr'
import continuaSB from '../textures/Continua_Day_HDRI.hdr'
import { useEnvironment, useHud } from '../stores'

export default function Skybox() {
  // const hdrDay = 'bc_day.hdr'
  // const hdrNight = 'night_hdri_3.hdr'

  // const { gl, scene } = useThree()

  // const [backgroundTexture, backgroundTextureNight] = useTexture([
  //   '/textures/rural_landscape_1k.webp',
  //   '/textures/rural_landscape_1k_night.webp',
  // ])
  const [localTex, setLocalTex] = useState(null)
  const background = useTexture(
    localTex != null ? localTex : '/textures/rural_landscape_1k.webp'
  )

  // const daySkybox = useLoader(RGBELoader, daySB)
  // const nightSkybox = useLoader(RGBELoader, nightSB)
  // const continuaSkybox = useLoader(RGBELoader, continuaSB)

  const [mode, setMode] = useState('day')
  const { current } = useEnvironment()

  const Continua = useHud()

  useEffect(() => {
    // this useEffect runs first and sets the mode.
    if (current === 0) {
      setMode('day')
    } else if (current === 1) {
      setMode('night')
    } else if (current === 2) {
      setMode('continua')
    }
  }, [])

  function onChange(env) {
    setTimeout(() => {
      if (env === 0) {
        setMode('day')
      } else if (env === 1) {
        setMode('night')
        // setDay(true)
      } else if (env === 2) {
        setMode('continua')
      } else {
        setMode('day')
      }
    }, 500)
  }

  useEffect(() => {
    if (localTex != null) {
      setTimeout(() => {
        Continua.setContinuaLoading(false)
      }, 1000)
    }
  }, [localTex])

  useEffect(() => {
    if (mode === 'day') {
      setLocalTex('/textures/rural_landscape_1k.webp')
    } else if (mode === 'night') {
      setLocalTex('/textures/night01.webp')
    } else if (mode === 'continua') {
      setLocalTex('/textures/rainforest_trail_4k.webp')
    }
  }, [mode])

  // useEffect(() => {
  //   backgroundTexture.encoding = LinearEncoding
  //   backgroundTextureNight.encoding = LinearEncoding
  // }, [backgroundTexture, backgroundTextureNight])

  useEffect(() => {
    background.encoding = LinearEncoding
  }, [background])

  // function setEnvironmentMap() {
  //   let pmremGenerator = new PMREMGenerator(gl)
  //   pmremGenerator.compileEquirectangularShader()
  //   const envMap = pmremGenerator.fromEquirectangular(
  //     mode === 'day'
  //       ? daySkybox
  //       : mode === 'night'
  //       ? nightSkybox
  //       : continuaSkybox
  //   ).texture
  //   scene.environment = envMap
  //   pmremGenerator.dispose()
  // }

  // useEffect(() => {
  //   setEnvironmentMap()
  // }, [mode])

  return (
    <>
      {/* <Sphere
        args={mode === 'continua' ? [300, 120, 80] : [50, 30, 30]}
        position={mode === 'continua' ? [0, 100, 0] : [0, -5, 0]}
      >
        <meshBasicMaterial
          attach="material"
          map={background != null && background}
          side={BackSide}
        />
      </Sphere> */}
      {/* {day && (
        <Environment files={'/textures/' + hdrDay} encoding={sRGBEncoding} />
      )}
      {!day && (
        <Environment files={'/textures/' + hdrNight} encoding={sRGBEncoding} />
      )} */}
      <Environment
        files={mode === 'day' ? daySB : mode === 'night' ? nightSB : continuaSB}
        background
      />
      <Sphere
        args={mode === 'continua' ? [300, 120, 80] : [50, 30, 30]}
        position={mode === 'continua' ? [0, 100, 0] : [0, -5, 0]}
      >
        <meshBasicMaterial
          attach="material"
          map={background != null && background}
          side={BackSide}
        />
      </Sphere>
      <EnvironmentState onChange={onChange} />
    </>
  )
}
