import { useFrame, useThree } from '@react-three/fiber'

export default function RenderStuff() {
  const { invalidate } = useThree()

  useFrame(() => {
    invalidate()
  })

  return null
}
