import { useAnimations } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { Color, LoopOnce, MathUtils } from 'three'

const paintLerpSpeed = 4

export default function PaintGLTF(props) {
  const { ref, actions } = useAnimations(props.animations)
  const { invalidate } = useThree()

  // useEffect(() => {
  //   if (props.object != null) {
  //     if (props.object.highlight != null) {
  //       if (props.object.highlight.index != currentSelected) {
  //         let nom = props.object.highlight.index
  //         if (nom != -1) {
  //           props.materials.CarColor.emissiveMap = emissiveTextures[nom]
  //           props.materials.CarColor.emissive = new Color(
  //             props.object.highlight.color
  //           )
  //           props.materials.CarColor.needsUpdate = true
  //         }
  //         setCurrentSelected(props.object.highlight.index)
  //       }
  //     }
  //   }
  // }, [props.object])

  useEffect(() => {
    if (props.object != null) {
      if (props.object.animate != null) {
        if (props.object.animate) {
          actions.HoodAction.clampWhenFinished = true
          actions.HoodAction.loop = LoopOnce
          actions.HoodAction.setEffectiveTimeScale(1)
          actions.HoodAction.paused = false
          actions.HoodAction.play()
        } else {
          actions.HoodAction.clampWhenFinished = true
          actions.HoodAction.loop = LoopOnce
          actions.HoodAction.setEffectiveTimeScale(-2)
          actions.HoodAction.paused = false
        }
      } else {
        actions.HoodAction.clampWhenFinished = true
        actions.HoodAction.loop = LoopOnce
        actions.HoodAction.setEffectiveTimeScale(-2)
        actions.HoodAction.paused = false
      }
    } else {
      actions.HoodAction.clampWhenFinished = true
      actions.HoodAction.loop = LoopOnce
      actions.HoodAction.setEffectiveTimeScale(-2)
      actions.HoodAction.paused = false
    }
  }, [actions, props.object])

  useEffect(() => {
    props.materials.CarColor.color =
      props.carMaterial != null
        ? new Color(props.carMaterial.color)
        : new Color('#FF0000')
    props.materials.CarColor.roughness =
      props.carMaterial != null ? props.carMaterial.roughness : 0.2
    props.materials.CarColor.metalness =
      props.carMaterial != null ? props.carMaterial.metalness : 0.3
    props.materials.CarColor.clearcoat =
      props.carMaterial != null ? props.carMaterial.clearcoat : 0
    props.materials.CarColor.clearcoatRoughness =
      props.carMaterial != null ? props.carMaterial.clearcoatRoughness : 0.1
    props.materials.CarColor.transmission =
      props.carMaterial != null ? props.carMaterial.transmission : 0
    // props.materials.CarColor.specularTint =
    //   props.carMaterial != null
    //     ? new Color(props.carMaterial.specularTint)
    //     : new Color('#000000')
    // props.materials.CarColor.specularIntensity =
    //   props.carMaterial != null ? props.carMaterial.specularIntensity : 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      props.materials != null &&
      props.carMaterial != null &&
      props.scene !== null &&
      isMobile
    ) {
      props.materials.CarColor.color = new Color(props.carMaterial.color)
      props.materials.CarColor.roughness = props.carMaterial.roughness
      props.materials.CarColor.metalness = props.carMaterial.metalness
      props.materials.CarColor.clearcoat = props.carMaterial.clearcoat
      props.materials.CarColor.clearcoatRoughness =
        props.carMaterial.clearcoatRoughness
      props.materials.CarColor.transmission = props.carMaterial.transmission
      // props.materials.CarColor.sheenColor.lerp(
      //   new Color(props.materials.CarColor.sheenColor),
      //   new Color(props.carMaterial.specularTint),
      //   delta * 4
      // )
      // props.materials.CarColor.sheen = MathUtils.lerp(
      //   props.materials.CarColor.sheen,
      //   props.carMaterial.specularIntensity,
      //   delta * 2
      // )
      props.materials.CarColor.needsUpdate = true
      invalidate()
    }
  }, [props.carMaterial])

  useFrame((state, delta) => {
    if (props.carMaterial != null && props.scene !== null) {
      props.materials.CarColor.color.lerpColors(
        new Color(props.materials.CarColor.color),
        new Color(props.carMaterial.color),
        delta * paintLerpSpeed
      )
      props.materials.CarColor.roughness = MathUtils.lerp(
        props.materials.CarColor.roughness,
        props.carMaterial.roughness,
        delta * paintLerpSpeed
      )
      props.materials.CarColor.metalness = MathUtils.lerp(
        props.materials.CarColor.metalness,
        props.carMaterial.metalness,
        delta * paintLerpSpeed
      )
      props.materials.CarColor.clearcoat = MathUtils.lerp(
        props.materials.CarColor.clearcoat,
        props.carMaterial.clearcoat,
        delta * paintLerpSpeed
      )
      props.materials.CarColor.clearcoatRoughness = MathUtils.lerp(
        props.materials.CarColor.clearcoatRoughness,
        props.carMaterial.clearcoatRoughness,
        delta * paintLerpSpeed
      )
      props.materials.CarColor.transmission = MathUtils.lerp(
        props.materials.CarColor.transmission,
        props.carMaterial.transmission,
        delta * paintLerpSpeed
      )
      // props.materials.CarColor.sheenColor.lerp(
      //   new Color(props.materials.CarColor.sheenColor),
      //   new Color(props.carMaterial.specularTint),
      //   delta * 4
      // )
      // props.materials.CarColor.sheen = MathUtils.lerp(
      //   props.materials.CarColor.sheen,
      //   props.carMaterial.specularIntensity,
      //   delta * 2
      // )
    }
  })

  return <primitive ref={ref} object={props.scene} />
}
