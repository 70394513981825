export const FrameRate = 30

export const AllowedLocales = ['en', 'zh-CN', 'es', 'pt']
export const DisplayLocales = ['en-UK', 'zh-CN', 'es-ESP', 'pt-PRT']
export const languageIndices = {
  english: 0,
  chinese: 1,
  spanish: 2,
  portuguese: 3,
}
export const infocardTitleIds = {
  en: [1, 2, 3, 4],
  'zh-CN': [5, 6, 7, 8],
}

export const CountryISOCode = [
  { name: 'Panama', code: 'PA' },
  { name: 'Albania', code: 'AL' },
  { name: 'Comoros', code: 'KM' },
  { name: "Cote d'Ivoire", code: 'CI' },
  { name: 'Dem. Rep. Congo', code: 'CD' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Egypt', code: 'EG' },
  { name: 'Oman', code: 'OM' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Syria', code: 'SY' },
  { name: 'Andorran', code: 'AD' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Falkland Islnds', code: 'FK' },
  { name: 'French Guayana', code: 'GF' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Laos', code: 'LA' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'India', code: 'IN' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Italy', code: 'IT' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Somalia', code: 'SO' },
  { name: 'St. Lucia', code: 'LC' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Monaco', code: 'MC' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Tanzania', code: 'TZ' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French S.Territ', code: 'TF' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Coconut Islands', code: 'CC' },
  { name: 'Mexico', code: 'MX' },
  { name: 'USA', code: 'US' },
  { name: 'Trinidad,Tobago', code: 'TT' },
  { name: 'Macedonia', code: 'MK' },
  { name: 'Malta', code: 'MT' },
  { name: 'Moldova', code: 'MD' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Norway', code: 'NO' },
  { name: 'Palestine', code: 'PS' },
  { name: 'Poland', code: 'PL' },
  { name: 'Utd.Arab Emir.', code: 'AE' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'S. Sandwich Ins', code: 'GS' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Bosnia-Herz.', code: 'BA' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Togo', code: 'TG' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Australia', code: 'AU' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'China', code: 'CN' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Serbia/Monten.', code: 'RS' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Namibia', code: 'NA' },
  { name: 'St. Vincent', code: 'VC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Pap. New Guinea', code: 'PG' },
  { name: 'Philippines', code: 'PH' },
  { name: 'St.Pier,Miquel.', code: 'PM' },
  { name: 'Gabon', code: 'GA' },
  { name: 'West Sahara', code: 'EH' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
  { name: 'Amer.Virgin Is.', code: 'VI' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Spain', code: 'ES' },
  { name: 'Svalbard', code: 'SJ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'Turksh Caicosin', code: 'TC' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Germany', code: 'DE' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'S.Tome,Principe', code: 'ST' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Antigua/Barbuda', code: 'AG' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Japan', code: 'JP' },
  { name: 'Romania', code: 'RO' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'Angola', code: 'AO' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Equatorial Guin', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Brit.Virgin Is.', code: 'VG' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Rep.', code: 'DO' },
  { name: 'Dutch Antilles', code: 'AN' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Russian Fed.', code: 'RU' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Iran', code: 'IR' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Afghanistan', code: 'AF' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Singapore', code: 'SG' },
  { name: 'United States', code: 'US' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bouvet Islands', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'Chile', code: 'CL' },
  { name: 'Serbia', code: 'RS' },
  { name: 'St Kitts & Nevis', code: 'KN' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libya', code: 'LY' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Israel', code: 'IL' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Republic of Congo', code: 'CG' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Mali', code: 'ML' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'South Korea', code: 'KR' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Thailand', code: 'TH' },
]

export const countryData = {
  India: {
    webviewTitle: 'Please switch to browser to view our experience',
    portraitError: 'Rotate your phone to landscape mode',
  },
  China: {
    webviewTitle: '点击右上角浏览器打开',
    portraitError: '将手机旋转到横向模式',
  },
}

// Tween points
export const TweenTargets = {
  '/thankyou': {
    position: [-4, 2, -3],
    control: [0, 0.8, 0],
    controlEnabled: false,
  },
  // ! Continua
  '/continua': {
    position: [-4, 2, -3],
    control: [0, 0.8, 0],
    controlEnabled: true,
  },
  '/continua/coatings': {
    position: [-3.1178, 1.1802, -2.777],
    control: [1.6428, 1.17, -0.98],
    controlEnabled: false,
  },
  '/continua/mrg': {
    position: [-3.5835, 1.8453, -1.0228],
    control: [-1.108, 0.7225, -0.7317],
    controlEnabled: false,
  },
  '/continua/tyre': {
    position: [1.6829, 0.7418, -1.9449],
    control: [1.4307, 0.5329, -1.0451],
    controlEnabled: false,
  },
  '/continua/plastics': {
    position: [-4.6679, 1.8255, -0.527],
    control: [-0.5, 0.2, -1.0],
    controlEnabled: false,
  },
  // ! Birla Carbon
  '/': {
    position: [-4, 2, -3],
    control: [0, 0.8, 0],
    controlEnabled: true,
  },
  '/coatings': {
    position: [-2.739, 1.757, -3.276],
    control: [-0.23, 0.71, 0.28],
    controlEnabled: false,
  },
  '/coatings/roof': {
    position: [-0.8, 2, -2],
    control: [0.3, 1.6, 0],
    controlEnabled: false,
  },

  '/coatings/roof/clear': {
    position: [2, 2, -1],
    control: [1, 1.7, 0.5],
    controlEnabled: false,
  },

  '/coatings/roof/metal': {
    position: [-0.55, 2.0, -0.91],
    control: [0.38, 1.49, 0.03],
    controlEnabled: false,
  },

  '/coatings/roof/base': {
    position: [-0.18, 1.98, -0.96],
    control: [1.23, 1.76, -0.05],
    controlEnabled: false,
  },

  '/coatings/roof/primer': {
    position: [-0.8, 2, -1],
    control: [0.6, 1.8, 0],
    controlEnabled: false,
  },
  '/coatings/color': {
    position: [-4, 1.5, -3],
    control: [-0.5, 0.4, 0],
    controlEnabled: false,
  },
  '/coatings/leathercoatings': {
    position: [-0.544, 1.094, -0.695],
    control: [4.5, 0.5, 0.5],
    controlEnabled: false,
  },
  '/coatings/interior': {
    position: [-0.2, 1.1, -0.1],
    control: [-1, 0.8, 0.2],
    controlEnabled: false,
  },
  '/coatings/powder': {
    position: [1.5, 0.6, -2],
    control: [1.4, 0.5, -0.9],
    controlEnabled: false,
  },
  '/tyre': {
    position: [1.2, 0.8, -2.3],
    control: [1, 0.5, -1],
    controlEnabled: false,
  },
  '/tyre/interior': {
    position: [1.741, 1.061, -1.995],
    control: [1.2, 0.5, -1.76],
    controlEnabled: false,
  },
  '/tyre/exterior': {
    position: [1.6, 0.8, -1.9],
    control: [1.2, 0.5, -1.2],
    controlEnabled: false,
  },

  '/tyre/exterior/tread': {
    position: [1.25, 1.113, -1.3],
    control: [1.25, 0.5, -1.27],
    controlEnabled: false,
  },

  '/tyre/exterior/subtread': {
    position: [1.239, 1.016, -1.456],
    control: [1.35, 0.5, -1.3],
    controlEnabled: false,
  },

  '/tyre/exterior/sidewall': {
    position: [1.35, 0.8, -1.7],
    control: [1.25, 0.6, -1.3],
    controlEnabled: false,
  },
  '/tyre/interior/bead': {
    position: [1.523, 0.569, -2.026],
    control: [1.41, 0.51, -1.8],
    controlEnabled: false,
  },
  '/tyre/interior/beadapex': {
    position: [1.489, 0.551, -1.992],
    control: [1.4, 0.51, -1.8],
    controlEnabled: false,
  },

  '/tyre/interior/belt': {
    position: [1.525, 0.848, -1.96],
    control: [1.31, 0.7, -1.66],
    controlEnabled: false,
  },

  '/tyre/interior/capplies': {
    position: [1.485, 0.815, -2.008],
    control: [1.31, 0.6, -1.66],
    controlEnabled: false,
  },

  '/tyre/interior/radialplies': {
    position: [1.543, 0.742, -2.01],
    control: [1.31, 0.5, -1.66],
    controlEnabled: false,
  },

  '/tyre/interior/innerliner': {
    position: [1.555, 0.643, -1.937],
    control: [1.31, 0.4, -1.66],
    controlEnabled: false,
  },

  '/mrg': {
    position: [-3.155, 1.795, -0.726],
    control: [-1.3, 0.8, 0],
    controlEnabled: false,
  },
  '/mrg/wipers': {
    position: [-2.109, 1.766, -1.059],
    control: [-0.05, 0.2, -0.225295925],
    controlEnabled: false,
  },
  '/mrg/weatherstrips': {
    position: [-2.17, 1.839, 1.771],
    control: [-0.5, 0.6, -0.76663395333],
    controlEnabled: false,
  },
  '/mrg/coolanthose': {
    position: [-2.112, 0.957, -0.671],
    control: [-0.6, -0.4, 1.0],
    controlEnabled: false,
  },
  '/mrg/pneumatichose': {
    position: [-1.914, 1.235, 0.535],
    control: [-1.81, 0.84, 0.21],
    controlEnabled: false,
  },
  '/mrg/antivibrationbushings': {
    position: [-2.215, 0.644, -0.364],
    control: [-0.91, 0.41, -1.03],
    controlEnabled: false,
  },
  '/mrg/antivibrationmounts': {
    position: [-2.846, 1.159, 0.274],
    control: [-2.4, 0.6, -0.175518848274],
    controlEnabled: false,
  },
  '/mrg/drivebelt': {
    position: [-2.2, 0.843, -0.333],
    control: [-1.93, 0.5, -0.28],
    controlEnabled: false,
  },

  '/inks': {
    position: [0.997, 1.301, -0.001],
    control: [-0.03, 0.47, 0],
    controlEnabled: false,
  },
  '/inks/uvink': {
    position: [0.536, 0.616, 0.387],
    control: [0.3, 0.34, 0.68],
    controlEnabled: false,
  },
  '/inks/conductive': {
    position: [0.101, 0.786, 0.063],
    control: [-0.17, 0.61, 0.03],
    controlEnabled: false,
  },
  '/inks/coffee': {
    position: [-0.062, 0.727, -0.015],
    control: [-0.43, 0.72, 0.1],
    controlEnabled: false,
  },
  '/inks/newspaper': {
    position: [0.549, 0.717, -0.196],
    control: [0.12, -0.02, -0.43],
    controlEnabled: false,
  },
  '/inks/magazine': {
    position: [0.59, 0.7, 0.18],
    control: [0.39, 0.26, 0.48],
    controlEnabled: false,
  },
  '/inks/printout': {
    position: [0.312, 0.821, -0.332],
    control: [0.22, 0.43, -0.42],
    controlEnabled: false,
  },

  '/energy': {
    position: [-5.1169, 1.526, 1.9285],
    control: [-0.1, 0.36, 0],
    controlEnabled: false,
  },
  '/energy/lead': {
    position: [-2.654, 1.016, 0.05],
    control: [-1.43, 0.43, 0.45],
    controlEnabled: false,
  },
  '/energy/lithium': {
    position: [-4.9205, 2.386, 1.8768],
    control: [0, 0.8, 0],
    controlEnabled: false,
  },
  '/energy/lithium/cb_cnt': {
    position: [-2.8316, 0.8, 2.301],
    control: [0, 0.8, 0],
    controlEnabled: false,
  },
  '/energy/lithium/conductex': {
    position: [-1.7131, 1.0493, 3.003],
    control: [0, 0.8, 0],
    controlEnabled: false,
  },
  '/energy/lithium/graphite': {
    position: [-1.5368, 1.0976, 3.9574],
    control: [0, 0.8, 0],
    controlEnabled: false,
  },

  '/niche': {
    position: [-3.993, 2.061, -3.04],
    control: [-0.1, 0.36, 0],
    controlEnabled: false,
  },
  '/niche/puseatfoam': {
    position: [-0.92, 1.1, -0.5],
    control: [1.9, 0.6, 1],
    controlEnabled: false,
  },
  '/niche/windshield': {
    position: [-1.99, 1.76, -1.67],
    control: [1.08, 0.5, 0.33],
    controlEnabled: false,
  },
  '/niche/battery': {
    position: [-2.654, 1.016, 0.05],
    control: [-1.43, 0.43, 0.45],
    controlEnabled: false,
  },
  '/niche/brakepad': {
    position: [0.554, 0.635, -2.603],
    control: [1.65, 0.41, -0.83],
    controlEnabled: false,
  },

  '/plastics': {
    position: [-4.088574864517818, 1.2179911812540927, 2.6125650046624185],
    control: [-0.3616246222927349, 0.6544127438045062, -0.014629431810622909],
    controlEnabled: false,
  },

  '/plastics/interior': {
    position: [0.1142, 1.1866, -0.1094],
    control: [-0.71, 0.75, -0.1],
    controlEnabled: false,
  },

  '/plastics/interior/dashboardpanel': {
    position: [-0.4549, 1.1276, -0.0055],
    control: [-1.0, 0.95, -0.3],
    controlEnabled: false,
  },

  '/plastics/interior/steeringwheel': {
    position: [-0.1176, 1.0125, 0.2339],
    control: [-0.7, 0.9, 0.58],
    controlEnabled: false,
  },

  '/plastics/interior/glovebox': {
    position: [-0.1543, 0.7859, -0.405],
    control: [-0.71, 0.75, -0.26],
    controlEnabled: false,
  },

  '/plastics/interior/centerconsole': {
    position: [-0.0178, 0.9843, -0.0753],
    control: [-0.71, 0.72, 0.23],
    controlEnabled: false,
  },

  '/plastics/interior/carpet': {
    position: [-0.4051, 0.9028, -0.1149],
    control: [-0.75, 0.62, -0.12],
    controlEnabled: false,
  },

  '/plastics/exterior': {
    position: [-4.1012, 1.2593, 1.4792],
    control: [-0.5, 0.2, -1.0],
    controlEnabled: false,
  },

  '/plastics/exterior/radiatorgrill': {
    position: [-4.13, 0.6, 1.33],
    control: [-1.14, 0.52, -2.01],
    controlEnabled: false,
  },

  '/plastics/exterior/airintake': {
    position: [-2.85, 1.67, 0.13],
    control: [-0.77, -0.18, -0.96],
    controlEnabled: false,
  },
  '/driverseat': {
    position: [-0.049, 1.1, 0.524],
    control: [-0.3300165857052462, 1.003171629202039, 0.32599314857128936],
    controlEnabled: false,
  },
  '/rearseat': {
    // position: [0.8452942858149036, 1.2376899583058278, -0.6279482176091703],
    // control: [ -0.057878359121228434 , 0.8867494167128331, 0.012987908459556453],
    // controlEnabled:false
    position: [0.8900193027940335, 1.1435181581991847, -0.6423693037923669],
    control: [0.6837961370690817, 1.0563201589168338, -0.4729779090518021],
    controlEnabled: false,
  },
  '/frontpassengerseat': {
    position: [-0.04039105665664344, 1.1430438805643472, -0.53182809311865],
    control: [-1.2082954502785526, 0.6615765041814089, 0.19594066573575883],
    // //-1.2082954502785526 , 0.6615765041814089, 0.19594066573575883
    // //-0.25522099327257786, 1.0755919279427153, -0.24318465658471058
    controlEnabled: false,
  },
}

export const TotalLoaders = [
  {
    desktop: ['all', 'WebsiteData', 'renderStuff'],
    mobile: ['set1', 'set2', 'set3', 'WebsiteData'],
  },
]
// export const baseURL = "/staging/birla-carbon"
export const baseURL = ''
