import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'wouter'
import { useBehaviour } from '../../stores'
import ComponentDetailsCard from './ComponentDetailsCard'

export default function DetailsPage(props) {
  const [location] = useLocation()

  const currentLocation = useRef()
  const { setCurrentProduct } = useBehaviour()

  const loc = ['/coatings/roof', '/coatings/color']

  const [locState, setLocState] = useState(false)

  useEffect(() => {
    currentLocation.current = props.component[location]
    locationCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function locationCheck() {
    for (var i = 0; i < loc.length; i++) {
      if (location === loc[i]) {
        setLocState(true)
        break
      } else {
        setLocState(false)
      }
    }
  }

  useEffect(() => {
    if (location in props.component) {
      setCurrentProduct(props.component[location].element)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      <ComponentDetailsCard
        type="left"
        enableButtons={location in props.component || locState}
        enabled={location in props.component}
        {...props.component[location]}
        titles={props.title}
      />
    </>
  )
}
