import ReactGA from 'react-ga4'

export function sendGAEvent(category, action, label) {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  })
}

export function sendGAEventWithValue(category, action, label) {
  ReactGA.event({
    category: category,
    action: action,
    value: label,
    label: label,
  })
}

export function sendGAEventWithValueLabel(category, action, label, value) {
  ReactGA.event({
    category: category,
    action: action,
    value: value,
    label: label,
  })
}

export function sendGATiming(category, variable, value, label) {
  ReactGA.timing({
    category: category,
    variable: variable,
    value: value,
    label: label,
  })
}

export function sendGAPageview(path) {
  ReactGA.pageview(path)
}

export function sendCustomDimensions(value) {
  // ReactGA.set({
  //   downloadSpeed : value
  // })
  ReactGA.ga('set', 'downloadSpeed', value)
}
