import React from 'react';

export default function RotateSign(){
    return(
        <>
        <svg viewBox="0 0 51 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.344 25.5H7.96879C7.08852 25.5 6.375 24.7865 6.375 23.9062C6.375 23.026 7.08852 22.3125 7.96879 22.3125H12.7502V12.75H7.96879C7.08852 12.75 6.375 12.0365 6.375 11.1562C6.375 10.276 7.08852 9.5625 7.96879 9.5625H14.344C15.2242 9.5625 15.9377 10.276 15.9377 11.1562V23.9062C15.9377 24.7865 15.2242 25.5 14.344 25.5Z" fill="white"/>
            <path d="M25.5001 25.5H22.3125C20.5521 25.5 19.125 24.0729 19.125 22.3125V12.75C19.125 10.9896 20.5521 9.5625 22.3125 9.5625H27.0939C27.9741 9.5625 28.6876 10.276 28.6876 11.1562C28.6876 12.0365 27.9741 12.75 27.0939 12.75H22.3125V15.9375H25.5001C27.2605 15.9375 28.6876 17.3646 28.6876 19.125V22.3125C28.6876 24.0729 27.2605 25.5 25.5001 25.5ZM22.3125 19.125V22.3125H25.5001V19.125H22.3125Z" fill="white"/>
            <path d="M38.2498 25.5H35.0624C33.3021 25.5 31.875 24.0729 31.875 22.3125V12.75C31.875 10.9896 33.3021 9.5625 35.0624 9.5625H38.2498C40.0101 9.5625 41.4372 10.9896 41.4372 12.75V22.3125C41.4372 24.0729 40.0101 25.5 38.2498 25.5ZM35.0624 12.75V22.3125H38.2498V12.75H35.0624Z" fill="white"/>
            <path d="M14.344 19.125H7.96879C7.08852 19.125 6.375 18.4115 6.375 17.5312C6.375 16.651 7.08852 15.9375 7.96879 15.9375H14.344C15.2242 15.9375 15.9377 16.651 15.9377 17.5312C15.9377 18.4115 15.2242 19.125 14.344 19.125Z" fill="white"/>
            <path d="M25.4999 38.25C11.2008 38.25 0 32.6496 0 25.5C0.128894 22.7697 1.53677 20.2599 3.79948 18.7266C4.49396 18.1844 5.49652 18.3078 6.03869 19.0023C6.58086 19.6968 6.45745 20.6993 5.76297 21.2415C4.30799 22.2017 3.3621 23.7655 3.18749 25.5C3.18749 30.0231 12.3515 35.0625 25.4999 35.0625C26.3801 35.0625 27.0936 35.776 27.0936 36.6563C27.0936 37.5365 26.3801 38.25 25.4999 38.25Z" fill="white"/>
            <path d="M30.2815 38.026C29.4012 38.0683 28.6535 37.389 28.6112 36.5088C28.569 35.6285 29.2482 34.8808 30.1285 34.8385C40.2104 33.8727 47.8124 29.8533 47.8124 25.4991C47.6378 23.7646 46.6919 22.2008 45.237 21.2406C44.5434 20.6984 44.4207 19.6966 44.9629 19.003C45.505 18.3094 46.5069 18.1867 47.2004 18.7289C49.4637 20.2601 50.872 22.7694 50.9999 25.4991C50.9999 31.6988 42.5436 36.8498 30.4345 38.0196L30.2815 38.026Z" fill="white"/>
            <path d="M20.7188 43.0313C19.8385 43.0329 19.1237 42.3206 19.1221 41.4404C19.1213 41.0161 19.2898 40.6089 19.5903 40.3092L23.2465 36.6563L19.5903 33.0034C18.9672 32.3803 18.9672 31.3698 19.5903 30.7467C20.2135 30.1235 21.224 30.1235 21.8472 30.7467L26.6285 35.5279C27.2517 36.1495 27.253 37.1586 26.6314 37.7818C26.6304 37.7828 26.6295 37.7837 26.6285 37.7847L21.8472 42.5659C21.5477 42.8647 21.1417 43.0321 20.7188 43.0313Z" fill="white"/>
            <path d="M46.2187 9.5625C43.5781 9.5625 41.4375 7.42189 41.4375 4.78125C41.4375 2.14061 43.5781 0 46.2187 0C48.8593 0 50.9999 2.14061 50.9999 4.78125C50.9999 7.42189 48.8593 9.5625 46.2187 9.5625ZM46.2187 3.1875C45.3385 3.1875 44.625 3.901 44.625 4.78125C44.625 5.6615 45.3385 6.375 46.2187 6.375C47.0989 6.375 47.8124 5.6615 47.8124 4.78125C47.8124 3.901 47.0989 3.1875 46.2187 3.1875Z" fill="white"/>
        </svg>
        </>
    )
}