import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import RightArrow from '../../components/icons/RightArrow'
import CloseIcon from '../../components/icons/CloseIcon'
import { useBehaviour, useHud, useSFDC } from '../../stores'
import { fadeIn, fadeOut } from 'react-animations'
// import useGeoLocation from "react-ipgeolocation";

export default function SFDCForm(props) {
  const { setHideHotspots } = useHud()
  const { submitForm, setShowForm, setShowFormLearnMore, showForm } = useSFDC()
  const [isFadeOut, setIsFadeOut] = useState(false)
  // const [state, setState] = useState({
  //   showFormDetails: false,
  // })
  const [showFormDetails, setShowFormDetails] = useState(false)

  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState({ done: false, resp: false })
  const [submit, setSubmit] = useState(false)
  const formVisibility = useSFDC()

  const [form, setForm] = useState({
    firstName: '',
    Email: '',
    Title: '',
    City: '',
    lastName: '',
    Phone: '',
    Company: '',
  })

  function onSubmit() {
    setSubmit(true)
    let count = 0
    for (const value in form) {
      // console.log(value)
      if (form[value] === '') {
        document.getElementById(value).style.border = '1px solid red'
      } else {
        count = count + 1
      }
    }
    if (count >= 6) {
      setSuccess({ done: false, resp: false })
      setSending(true)
      submitForm(form, useBehaviour.getState().display, onFormResponse)
    } else {
      count = 0
    }
  }

  function onFormResponse(resp) {
    if (resp) {
      setSuccess({ done: true, resp: resp })
      setTimeout(() => {
        formVisibility.setShowForm(false)
        setSending(false)
        setHideHotspots(false)
      }, 3000)
    } else {
      setSuccess({ ...success, done: true, resp: false })
      setSending(false)
      setHideHotspots(false)
    }
  }

  const [form1, setForm1] = useState(false)

  useEffect(() => {
    const unsub = useSFDC.subscribe((form) => {
      // console.log(hots)
      // console.log('setting ' + hots.hotspots)
      setSuccess({ ...success, done: false, resp: false })
      setShowFormDetails(form.showForm)
      if (!form) {
        setSubmit(false)
      }
    })

    return function cleanup() {
      unsub()
    }
  }, [])

  useEffect(() => {
    if (showFormDetails != form1) {
      // console.log('setting ' + hotspots)
      // console.log(hotspots)
      setForm1(showFormDetails)
    }
  }, [showFormDetails])

  useEffect(() => {
    if (submit) {
      for (const value in form) {
        if (form[value] !== '') {
          document.getElementById(value).style.border = 'none'
        } else {
          document.getElementById(value).style.border = '1px solid red'
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  function fadeOutAnim() {
    setIsFadeOut(true)
    // //console.log("fadeAnim")
  }

  function closeForm() {
    setHideHotspots(false)
    // formVisibility.setShowForm(false)
    setShowForm(false)
    setIsFadeOut(false)
    setShowFormDetails(false)
    // getGeoInfo()
    // setState({
    //     showFormDetails: false,
    //     // fade: false
    // })
  }

  function openDetailsPanel() {
    setShowFormLearnMore(false)
  }

  // (async () => {
  //     await ipLocation("192.168.1.7");
  //     //=> { latitude: -33.8591, longitude: 151.2002, region: { name: "New South Wales" ... } ... }
  // })();

  return (
    <>
      <div
        className={css(
          form1 ? styles.container : styles.displayNone,
          // state.showFormDetails ? styles.container : styles.container,

          // formVisibility.showForm ? styles.container : styles.displayNone,
          isFadeOut ? styles.fade : styles.animContainer
        )}
      >
        <div className={css(styles.closeButtonContainer)}>
          <button
            className={css(styles.closeButton)}
            onClick={() => {
              fadeOutAnim()
              closeForm()
              // setTimeout(() => closeForm(), 500)
              openDetailsPanel()
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <div className={css(styles.title)}>
          {props.data['sfdc_enterdetails'] != null
            ? props.data['sfdc_enterdetails'].title
            : 'Enter your details'}
        </div>

        <div className={css(styles.detailArea)}>
          <div className={css(styles.row1)}>
            <div className={css(styles.column1)}>
              <label className={css(styles.label)}>
                {props.data['sfdc_firstname'] != null
                  ? props.data['sfdc_firstname'].title
                  : 'First Name'}{' '}
                *
              </label>
              <input
                id="firstName"
                style={{ border: 'none' }}
                className={css(styles.field)}
                type="text"
                name=""
                onChange={(c) =>
                  setForm({ ...form, firstName: c.target.value })
                }
                required
              />
            </div>
            <div className={css(styles.column1)}>
              <label className={css(styles.label)}>
                {props.data['sfdc_lastname'] != null
                  ? props.data['sfdc_lastname'].title
                  : 'Last Name'}{' '}
                *
              </label>
              <input
                id="lastName"
                style={{ border: 'none' }}
                className={css(styles.field)}
                type="text"
                name=""
                onChange={(c) => setForm({ ...form, lastName: c.target.value })}
              />
            </div>
          </div>
          <div className={css(styles.row1)}>
            <div className={css(styles.column1)}>
              <label className={css(styles.label)}>
                {props.data['sfdc_phone'] != null
                  ? props.data['sfdc_phone'].title
                  : 'Phone Number'}{' '}
                *
              </label>
              <input
                id="Phone"
                style={{ border: 'none' }}
                className={css(styles.field)}
                type="text"
                name=""
                onChange={(c) => setForm({ ...form, Phone: c.target.value })}
              />
            </div>
            <div className={css(styles.column1)}>
              <label className={css(styles.label)}>
                {props.data['sfdc_email'] != null
                  ? props.data['sfdc_email'].title
                  : 'Email'}{' '}
                *
              </label>
              <input
                id="Email"
                style={{ border: 'none' }}
                className={css(styles.field)}
                type="text"
                name=""
                onChange={(c) => setForm({ ...form, Email: c.target.value })}
                required
              />
            </div>
          </div>
          <div className={css(styles.row1)}>
            <div className={css(styles.column1)}>
              <label id="Company" className={css(styles.label)}>
                {props.data['sfdc_company'] != null
                  ? props.data['sfdc_company'].title
                  : 'Company'}{' '}
                *
              </label>
              <input
                style={{ border: 'none' }}
                className={css(styles.field)}
                type="text"
                name=""
                onChange={(c) => setForm({ ...form, Company: c.target.value })}
              />
            </div>
            <div className={css(styles.column1)}>
              <label className={css(styles.label)}>
                {props.data['sfdc_title'] != null
                  ? props.data['sfdc_title'].title
                  : 'Title'}
                *
              </label>
              <input
                id="Title"
                style={{ border: 'none' }}
                className={css(styles.field)}
                type="text"
                name=""
                onChange={(c) => setForm({ ...form, Title: c.target.value })}
                required
              />
            </div>
          </div>
          <div className={css(styles.row1)}>
            <div className={css(styles.column1, styles.columnHeight)}>
              <div className={css(styles.column2)}>
                <label className={css(styles.label)}>
                  {props.data['sfdc_city'] != null
                    ? props.data['sfdc_city'].title
                    : 'City'}{' '}
                  *
                </label>
                <input
                  id="City"
                  style={{ border: 'none' }}
                  className={css(styles.field1)}
                  type="text"
                  name=""
                  onChange={(c) => setForm({ ...form, City: c.target.value })}
                  required
                />
              </div>
              <div className={css(styles.column2)}>
                <label className={css(styles.label)}>
                  {props.data['sfdc_state'] != null
                    ? props.data['sfdc_state'].title
                    : 'State'}
                </label>
                <input
                  style={{ border: 'none' }}
                  className={css(styles.field1)}
                  type="text"
                  name=""
                  onChange={(c) => setForm({ ...form, State: c.target.value })}
                />
              </div>
            </div>
            <div className={css(styles.column1)}>
              <label className={css(styles.label, styles.streetLabel)}>
                {props.data['sfdc_street'] != null
                  ? props.data['sfdc_street'].title
                  : 'Street'}
              </label>
              <input
                style={{ border: 'none' }}
                className={css(styles.field, styles.streetField)}
                type="text"
                name=""
                onChange={(c) => setForm({ ...form, Street: c.target.value })}
              />
            </div>
          </div>
          <div className={css(styles.row1, styles.commentRow)}>
            <div className={css(styles.column3)}>
              <label className={css(styles.label)}>
                {props.data['sfdc_comments'] != null
                  ? props.data['sfdc_comments'].title
                  : 'Comments'}
              </label>
              <textarea
                className={css(styles.comment)}
                name="comments"
                onChange={(c) => setForm({ ...form, Comment: c.target.value })}
              ></textarea>
            </div>
          </div>
          <div className={css(styles.row1)}>
            <div className={css(styles.column4)}>
              <button className={css(styles.btn)} onClick={onSubmit}>
                {props.data['sfdc_submit']
                  ? props.data['sfdc_submit'].title
                  : 'Submit'}
                <RightArrow />
              </button>
            </div>
          </div>
        </div>
        {sending && !success.done && (
          <div className={css(styles.screenBlocker)}>
            {props.data['sfdc_response_wait'].title}
          </div>
        )}
        {success.done && success.resp && (
          <div className={css(styles.screenBlocker)}>
            {props.data['sfdc_success'].title}
          </div>
        )}
        {success.done && !success.resp && (
          <div className={css(styles.screenBlockerError)}>
            {props.data['sfdc_error'].title}
          </div>
        )}
      </div>
    </>
  )
}

const styles = StyleSheet.create({
  screenBlocker: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: '#101010af',
    textAlign: 'center',
    color: 'white',
    fontSize: 30,
    fontWeight: 'bold',
    paddingTop: '20%',
  },
  screenBlockerError: {
    pointerEvents: 'none',
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: '#101010af',
    textAlign: 'center',
    color: 'white',
    fontSize: 30,
    fontWeight: 'bold',
    paddingTop: '20%',
    animationName: [fadeOut],
    animationDuration: '6s',
    animationDelay: 2000,
    animationFillMode: 'forwards',
  },

  containerTemp: {
    position: 'absolute',
    pointerEvents: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '50%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: '60px auto',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    borderRadius: 12,
  },

  container: {
    position: 'absolute',
    pointerEvents: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: 1159,
    // height: '100vh',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 12,
    backgroundColor: '#3C3C3C',
    zIndex: 1,

    overflowY: 'auto',
    overflowX: 'hidden',

    '@media(max-width: 575.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 450,
      bottom: 0,
      top: 60,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 450,
        bottom: 0,
        marginTop: 60,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 600,
        marginTop: 60,
        bottom: 0,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        width: 900,
        height: 610,
        marginTop: 180,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        width: 900,
        height: 615,
        marginTop: 210,
      },

    // Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      width: 900,
      // height: 900,

      height: '80%',
      marginTop: 120,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      width: 900,
      // height: 615,
      height: '80%',
      marginTop: 110,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      width: 1150,
      // height: 630,
      marginTop: 120,
      height: '80%',
      bottom: 0,
    },
    '@media(min-width: 1600px) and (max-width: 1919.98px)': {
      width: 1150,
      height: 650,
      // height: '80%',
      top: 165,
    },
    '@media(min-width: 1920px) and (max-width: 2559.98px)': {
      width: 1150,
      height: 650,
      // height: '80%',
      top: 210,
    },
    '@media(min-width: 2560px)': {
      marginTop: 280,
      // height: 630,
      height: '80%',
    },
  },
  animContainer: {
    animationName: [fadeIn],
    animationDuration: '1s, 300ms',
  },
  fade: {
    animationName: [fadeOut],
    animationDuration: '1s, 300ms',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    // marginTop: 20,
    marginLeft: 40,
    color: '#ffffff',
    fontSize: 40,
    height: 60,
    // width: '100%',
    // backgroundColor: 'red',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 25,
      height: 30,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 25,
        height: 30,
        marginTop: -10,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 25,
        height: 30,
        marginLeft: 50,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        fontSize: 30,
        height: 50,
        marginLeft: 50,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 30,
        height: 50,
        marginLeft: 50,
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      fontSize: 30,
      height: 50,
      marginLeft: 50,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 30,
      height: 50,
      marginLeft: 50,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      height: 50,
      fontSize: 35,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      height: 50,
    },
    '@media(min-width: 2560px)': {
      height: 50,
    },
  },
  closeButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: 10,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      marginTop: 15,
      // marginLeft: 10
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        marginTop: 15,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        marginTop: 15,
      },
    // backgroundColor: 'black'
  },
  closeButton: {
    outline: 'none',
    border: 0,
    background: 'none',
    height: 40,
    marginRight: 20,
    zIndex: 1,
    cursor: 'pointer',
    pointerEvents: 'auto',
    // backgroundColor: 'blue',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      height: 20,
      marginRight: 10,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        height: 20,
        marginRight: 10,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        height: 20,
        marginRight: 10,
      },
  },
  detailArea: {
    display: 'flex',
    flexDirection: 'column',
    // height: 400,
    width: '100%',
    // backgroundColor: 'white',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      // flexGrow: 2,
      marginTop: 5,

      '::-webkit-scrollbar': {
        width: 20,
      },
      '::-webkit-scrollbar-thumb': {
        background: '#DBDBDB',
        height: 100,
        borderRadius: 8,
        borderLeft: '5px solid rgba(0, 0, 0, 0)',
        borderRight: '5px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.025)',
      },
      '::-webkit-scrollbar-track': {
        // width: 2,
        // height: 357,
        borderRadius: 52,
        background: '#5C5C5C',
        borderLeft: '8px solid #3C3C3C',
        borderRight: '8px solid #3C3C3C',
      },
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        // height: 600,
        marginTop: 5,

        '::-webkit-scrollbar': {
          width: 20,
        },
        '::-webkit-scrollbar-thumb': {
          background: '#DBDBDB',
          height: 100,
          borderRadius: 8,
          borderLeft: '5px solid rgba(0, 0, 0, 0)',
          borderRight: '5px solid rgba(0, 0, 0, 0)',
          backgroundClip: 'padding-box',
          boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.025)',
        },
        '::-webkit-scrollbar-track': {
          // width: 2,
          // height: 357,
          borderRadius: 52,
          background: '#5C5C5C',
          borderLeft: '8px solid #3C3C3C',
          borderRight: '8px solid #3C3C3C',
        },
        overflowY: 'scroll',
        overflowX: 'hidden',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        // height: 650,
        marginTop: 5,
        '::-webkit-scrollbar': {
          width: 20,
        },
        '::-webkit-scrollbar-thumb': {
          background: '#DBDBDB',
          height: 100,
          borderRadius: 8,
          borderLeft: '5px solid rgba(0, 0, 0, 0)',
          borderRight: '5px solid rgba(0, 0, 0, 0)',
          backgroundClip: 'padding-box',
          boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.025)',
        },
        '::-webkit-scrollbar-track': {
          // width: 2,
          // height: 357,
          borderRadius: 52,
          background: '#5C5C5C',
          borderLeft: '8px solid #3C3C3C',
          borderRight: '8px solid #3C3C3C',
        },
        overflowY: 'scroll',
        overflowX: 'hidden',
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        marginTop: 5,
        // height: 400,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        marginTop: 5,
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      marginTop: 5,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      marginTop: 5,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      marginTop: 5,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      marginTop: 5,
    },
    '@media(min-width: 2560px)': {
      marginTop: 5,
    },
  },
  row1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    // backgroundColor: 'blue',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      flexDirection: 'column',
      height: '100%',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        flexDirection: 'column',
        height: '100%',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        flexDirection: 'column',
        height: '100%',
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        flexDirection: 'row',
        width: '100%',
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        flexDirection: 'row',
        width: '100%',
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      flexDirection: 'row',
      width: '100%',
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      flexDirection: 'row',
      width: '100%',
    },
  },
  column1: {
    flexDirection: 'column',
    height: 70,
    width: '100%',
    flexWrap: 'wrap',
    // backgroundColor:'red',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      height: 80,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        height: 90,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        height: 90,
      },
  },
  columnHeight: {
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      height: 160,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        height: 180,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        height: 180,
      },
  },
  column2: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    float: 'left',
    // backgroundColor:'blue',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: '100%',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: '100%',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: '100%',
      },
  },
  column3: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  commentRow: {
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      marginTop: 50,
    },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        marginTop: 70,
      },
  },
  column4: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      flexDirection: 'column',
      marginTop: 10,
      marginBottom: 10,
      // marginLeft: 10
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        flexDirection: 'column',
        marginTop: 10,
        marginBottom: 10,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        flexDirection: 'column',
        marginTop: 10,
        marginBottom: 10,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        marginTop: 20,
        marginLeft: 650,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        marginTop: 30,
        marginLeft: 650,
      },
    // Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      marginTop: 20,
      marginLeft: 650,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      marginTop: 20,
      marginLeft: 650,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      marginTop: 20,
      marginLeft: 900,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      marginTop: 20,
      marginLeft: 900,
    },
    '@media(min-width: 2560px)': {
      marginTop: 30,
      marginBottom: 30,
      marginLeft: 900,
    },
  },
  label: {
    textAlign: 'left',
    flex: 'none',
    marginLeft: 10,
    marginBottom: 5,
    marginTop: 10,
    color: '#ffffff',
    display: 'block',
    fontSize: 15,
    fontFamily: 'Oxygen-Regular',
    fontWeight: 'normal',
    // backgroundColor:'yellow',

    '@media(max-width: 575.98px) and (orientation:landscape)': {
      marginLeft: 40,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        marginLeft: 40,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        marginLeft: 50,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        marginLeft: 50,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        marginLeft: 50,
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      marginLeft: 50,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      marginLeft: 50,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      marginLeft: 50,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      marginLeft: 50,
    },
    '@media(min-width: 2560px)': {
      marginLeft: 50,
    },
  },
  field: {
    height: 36,
    borderRadius: 12,
    // backgroundColor: '#ffffff',
    paddingLeft: 20,
    outline: 'none',
    backgroundColor: '#8F8F8F',
    color: '#FFFFFF',
    fontSize: 15,
    fontFamily: 'Oxygen-Regular',
    fontWeight: 'normal',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      marginLeft: 40,
      width: 350,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        marginLeft: 40,
        width: 350,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        marginLeft: 50,
        width: 500,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        marginLeft: 40,
        width: 370,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        marginLeft: 40,
        width: 370,
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      marginLeft: 40,
      width: 370,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      marginLeft: 40,
      width: 370,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      marginLeft: 40,
      width: 495,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      width: '80%',
      marginLeft: 50,
      // width: 495,
    },
    '@media(min-width: 2560px)': {
      marginLeft: 40,
      width: 495,
    },
  },
  field1: {
    // width: '100%',
    width: 204,
    height: 36,
    borderRadius: 12,
    // backgroundColor: '#ffffff',
    paddingLeft: 20,
    outline: 'none',
    backgroundColor: '#8F8F8F',
    color: '#FFFFFF',
    fontSize: 15,
    fontFamily: 'Oxygen-Regular',
    fontWeight: 'normal',
    marginLeft: 45,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 350,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 350,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 500,
        marginLeft: 50,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        width: 150,
        // marginLeft: 50
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        width: 150,
      },
    // Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      width: 150,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      width: 150,
    },
  },
  comment: {
    borderRadius: 12,
    outline: 'none',
    overflow: 'hidden',
    height: 142,
    width: 1000,
    paddingTop: 20,
    paddingLeft: 20,
    marginLeft: 40,
    backgroundColor: '#8F8F8F',
    color: '#FFFFFF',
    fontSize: 15,
    fontFamily: 'Oxygen-Regular',
    fontWeight: 'normal',
    // marginRight: 40,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: 350,
      height: 100,
      marginLeft: 40,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: 350,
        height: 100,
        marginLeft: 40,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: 500,
        height: 100,
        marginLeft: 50,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        width: 820,
        height: 100,
        marginLeft: 40,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        width: 820,
        height: 100,
        marginLeft: 40,
      },
    // Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      width: 820,
      height: 100,
      marginLeft: 40,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      width: 820,
      height: 100,
      marginLeft: 40,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      width: 1070,
      height: 110,
      marginLeft: 40,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      width: 1050,
      height: 110,
      marginLeft: 40,
    },
    '@media(min-width: 2560px)': {
      marginLeft: 40,
      width: 1070,
      height: 110,
    },
  },
  btn: {
    outline: 'none',
    border: 'none',
    backgroundColor: '#ffffff',
    fontFamily: 'Roboto-Regular',
    color: '#000000',
    width: 224,
    height: 53,
    borderRadius: 8,
    cursor: 'pointer',
    pointerEvents: 'auto',
    marginBottom: '20px',
    // marginRight: 40,
    // marginTop: 40,
    // marginBottom: 40
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      marginLeft: 220,
      width: 170,
      height: 40,
      fontSize: 12,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        marginLeft: 220,
        width: 170,
        height: 40,
        fontSize: 12,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        marginLeft: 380,
        width: 170,
        height: 40,
        fontSize: 12,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        marginRight: 40,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        marginRight: 40,
      },
    // Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      marginRight: 40,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      marginRight: 40,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      marginRight: 20,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      marginRight: 40,
    },
    '@media(min-width: 2560px)': {
      marginRight: 40,
    },
  },
  displayNone: {
    display: 'none',
    pointerEvents: 'none',
  },
})
