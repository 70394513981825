import React from 'react'
import { Html } from '@react-three/drei'
import { useHud, useLoader } from '../stores'
import { useState, useEffect } from 'react'
// import { isMobile } from 'react-device-detect'

export default function Hotspot(props) {
  const [hotspots, sethotspots] = useState(true)
  const { everythingLoaded } = useLoader()
  const [appliedHotspot, setAppliedHotspot] = useState(true)

  useEffect(() => {
    const unsub = useHud.subscribe((hots) => {
      // console.log(hots)
      // console.log('setting ' + hots.hotspots)
      sethotspots(hots.hotspots)
    })

    return function cleanup() {
      unsub()
    }
  }, [])

  useEffect(() => {
    if (hotspots != appliedHotspot) {
      setAppliedHotspot(hotspots)
    }
  }, [hotspots])

  return (
    <>
      {!appliedHotspot && (
        <group position={props.position}>
          <Html className="htmlHotspot" sprite>
            {props.children}
          </Html>
        </group>
      )}
    </>
  )
}
