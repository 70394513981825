import { css, StyleSheet } from 'aphrodite'
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'wouter'
import { sendGAEvent } from '../../core/GoogleAnalytics'
import { useBehaviour, useEnvironment, useHud, useMaster } from '../../stores'
import { a, config, useSpring } from '@react-spring/web'

export default function NavbarV01(props) {
  const { currentCategory } = useBehaviour()
  const { data } = useMaster()
  const [currCat, setCurrCat] = useState(-1)
  const [location] = useLocation()
  const { current, continuaToggle, categoriesClick } = useEnvironment()
  // const [continuaLoading, setContinaLoading] = useState(false)
  const Continua = useHud()

  function OnNavCategoryClicked(category) {
    sendGAEvent('Navbar Category Selected', 'Click', category)
  }

  function OnContinuaClicked() {
    continuaToggle()
    if (current !== 2) {
      Continua.setContinuaLoading(true)
    } else {
      Continua.setContinuaLoading(false)
    }
  }

  function OnCategoriesClick() {
    categoriesClick()
    if (current !== 0 && current !== 1) {
      Continua.setContinuaLoading(true)
    } else {
      Continua.setContinuaLoading(false)
    }
  }

  const animatedStyle = useSpring({
    opacity: Continua.continuaLoading ? 2 : 0,
    config: config.gentle,
  })

  useEffect(() => {
    setTimeout(() => {
      Continua.setContinuaLoading(false)
    }, 2000)
  }, [Continua.continuaLoading])

  useEffect(() => {
    setCurrCat(currentCategory)
  }, [currentCategory])

  //* Called when refresh website in continua mode
  useEffect(() => {
    if (location.includes('/continua')) {
      continuaToggle()
    }
  }, [])

  return (
    <>
      <div className={css(styles.nav)}>
        {Object.keys(data.categories).map((key, index) => {
          let dat = data.categories[key]
          let href = dat.target

          return (
            <div key={index} className={css(styles.navLink)}>
              <Link
                onClick={() => {
                  OnNavCategoryClicked(dat.display)
                  // OnContinuaClicked()
                  dat.display === 'Continua'
                    ? OnContinuaClicked()
                    : OnCategoriesClick()
                }}
                className={css(
                  index === currCat ? styles.linkFontSelected : styles.linkFont,
                  styles.link
                )}
                href={href}
              >
                {dat.display === 'Continua' ? (
                  <img
                    src={dat.src}
                    className={css(
                      location.includes('/continua')
                        ? styles.continuaSelected
                        : styles.continua,
                      styles.link,
                      styles.continuaButton
                    )}
                    style={{
                      height: 'auto',
                      width: '100%',
                      cursor: 'pointer',
                      // background: 'red',
                    }}
                    // onClick={ToggleContinua}
                    alt="continua_logo"
                  />
                ) : (
                  dat.display
                )}
              </Link>
            </div>
          )
        })}
      </div>
      {Continua.continuaLoading && (
        <a.div className={css(styles.fadeOut)} style={animatedStyle}>
          <div className={css(styles.text)}>
            Please wait while we change the mode
          </div>
        </a.div>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  fadeOut: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100vh',
    backgroundColor: '#000000',
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      width: '100%',
      position: 'fixed',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        width: '100%',
        position: 'fixed',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        width: '100%',
        position: 'fixed',
      },
    textAlign: 'center',
  },
  text: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    top: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'space-around',
    color: 'white',
    fontSize: 40,
    // paddingTop: 260,
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      fontSize: 30,
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        fontSize: 30,
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        fontSize: 30,
      },
  },
  continuaButton: {
    background: 'none',
    outline: 'none',
    // border: 'none',
    width: '12vh',
    pointerEvents: 'auto',
  },
  continuaSelected: {
    borderBottom: '0.2vh solid rgb(255,255,255)',
  },
  continua: {
    borderBottom: 'none',
  },
  nav: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    // '@media(max-width: 575.98px) and (orientation:landscape)': {
    //     width: '100%',
    //     // backgroundColor: 'red',
    // },
    // '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:portrait)': {
    //     display: 'none'
    // },
    // '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)': {
    //     width: 75,
    //     marginLeft:0,
    //     height: 80,
    // },
    // '@media(min-width: 768px) and (max-width: 991.98px)': {
    //     width: '100%',
    //     // height: 80,
    //     // marginLeft:10,
    // },
    // '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)': {
    //     width: 75,
    //     height: 80,
    //     marginLeft:10,
    // },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:portrait)':
      {
        display: 'none',
      },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        width: '100%',
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        width: '100%',
      },
    //Laptops & larger screens
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      width: '100%',
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      width: '100%',
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      width: '100%',
      // marginLeft: 60
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      width: '100%',
      // marginLeft: 50
    },
    // '@media(min-width: 1920px) and (max-width: 2559.98px)': {
    //     width: '100%',
    //     // marginLeft: 150
    // },
    '@media(min-width: 2560px)': {
      width: '100%',
    },
  },
  button: {
    pointerEvents: 'auto',
  },
  linkFont: {
    textDecoration: 'none',
  },
  linkFontSelected: {
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationColor: '#ffffff',
  },
  navLink: {
    textAlign: 'center',
    paddingLeft: '1.5vw',
    paddingRight: '1.5vw',
    // width: 120,
    // backgroundColor: 'blue',
    // '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
    //   {
    //     width: 80,
    //   },
    // '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
    //   {
    //     width: 120,
    //   },
    // '@media(min-width: 992px) and (max-width: 1199.98px)': {
    //   width: 80,
    // },
    // '@media(min-width: 1200px) and (max-width: 1399.98px)': {
    //   width: 120,
    // },
    // '@media(min-width: 1600px) and (max-width: 2559.98px)': {
    //   width: 150,
    // },
    // '@media(min-width: 2560px)': {
    //   width: 200,
    // },
  },
  link: {
    display: 'block',
    textAlign: 'center',
    fontFamily: 'Oxygen-Regular',
    fontSize: 15,
    flex: 'none',
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    lineHeight: '19px',
    color: '#ffffff',
    // backgroundColor: 'black',
    pointerEvents: 'auto',
    '@media(max-width: 575.98px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(max-width: 575.98px) and (orientation:landscape)': {
      display: 'none',
    },
    '@media(min-width: 576px) and (max-width: 768.98px) and (orientation:landscape)':
      {
        display: 'none',
      },
    '@media(min-width: 768px) and (max-width: 991.98px) and (orientation:landscape)':
      {
        display: 'none',
      },
    '@media(min-width: 768px) and (max-width: 991.98px)': {
      fontSize: 12,
      width: 80,
    },
    '@media(min-width: 992px) and (max-width: 1199.98px) and (orientation:landscape)':
      {
        fontSize: 14,
        marginTop: 50,
      },
    '@media(min-width: 1200px) and (max-width: 1399.98px) and (orientation:landscape)':
      {
        fontSize: 15,
        marginTop: 50,
      },
    '@media(min-width: 992px) and (max-width: 1199.98px)': {
      fontSize: 12,
      marginTop: 50,
    },
    '@media(min-width: 1200px) and (max-width: 1399.98px)': {
      fontSize: 13,
      marginTop: 50,
    },
    '@media(min-width: 1400px) and (max-width: 1599.98px)': {
      fontSize: 15,
      marginTop: 51,
    },
    '@media(min-width: 1600px) and (max-width: 2559.98px)': {
      fontSize: 18,
      marginTop: 51,
    },
    '@media(min-width: 2560px)': {
      fontSize: 24,
      marginTop: 61,
    },
  },
})
