import { AdaptiveDpr } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import { useEffect, useState } from 'react'
import React from 'react'

export default function CustomDPR({ qualityMode }) {
  const { gl } = useThree()
  const [quality, setQuality] = useState(false)

  useEffect(() => {
    let tom1 = null
    let tom = null
    if (qualityMode) {
      tom1 = setTimeout(() => {
        gl.setPixelRatio(0.8)
      }, 800)
      tom = setTimeout(() => {
        gl.setPixelRatio(1)
        setQuality(true)
      }, 1500)
    }

    return function cleanup() {
      if (tom != null) clearTimeout(tom)

      if (tom != null) clearTimeout(tom1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualityMode])
  useEffect(() => {
    gl.setPixelRatio(0.1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <>{quality && <AdaptiveDpr />}</>
}
